import React, { useRef, useCallback, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Card from 'react-bootstrap/Card'


const DashboardUserForm = ({dashboard_id, dashboard_user_id, set_dashboard_data}) => {
	const {t} = useTranslation();
	
	const auth = useSelector(state => state.auth)
	const all_users = useSelector(state => state.settings.all_users)
	
	const urls = useSelector(state => state.urls)
	//const ws = useSelector(state => state.ws);
	
	const dispatch = useDispatch()
	
	const select_ref = useRef(null);
	
	const change_user_select = useCallback(event => {
		let user_id = auth.id
		if(event !== undefined) {
			user_id = select_ref.current.value
		}
		let data = {
			"user_id": user_id,
			"operation": "get_dashboard_for_user",
		}
		const url = urls['iot-api:dashboard-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200  && !data.error) {
				if(event !== undefined) {
					dispatch(set_header_form_alert(true, "success", t('Die Konfiguration wurde geladen.')))
				}
				set_dashboard_data(data)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, auth.id, set_dashboard_data])
	
	useEffect(() => {
		change_user_select(undefined)
	}, [change_user_select]);
	
	const create_user_dashboard = useCallback(event => {
		let user_id = auth.id
		if(event !== undefined) {
			user_id = select_ref.current.value
		}
		let data = {
			"user_id": user_id,
			"operation": "create_empty_dashboard",
		}
		const url = urls['iot-api:dashboard-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200  && !data.error) {
				if(event !== undefined) {
					dispatch(set_header_form_alert(true, "success", t('Das Dashboard wurde erstellt.')))
				}
				set_dashboard_data(data)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, auth.id, set_dashboard_data])
	
	const delete_user_dashboard = useCallback(event => {
		let user_id = auth.id
		if(event !== undefined) {
			user_id = select_ref.current.value
		}
		let data = {
			"user_id": user_id,
			"operation": "delete_dashboard",
		}
		const url = urls['iot-api:dashboard-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200  && !data.error) {
				if(event !== undefined) {
					dispatch(set_header_form_alert(true, "success", t('Das Dashboard wurde gelöscht.')))
				}
				set_dashboard_data(data)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, auth.id, set_dashboard_data])
	
	let choose_user_select_jsx = []
	if(auth.is_superuser) {
		let options_jsx = [
			<option key="global-dashboard" value={0}>{t('Gobales Dashboard')}</option>
		]
		for(let user of all_users) {
			options_jsx.push(
				<option key={`option-${user.id}`} value={user.id}>{user.email}</option>
			)
		}
		choose_user_select_jsx = [
			<div key="user-choose-select">
			<FloatingLabel label={t('Benutzer')}>
				<Form.Select 
					ref={select_ref}
					key={`user-${new Date().getTime()}`} 
					defaultValue={dashboard_user_id} 
					name="user_dashboard"
					onChange={change_user_select}>
					{options_jsx}
				</Form.Select>
			</FloatingLabel>
			</div>
		]
	}
	
	let user_db_jsx = []
	if(dashboard_id === null) {
		user_db_jsx.push(
			<div key="no-dashboard-exists-create-one" className="mt-3">
				<p key="db-info-text">{t('Für diesen Benutzer existiert kein Dashboard, es wird die globale Konfiguration verwendet. Falls gwünscht kann ein Dashboard erstellt werden.')}</p>
				<Button onClick={create_user_dashboard} variant="" className="primary-light-bg">{t('Dashboard erstellen')}</Button>
			</div>
		)
	} else {
		if(select_ref.current.value !== "0" && auth.is_superuser) {
			user_db_jsx.push(
				<div key="delete-user-dashboard" className="mt-3">
					<Button variant="" className="danger-light-bg" onClick={delete_user_dashboard}>{t('Dashboard löschen')}</Button>
				</div>
			)
		}
	}
	
	return (
		<Card>
			<Card.Body>
			<div>
				{choose_user_select_jsx}
			</div>
			{user_db_jsx}
			</Card.Body>
		</Card>
	)
}

export default DashboardUserForm;
