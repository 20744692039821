import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';
import { send } from '@giantmachines/redux-websocket';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'

const SetAlarmNotificationForm = ({ obj, obj_type, event_key }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const notifications = useSelector(state => state.settings.alarm_notifications.all_notifications)
	
	const devices_with_project_notifications = useSelector(state => state.settings.alarm_notifications.devices_with_project_notifications)
	
	const dispatch = useDispatch()
	
	const select_ref = useRef(null);
	
	let options_jsx = []
	for(let notification of notifications) {
		options_jsx.push(
			<option key={`option-${notification.id}`} value={notification.id}>{notification.name}</option>
		)
	}
	
	const choose_notification_select_jsx = [
		<div key="notification-choose-select" className="mt-3">
			<FloatingLabel label={t('Alarm Benachrichtigung')}>
				<Form.Select 
					ref={select_ref}
					defaultValue={obj.alarm_notification} 
					key={`notification-${new Date().getTime()}`} 
					name="alarm_notification">
					{options_jsx}
				</Form.Select>
			</FloatingLabel>
		</div>
	]
	
	let device_project_notification_info_jsx = []
	if(obj_type === 'device' && devices_with_project_notifications[obj.dev_eui]) {
		device_project_notification_info_jsx = [
			<div key="project-has-alarm-notifications-info" className='text-danger'>{t('Das Projekt dieses Gerätes hat zugeordnete Alarm Benachrichtigungen. Eine allfällige Zuordnung hier wird keinen Effekt zeigen!')}</div>
		]
	}
	
	const save_clicked = useCallback(() => {
		let data = {
			"obj_type": obj_type,
			"id": obj.id,
			"notification_id": parseInt(select_ref.current.value)
		}
		const url = urls['iot-api:set-alarm-notification']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, obj.id, obj_type, urls, t])
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('Alarm Benachrichtigung zuweisen')}</Accordion.Header>
			<Accordion.Body>
				<div>{t('Alarm Benachrichtigungen die dem Projekt zugewiesen sind, haben Priorität vor den Benachrichtigungen des Gerätes.')}</div>
				{device_project_notification_info_jsx}
				<div>
					{choose_notification_select_jsx}
				</div>
				<ButtonToolbar>
					<ButtonGroup className="me-2 mt-3">
						<Button key="save"variant="" className="primary-light-bg" onClick={save_clicked}>{t('Speichern')}</Button>
					</ButtonGroup>
					<ButtonGroup className="me-2 mt-3">
						<Button as={Link} key="link" className="primary-light-bg" to="/alarm/notifications">{t('Zur Benachrichtigungs Konfiguration')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default SetAlarmNotificationForm;