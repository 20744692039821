import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import InputFloatField from './fields/InputFloatField'

const PhoneConfigurationTestForm = ({ configurations, 
									  get_error_state,
									  test_config }) => {
	const {t} = useTranslation();
	
	const [validated, set_validated] = useState(false);
	
	const form_ref = useRef(null);
	const select_ref = useRef(null);
	
	let options_jsx = []
	for(let config of configurations) {
		options_jsx.push(
			<option key={`option-${config.id}`} value={config.id}>{config.name}</option>
		)
	}
	
	const send_test_email_click = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			data['config_type'] = 'phone'
			test_config(data)
			if(validated) {
				set_validated(false);
			}
		} else {
			set_validated(true);
		}
	}
	
	let output_jsx = []
	if(configurations.length) {
		output_jsx = [
			<Form ref={form_ref} validated={validated} key='phone-config-test-form'>
				<Row>
					<Col>
						<InputFloatField 
							is_required={true}
							label={t('Nachricht')} 
							field_name="message" 
							field_type="text"
							key={`email-message-${new Date().getTime()}`} 
							errors={get_error_state("message")} />
					</Col>
				</Row>
				<Row>
					<Col>
						<FloatingLabel label={t('Telefon/SIP Konfiguration')}>
							<Form.Select 
								ref={select_ref}
								key={`user-${new Date().getTime()}`} 
								name="config_id">
								{options_jsx}
							</Form.Select>
						</FloatingLabel>
					</Col>
				</Row>
				<Button variant="sm" className="primary-light-bg mt-3" onClick={send_test_email_click}>{t('Test Anruf auslösen')}</Button>
			</Form>
		]
	} else {
		output_jsx = [
			<p key="no-phone-configurations" >{t('Keine Benachrichtigunskonfigurationen vorhanden')}</p>
		]
	}
	
	return (
		<>
			{output_jsx}
		</>
	)
}

export default PhoneConfigurationTestForm;