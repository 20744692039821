import React from 'react';
import { useTranslation} from 'react-i18next';
import Badge from 'react-bootstrap/Badge'
import {Cpu} from 'react-bootstrap-icons'

const DeviceTypeIndicator = ({ device_type }) => {
	const {t} = useTranslation();
	return (
		<Badge bg='' className="orange-light-bg">
		<Cpu /> {t('Typ')} {device_type}
		</Badge>
	);
}

export default DeviceTypeIndicator;
