import React, {useState} from 'react';
import { useTranslation} from 'react-i18next';
import DeviceTitleContainer from './DeviceTitleContainer';
import DeviceInfoContainer from './DeviceInfoContainer';
import DeviceExportForm from '../forms/DeviceExportForm'
import DeviceSettingsForm from '../forms/DeviceSettingsForm'
import Accordion from 'react-bootstrap/Accordion'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import TabPane from 'react-bootstrap/TabPane'
import Button from 'react-bootstrap/Button'
import GroupObjectPermissionContainer from '../GroupObjectPermissionContainer'

import {usePermission, useObjectPermission} from '../../hooks'

const DeviceContainer = ({ device_obj, with_link, table_items, chart_items, triggered_alarm_count, triggered_alarm_names }) => {
	const {t} = useTranslation();
	
	const [tab_key, set_tab_key] = useState('info');
	
	const has_perm = usePermission()
	const has_object_perm = useObjectPermission()
	
	let charts = []
	if(with_link) {
		charts.push(
			<React.Fragment key="container-with-button-wrapper">
			<div key={`small-chart-container-${device_obj['dev_eui']}`} className="device-small-chart-container">
				{chart_items}
			</div>
			<Button key={`button-${device_obj['dev_eui']}`} as={Link} to={`/devices/${device_obj['dev_eui']}`} variant="" className="primary-light-bg">{t('Gerät Detail')}</Button>
			</React.Fragment>
		)
	} else {
		let last_values_tables = []
		
		if(table_items.length) {
			last_values_tables = [
				<Accordion key="last-values-accordion" className="last-value-accordion">
					<Accordion.Item eventKey="0">
						<Accordion.Header className="accordion-header-big">{t('Tabellen Letzte Werte')}</Accordion.Header>
						<Accordion.Body>
							<div key="last-values-container-wrapper" className="last-values-container-wrapper">
								{table_items}
							</div>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			]
		}
		
		charts.push(
			<React.Fragment key="container-without-button">
			{last_values_tables}
			{chart_items}
			</React.Fragment>
		)
	}
	
	let device_settings_nav_item_jsx = []
	let device_settings_tab_pane_jsx = []
	if(has_perm('iot.change_device')) {
		device_settings_nav_item_jsx = [
			<Nav.Item key="device-settings-nav-item">
				<Nav.Link eventKey="device-settings">{t('Einstellungen')}</Nav.Link>
			</Nav.Item>
		]
		device_settings_tab_pane_jsx = [
			<TabPane eventKey="device-settings" key="device-settings-tab-pane">
				<DeviceSettingsForm device_obj={device_obj}/>
			</TabPane>
		]
	}
	
	let device_export_nav_item_jsx = []
	let device_export_tab_pane_jsx = []
	if(has_perm('iot.export_device_data') || has_object_perm('iot.export_device_data', 'device', device_obj.id)) {
		device_export_nav_item_jsx = [
			<Nav.Item key="export-device-data-nav-item">
				<Nav.Link eventKey="device-export">{t('Export')}</Nav.Link>
			</Nav.Item>
		]
		device_export_tab_pane_jsx = [
			<TabPane eventKey="device-export" key="export-device-tab-pane">
				<DeviceExportForm device_obj={device_obj}/>
			</TabPane>
		]
	}
	
	let device_object_permission_nav_item_jsx = []
	let device_object_permission_tab_pane_jsx = []
	if(has_perm('auth.change_permission')) {
		device_object_permission_nav_item_jsx = [
			<Nav.Item key="device-object-permission-nav-item">
				<Nav.Link eventKey="device-permission">{t('Berechtigungen')}</Nav.Link>
			</Nav.Item>
		]
		device_object_permission_tab_pane_jsx = [
			<TabPane eventKey="device-permission" key="device-object-permission-tab-pane">
				<GroupObjectPermissionContainer obj={device_obj} obj_type="device" event_key="device-permission" tab_key={tab_key} />
			</TabPane>
		]
	}
	
	return (
		<Card className="device-container">
			<DeviceTitleContainer device_obj={device_obj} triggered_alarm_count={triggered_alarm_count} triggered_alarm_names={triggered_alarm_names} /> 
			<Card.Body>
				<Card>
					<Card.Header>
						<Nav variant="tabs" defaultActiveKey={tab_key} onSelect={(k) => set_tab_key(k)}>
							<Nav.Item>
								<Nav.Link eventKey="info">{t('Info')}</Nav.Link>
							</Nav.Item>
							{device_export_nav_item_jsx}
							{device_settings_nav_item_jsx}
							{device_object_permission_nav_item_jsx}
						</Nav>
					</Card.Header>
					<Card.Body>
						<Tabs activeKey={tab_key}>
							<TabPane eventKey="info">
								<DeviceInfoContainer device_obj={device_obj} />
								{charts}							
							</TabPane>
							{device_export_tab_pane_jsx}
							{device_settings_tab_pane_jsx}
							{device_object_permission_tab_pane_jsx}
						</Tabs>
					</Card.Body>
				</Card>
			</Card.Body>
		</Card>
	);
}

export default DeviceContainer;
