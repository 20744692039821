import React, { forwardRef } from 'react';
import { Draggable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import UserGroupWidget from '../widgets/UserGroupWidget'

const GroupDnDContainer = forwardRef(({title, groups, provided}, ref) =>  {
	return (
		<Card className="half-width-dnd-container with-min-height">
			<Card.Header className='default-title'>{title}</Card.Header>
			<Card.Body ref={ref} {...provided.droppableProps}>
				{groups.map(({ name, id }, index) => (
					<Draggable
                      key={id}
                      draggableId={`group-${id}`}
                      index={index}
                    >
                    {(provided) => (
			    	<UserGroupWidget
			    		ref={provided.innerRef}
			    		id={id}
						name={name}
			            key={index}
			            provided={provided}
			          />
			         )}
			         </Draggable>
				))}
				{provided.placeholder}
			</Card.Body>
		</Card>
	)
})

export default GroupDnDContainer;