import React, {useState, useEffect, useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'

import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import TabPane from 'react-bootstrap/TabPane'

import {usePermission} from '../../hooks'
import {
	set_header_form_alert,
	async_do_simple_form_post} 
from '../../actions'

import UserWidget from '../forms/widgets/UserWidget'
import GroupWidget from '../forms/widgets/GroupWidget'

const UsersGroupsSettingsDetailPage = () => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const has_perm = usePermission()
	
	const [tab_key, set_tab_key] = useState('users');
	
	const [is_initialized, set_is_initialized] = useState(false)
	
	const [settings_data, set_settings_data] = useState({
		users: [],
		users_to_create: [],
		groups: [],
		groups_to_create: [],
		just_created_user_id: 0,
		just_created_group_id: 0,
	})
	
	const initial_load = useCallback(() => {
		
		let data = {
			"operation": "get_all_information"
		}
		const url = urls['core-api:user-groups']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				console.log('loaded, data: ',data)
				set_settings_data({
					users: data.users,
					users_to_create: data.users_to_create,
					groups: data.groups,
					groups_to_create: data.groups_to_create,
					just_created_user_id: data.just_created_user_id
				})
				set_is_initialized(true)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls])
	
	useEffect(() => {
		if(!is_initialized) { // load data for configuration
			initial_load();
		}
	}, [is_initialized, initial_load]);
	
	let user_widget_jsx = [
		<div key="user-management-no-permission">{t('Nicht genügend Berechtigung um diese Optionen anzuzeigen.')}</div>
	]
	let group_widget_jsx = [
		<div key="group-management-no-permission">{t('Nicht genügend Berechtigung um diese Optionen anzuzeigen.')}</div>
	]
	if(has_perm([
		'auth.add_user', 'auth.change_user', 'auth.delete_user',
		'auth.add_group', 'auth.change_group', 'auth.delete_group',
		'auth.add_permission', 'auth.change_permission'
		
	])) {
		user_widget_jsx = [
			<UserWidget key="user-management-widget" settings_data={settings_data} set_settings_data={set_settings_data} />
		]
		group_widget_jsx = [
			<GroupWidget key="group-management-widget" settings_data={settings_data} set_settings_data={set_settings_data} />
		]
	}
	return (
		<Card className="settings-container">
			<Card.Header as="h5">
				{t('Benutzer und Gruppen Einstellungen/Konfigurationen')}
			</Card.Header>
			<Card.Body>
				<Card key="user-group-settings-card">
					<Card.Header>
						<Nav variant="tabs" defaultActiveKey={tab_key} onSelect={(k) => set_tab_key(k)}>
							<Nav.Item>
								<Nav.Link eventKey="users">{t('Benutzer')}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="groups">{t('Gruppen')}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Card.Header>
					<Card.Body>
						<Tabs activeKey={tab_key}>
							<TabPane eventKey="users">
								{user_widget_jsx}
							</TabPane>
							<TabPane eventKey="groups">
								{group_widget_jsx}
							</TabPane>
						</Tabs>
					</Card.Body>
				</Card>
			</Card.Body>
		</Card>
	)
}

export default UsersGroupsSettingsDetailPage;
