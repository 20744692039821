import React, { forwardRef } from 'react';
import { Draggable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import DashboardProjectWidget from '../widgets/DashboardProjectWidget'

const ProjectDnDContainer = forwardRef(({title, projects, provided}, ref) =>  {
	return (
		<Card className="half-width-dnd-container with-min-height">
			<Card.Header as="h5">
				{title}
			</Card.Header>
			<Card.Body ref={ref} {...provided.droppableProps}>
				{projects.map(({ project_slug, project_title, project_status, type }, index) => (
					<Draggable
                      key={project_slug}
                      draggableId={`project-${project_slug}`}
                      index={index}
                    >
                    {(provided) => (
			    	<DashboardProjectWidget
			    		ref={provided.innerRef}
			    		project_slug={project_slug}
						title={project_title}
						project_status={project_status}
			            type={type}
			            key={index}
			            provided={provided}
			          />
			         )}
			         </Draggable>
				))}
				{provided.placeholder}
			</Card.Body>
		</Card>
	)
})

export default ProjectDnDContainer;