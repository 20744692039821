import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useTranslation} from 'react-i18next';
import { Link } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import {usePermission} from '../hooks'
import Alert from 'react-bootstrap/Alert'
import GroupObjectPermissionForm from './forms/GroupObjectPermissionForm'

import {
	set_header_form_alert,
	async_do_simple_form_post} 
from '../actions'

const GroupObjectPermissionContainer = ({ obj,
										  obj_type, 
										  event_key, 
										  tab_key }) => {
	const {t} = useTranslation();
	
	const dispatch = useDispatch()
	
	const urls = useSelector(state => state.urls)
	
	const has_perm = usePermission()
	
	const [is_initialized, set_is_initialized] = useState(false)
	
	const [settings_data, set_settings_data] = useState({
		device_permissions: {
			view_groups: [],
			view_na_groups: [],
			export_data_groups: [],
			export_data_na_groups: []
		},
		project_permissions: {
			view_groups: [],
			view_na_groups: []
		}
	})
	
	const initial_load = useCallback(() => {
		
		let form_data = {
			"operation": "get_all_object_permissions",
			"obj_id": obj.id,
			"obj_type": obj_type
		}
		const url = urls['core-api:object-permissions']();
		
		dispatch(async_do_simple_form_post(form_data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				console.log('loaded, data: ',data)
				set_settings_data({
					device_permissions: {
						view_groups: data.device_permissions.view_groups,
						view_na_groups: data.device_permissions.view_na_groups,
						export_data_groups: data.device_permissions.export_data_groups,
						export_data_na_groups: data.device_permissions.export_data_na_groups,
					},
					project_permissions: {
						view_groups: data.project_permissions.view_groups,
						view_na_groups: data.project_permissions.view_na_groups,
					}
				})
				set_is_initialized(true)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, obj.id, obj_type])
	
	useEffect(() => {
		if(!is_initialized && has_perm([
			'auth.add_permission', 'auth.change_permission'
		]) && event_key === tab_key) { // load data for configuration, only if tab is shown
			initial_load();
		}
	}, [is_initialized, initial_load, event_key, tab_key, has_perm]);
	
	let forms_jsx = []
	if(obj_type === 'device') {
		forms_jsx = [
			<Fragment key="group-object-permission-device-forms">
				<GroupObjectPermissionForm obj={obj} obj_type={obj_type} 
													 permission_codename='view_device' 
													 settings_data={settings_data} 
													 set_settings_data={set_settings_data} />
				<GroupObjectPermissionForm obj={obj} obj_type={obj_type} 
													 permission_codename='export_device_data' 
													 settings_data={settings_data} 
													 set_settings_data={set_settings_data} />
			</Fragment>
		]
	} else if(obj_type === 'project') {
		forms_jsx = [
			<Fragment key="group-object-permission-project-forms">
					<GroupObjectPermissionForm obj={obj} obj_type={obj_type} 
														 permission_codename='view_project' 
														 settings_data={settings_data} 
														 set_settings_data={set_settings_data} />
			</Fragment>
		]
	}
	
	return (
		<>
			<Alert variant="warning">
				{t('Diese Berechtigungen werden nur auf dieses spezifische Objekt angewandt.')}
				<br />
				<Link className="alert-link" to="/users-and-groups">{t('Globale Berechtigungen konfigurieren')}</Link>
			</Alert>
			{forms_jsx}
		</>
	)
}

export default GroupObjectPermissionContainer