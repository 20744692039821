import React, {useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import update from 'immutability-helper'
//import { send } from '@giantmachines/redux-websocket';
import Button from 'react-bootstrap/Button'
import {useFormIDError} from '../formHooks'
import GroupForm from '../GroupForm'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../../actions'

const UserWidget = ({ settings_data, set_settings_data }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [, set_form_errors, get_error_state] = useFormIDError({})
	
	const delete_group = useCallback((id) => {
		let data = {
			"operation": "delete_group",
			"group_id": id
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				console.log('data is: ',data)
				dispatch(set_header_form_alert(true, "success", t('Der Benutzer wurde entfernt.')))
				const new_settings_data = update(settings_data, {
					groups: {
						$set: data.groups
					},
					users: {
						$set: data.users
					}
				})
				set_settings_data(new_settings_data)
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, settings_data, set_settings_data])
	
	const update_group = useCallback((form_data, form_error_id) => {
		let data = {
			"operation": "update_group",
			...form_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			set_form_errors({[form_error_id]: {}})
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				const new_settings_data = update(settings_data, {
					groups: {
						$set: data.groups
					},
					users: {
						$set: data.users
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [dispatch, set_form_errors, t, urls, settings_data, set_settings_data])
	
	const create_group = useCallback((form_data, form_error_id, index) => {
		let data = {
			"operation": "create_group",
			...form_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			set_form_errors({[form_error_id]: {}})
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				const new_groups_to_create = Array.from(settings_data.groups_to_create)
				new_groups_to_create.splice(index, 1)
				const new_settings_data = update(settings_data, {
					groups: {
						$set: data.groups
					},
					users: {
						$set: data.users
					},
					groups_to_create: {
						$set: new_groups_to_create
					},
					just_created_group_id: {
						$set: data.just_created_group_id
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [dispatch, set_form_errors, t, urls, settings_data, set_settings_data])
	
	const update_group_permissions = useCallback((group_data) => {
		let data = {
			"operation": "update_group_permissions",
			...group_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				const new_settings_data = update(settings_data, {
					groups: {
						$set: data.groups
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Die Gruppen wurden aktualisiert.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, settings_data, set_settings_data])
	
	const add_group_click = (event) => {
		let groups_to_create = Array.from(settings_data.groups_to_create)
		groups_to_create.push({
			name: '',
			type: 'create'
		})
		
		const new_settings_data = update(settings_data, {
			groups_to_create: {
				$set: groups_to_create
			}
		}) 
		
		set_settings_data(new_settings_data)
	}
	
	const remove_group_form = (index) => {
		const new_groups_to_create = Array.from(settings_data.groups_to_create)
		new_groups_to_create.splice(index, 1)
		const new_settings_data = update(settings_data, {
			groups_to_create: {
				$set: new_groups_to_create
			}
		})
		set_settings_data(new_settings_data) 
	}
	
	let group_forms_jsx = []
	if(settings_data.groups.length || settings_data.groups_to_create.length) {
		for(const group_obj of settings_data.groups) {
			let is_initial_open = group_obj.id === settings_data.just_created_group_id ? true : false
			group_forms_jsx.push(
				<GroupForm key={`group-update-${group_obj.id}`} 
						  group_obj={group_obj}
						  index={undefined}
						  get_error_state={get_error_state}
						  delete_group={delete_group}
						  update_group={update_group}
						  create_group={undefined}
						  update_group_permissions={update_group_permissions}
						  remove_group_form={undefined}
						  is_initial_open={is_initial_open} />
			)
		}
		let create_counter = 0
		for(const group_obj of settings_data.groups_to_create) {
			group_obj.index = create_counter
			group_forms_jsx.push(
				<GroupForm key={`group-create-${create_counter}`} 
						  group_obj={group_obj}
						  index={create_counter}
						  get_error_state={get_error_state}
						  delete_group={undefined}
						  update_group={undefined}
						  create_group={create_group}
						  update_group_permissions={undefined}
						  remove_group_form={remove_group_form}
						  is_initial_open={true} />
			)
			create_counter += 1
		}
	} else {
		group_forms_jsx.push(
			<div key="no-groups" className="default-settings-card">{t('Keine Gruppen vorhanden')}</div>
		)
	}
	
	return (
		<>
			{group_forms_jsx}
			<Button variant="sm" className="primary-light-bg mt-3" onClick={add_group_click}>{t('Gruppe hinzufügen')}</Button>
		</>
	)
}

export default UserWidget;