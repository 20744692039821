import React, { useRef, useCallback, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import SelectFloatField from './fields/SelectFloatField'
import InputFloatField from './fields/InputFloatField'

const ProjectAddressPartForm = ({ project_obj, project_address, event_key }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	const dispatch = useDispatch()
	
	const [validated, set_validated] = useState(false);
	
	const form_ref = useRef(null);
	
	const handle_save_click = useCallback(event => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			let operation = 'update'
			if(project_address.id === undefined) {
				operation = 'create'
			}
			let data = {
				"id": project_address.id,
				"operation": operation,
				"project": project_obj.id
			}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['iot-api:project-update-create-address']();
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
				if(status === 200) {
					dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
					dispatch(send({'type': 'initial.data.for.settings.change'}));
				} else {
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				}
			})
		}
		set_validated(true);
	}, [form_ref, t, dispatch, urls, project_address.id, project_obj.id])
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('Adresse eingeben')}</Accordion.Header>
			<Accordion.Body>
				<Row>
					<Col>
						<Form ref={form_ref} validated={validated}>
							<InputFloatField 
									is_required={false}
									label={t('Strasse')}
									field_name="street"
									field_type="text" 
									key={`street-${project_obj.id}-${new Date().getTime()}`} 
									default_value={project_address.street} />
							<InputFloatField
									is_required={true} 
									label={t('PLZ')}
									field_name="zip"
									field_type="text"
									key={`zip-${project_obj.id}-${new Date().getTime()}`}  
									default_value={project_address.zip} />
							<InputFloatField 
									is_required={true}
									label={t('Stadt')}
									field_name="city" 
									field_type="text" 
									key={`city-${project_obj.id}-${new Date().getTime()}`}
									default_value={project_address.city} />
							<SelectFloatField 
								choices={project_obj.country_choices} 
								select_choice={project_address.country} 
								field_name="country"
								floating_label={t('Land')} />
						</Form>
					</Col>
				</Row>
				<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default ProjectAddressPartForm;