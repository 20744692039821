import React, {forwardRef} from 'react';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const UserGroupWidget = forwardRef(({ name, id, provided }, ref) => {
	
	return (
		<Card ref={ref} key={`group-id-${id}`} {...provided.draggableProps} {...provided.dragHandleProps} className="default-setttings-container">
		<Card.Header>
			<Container fluid>
  				<Row>
    				<Col className="title">{name}</Col>
  				</Row>
			</Container>
		</Card.Header>
		</Card>
		
	);
})

export default UserGroupWidget;