import React, { useRef, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Alert from 'react-bootstrap/Alert'
import Fade from 'react-bootstrap/Fade'

import {set_header_form_alert} from '../actions'

const HeaderFormAlert = (props) => {
	const dispatch = useDispatch()
	const header_form_alert = useSelector(state => state.header_form_alert)
	
	const time_out = useRef(null);
	
	useEffect(() => {
		if(header_form_alert.show) {
			time_out.current = setTimeout(() => {
		        dispatch(set_header_form_alert(false, header_form_alert.cls, header_form_alert.message))
		    }, 5000);
		} else {
			clearTimeout(time_out.current);
		}
	}, [dispatch, header_form_alert]);
	
	return (
		<div className="alert-fixed top">
			<Alert variant={header_form_alert.cls} dismissible={true} onClose={() => dispatch(set_header_form_alert(false, header_form_alert.cls, header_form_alert.message))} show={header_form_alert.show} transition={Fade}>{header_form_alert.message}</Alert>
		</div>
	)
}

export default HeaderFormAlert;