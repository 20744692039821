import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import ProjectTitleContainer from './ProjectTitleContainer';
import ProjectInfoContainer from './ProjectInfoContainer';
import ProjectOverviewSettingsForm from '../forms/ProjectOverviewSettingsForm';
import GroupObjectPermissionContainer from '../GroupObjectPermissionContainer'

import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import TabPane from 'react-bootstrap/TabPane'
import Button from 'react-bootstrap/Button'

import {usePermission} from '../../hooks'

const ProjectDashboardProject = ({ project_obj, 
								   project_address, 
								   triggered_alarm_count, 
								   total_datapoints,
								   device_count }) => {
	console.log('device_count: '+device_count)
	const {t} = useTranslation();
	
	const [tab_key, set_tab_key] = useState('info');
	
	const has_perm = usePermission()
	
	let project__settings_nav_jsx = []
	let project_settings_tab_jsx = []
	if(has_perm('iot.change_project')) {
		project__settings_nav_jsx = [
			<Nav.Item key="project-settings-nav-item">
				<Nav.Link eventKey="project-settings">{t('Einstellungen')}</Nav.Link>
			</Nav.Item>
		]
		project_settings_tab_jsx = [
			<TabPane eventKey="project-settings" key="project-settings-tab-pane">
				<ProjectOverviewSettingsForm project_obj={project_obj} project_address={project_address} />
			</TabPane>
		]
	}
	
	let project_object_permission_nav_item_jsx = []
	let project_object_permission_tab_pane_jsx = []
	if(has_perm('auth.change_permission')) {
		project_object_permission_nav_item_jsx = [
			<Nav.Item key="project-object-permission-nav-item">
				<Nav.Link eventKey="project-permission">{t('Berechtigungen')}</Nav.Link>
			</Nav.Item>
		]
		project_object_permission_tab_pane_jsx = [
			<TabPane eventKey="project-permission" key="project-object-permission-tab-pane">
				<GroupObjectPermissionContainer obj={project_obj} obj_type="project" event_key="project-permission" tab_key={tab_key} />
			</TabPane>
		]
	}
	
	return (
		<Card className="project-container">
			<ProjectTitleContainer project_obj={project_obj} device_count={device_count} triggered_alarm_count={triggered_alarm_count} total_datapoints={total_datapoints} />
			<Card.Body>
				<Card>
					<Card.Header>
						<Nav variant="tabs" defaultActiveKey={tab_key} onSelect={(k) => set_tab_key(k)}>
							<Nav.Item>
								<Nav.Link eventKey="info">{t('Info')}</Nav.Link>
							</Nav.Item>
							{project__settings_nav_jsx}
							{project_object_permission_nav_item_jsx}
						</Nav>
					</Card.Header>
					<Card.Body>
						<Tabs activeKey={tab_key}>
							<TabPane eventKey="info">
								<ProjectInfoContainer project_obj={project_obj} project_address={project_address} />
								<Button key={`button-${project_obj['slug']}`} as={Link} to={`/projects/${project_obj['slug']}`} variant="" className="primary-light-bg">{t('Projekt Detail')}</Button>				
							</TabPane>
							{project_settings_tab_jsx}
							{project_object_permission_tab_pane_jsx}
						</Tabs>
					</Card.Body>
				</Card>
			</Card.Body>
		</Card>
	);
}

export default ProjectDashboardProject;