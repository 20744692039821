import React, {useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'
import update from 'immutability-helper'

import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import {useFormIDError} from '../formHooks'

import EmailConfigurationForm from '../../forms/EmailConfigurationForm'
import PhoneConfigurationForm from '../../forms/PhoneConfigurationForm'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../../actions'

const AlarmNotificationConfigurationWidget = ({ settings_data, set_settings_data }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [, set_form_errors, get_error_state] = useFormIDError({})
	
	const delete_configuration = useCallback((id, config_type) => {
		let data = {
			"operation": "delete_configuration",
			"config_type": config_type,
			"id": id
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				let new_settings_data = {}
				// update all configurations in objects to create
				const notifications_to_create = Array.from(settings_data.notifications_to_create)
				let new_notifications_to_create = []
				for(const ntc of notifications_to_create) {
					new_notifications_to_create.push(update(ntc, {
						configurations: {
							$set: []
						},
						na_configurations: {
							$set: [...data.configurations.email, ...data.configurations.phone] 
						}
					}))
				}
				if(config_type === 'email') {
					// update settings
					new_settings_data = update(settings_data, {
						notifications: {
							$set: data.notifications
						},
						notifications_to_create: {
							$set: new_notifications_to_create
						},
						configurations: {
							email: {
								$set: data.configurations.email
							}
						}
					})
				} else if(config_type === 'phone') {
					new_settings_data = update(settings_data, {
						notifications: {
							$set: data.notifications
						},
						notifications_to_create: {
							$set: new_notifications_to_create
						},
						configurations: {
							phone: {
								$set: data.configurations.phone
							}
						}
					})
				}
				set_settings_data(new_settings_data) 
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [settings_data, set_settings_data, dispatch, t, urls])
	
	const create_configuration = useCallback((form_data, form_error_id, index) => {
		const {config_type} = form_data
		let data = {
			"operation": "create_configuration",
			...form_data
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				let new_settings_data = {}
				// update all configurations in objects to create
				const notifications_to_create = Array.from(settings_data.notifications_to_create)
				let new_notifications_to_create = []
				for(const ntc of notifications_to_create) {
					new_notifications_to_create.push(update(ntc, {
						configurations: {
							$set: []
						},
						na_configurations: {
							$set: [...data.configurations.email, ...data.configurations.phone] 
						}
					}))
				}
				if(config_type === 'email') {
					const new_email_configurations_to_create = Array.from(settings_data.configurations_to_create.email)
					new_email_configurations_to_create.splice(index, 1)
					new_settings_data = update(settings_data, {
						notifications: {
							$set: data.notifications
						},
						notifications_to_create: {
							$set: new_notifications_to_create
						},
						configurations: {
							email: {
								$set: data.configurations.email
							}
						},
						configurations_to_create: {
							email: {
								$set: new_email_configurations_to_create
							}
						}
					})
				} else if(config_type === 'phone') {
					const new_phone_configurations_to_create = Array.from(settings_data.configurations_to_create.phone)
					new_phone_configurations_to_create.splice(index, 1)
					new_settings_data = update(settings_data, {
						notifications: {
							$set: data.notifications
						},
						notifications_to_create: {
							$set: new_notifications_to_create
						},
						configurations: {
							phone: {
								$set: data.configurations.phone
							}
						},
						configurations_to_create: {
							phone: {
								$set: new_phone_configurations_to_create
							}
						}
					})
				}
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [settings_data, set_settings_data, dispatch, t, urls, set_form_errors])
	
	const update_configuration = useCallback((form_data, form_error_id) => {
		const {config_type} = form_data
		let data = {
			"operation": "update_configuration",
			...form_data
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				// update all configurations in objects to create
				const notifications_to_create = Array.from(settings_data.notifications_to_create)
				let new_notifications_to_create = []
				for(const ntc of notifications_to_create) {
					new_notifications_to_create.push(update(ntc, {
						configurations: {
							$set: []
						},
						na_configurations: {
							$set: [...data.configurations.email, ...data.configurations.phone] 
						}
					}))
				}
				let new_settings_data = {}
				if(config_type === 'email') {
					new_settings_data = update(settings_data, {
						notifications: {
							$set: data.notifications
						},
						notifications_to_create: {
							$set: new_notifications_to_create
						},
						configurations: {
							email: {
								$set: data.configurations.email
							}
						}
					})
				} else if(config_type === 'phone') {
					new_settings_data = update(settings_data, {
						notifications: {
							$set: data.notifications
						},
						notifications_to_create: {
							$set: new_notifications_to_create
						},
						configurations: {
							phone: {
								$set: data.configurations.phone
							}
						}
					})
				}
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [settings_data, set_settings_data, dispatch, t, urls, set_form_errors])
	
	const remove_configuration_form = (index, config_type) => {
		if(config_type === 'email') {
			const new_email_configurations_to_create = Array.from(settings_data.configurations_to_create.email)
			new_email_configurations_to_create.splice(index, 1)
			const new_settings_data = update(settings_data, {
				configurations_to_create: {
					email: {
						$set: new_email_configurations_to_create
					}
				}
			})
			set_settings_data(new_settings_data) 
		} else if(config_type === 'phone') {
			const new_phone_configurations_to_create = Array.from(settings_data.configurations_to_create.phone)
			new_phone_configurations_to_create.splice(index, 1)
			const new_settings_data = update(settings_data, {
				configurations_to_create: {
					phone: {
						$set: new_phone_configurations_to_create
					}
				}
			})
			set_settings_data(new_settings_data) 
		}
	}
	
	const handle_add_email_config_click = (event) => {
		let email_configs_to_create = Array.from(settings_data.configurations_to_create.email)
		email_configs_to_create.push({
			name: '',
			use_django_default: false,
			host: '',
			port: 0,
			username: '',
			password: '',
			use_tls: true,
			to_email: '',
			default_from_email: '',
			type: 'create'
		})
		
		const new_settings_data = update(settings_data, {
			configurations_to_create: {
				email: {
					$set: email_configs_to_create
				}
			}
		}) 
		
		set_settings_data(new_settings_data)
	}
	
	const handle_add_phone_config_click = (event) => {
		let phone_configs_to_create = Array.from(settings_data.configurations_to_create.phone)
		phone_configs_to_create.push({
			name: '',
			host: '',
			port: 0,
			username: '',
			password: '',
			call_number: '',
			type: 'create'
		})
		
		const new_settings_data = update(settings_data, {
			configurations_to_create: {
				phone: {
					$set: phone_configs_to_create
				}
			}
		}) 
		
		set_settings_data(new_settings_data)
	}
	
	let email_configurations_jsx = []
	if(settings_data.configurations.email.length || settings_data.configurations_to_create.email.length) {
		for(const email_config of settings_data.configurations.email) {
			email_configurations_jsx.push(
				<EmailConfigurationForm key={`email-config-id-${email_config.id}`} 
										config_obj={email_config}
										delete_configuration={delete_configuration}
										create_configuration={undefined} 
										update_configuration={update_configuration}
										remove_configuration_form={undefined}
										get_error_state={get_error_state} 
										index={undefined} />
			)
		}
		let create_counter = 0
		for(const email_config of settings_data.configurations_to_create.email) {
			email_configurations_jsx.push(
				<EmailConfigurationForm key={`email-config-id-${create_counter}`} 
										config_obj={email_config} 
										get_error_state={get_error_state}
										delete_configuration={undefined}
										create_configuration={create_configuration}
										update_configuration={undefined}
										remove_configuration_form={remove_configuration_form}
										index={create_counter} />
			)
			create_counter += 1
		}
	} else {
		email_configurations_jsx.push(
			<div key="no-email-configs" className="default-settings-card">{t('Keine Konfigurationen vorhanden')}</div>
		)
	}
	
	let phone_configurations_jsx = []
	if(settings_data.configurations.phone.length || settings_data.configurations_to_create.phone.length) {
		for(const phone_config of settings_data.configurations.phone) {
			phone_configurations_jsx.push(
				<PhoneConfigurationForm key={`phone-config-id-${phone_config.id}`} 
										config_obj={phone_config}
										delete_configuration={delete_configuration}
										create_configuration={undefined} 
										update_configuration={update_configuration}
										remove_configuration_form={undefined}
										get_error_state={get_error_state} 
										index={undefined} />
			)
		}
		let create_counter = 0
		for(const phone_config of settings_data.configurations_to_create.phone) {
			phone_configurations_jsx.push(
				<PhoneConfigurationForm key={`phone-config-id-${create_counter}`} 
										config_obj={phone_config} 
										get_error_state={get_error_state}
										delete_configuration={undefined}
										create_configuration={create_configuration}
										update_configuration={undefined}
										remove_configuration_form={remove_configuration_form}
										index={create_counter} />
			)
			create_counter += 1
		}
	} else {
		phone_configurations_jsx.push(
			<div key="no-sip-configs" className="default-settings-card">{t('Keine Konfigurationen vorhanden')}</div>
		)
	}
	
	return (
		<>
			<Accordion className="default-setttings-container">
				<Accordion.Item>
					<Accordion.Header>{t('E-Mail Benachrichtigungs Konfigurationen')}</Accordion.Header>
					<Accordion.Body>
						{email_configurations_jsx}
						<Button variant="sm" className="primary-light-bg" onClick={handle_add_email_config_click}>{t('E-Mail Konfiguration hinzufügen')}</Button>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Accordion className="default-setttings-container">
				<Accordion.Item>
					<Accordion.Header>{t('Telefon/SIP Benachrichtigungs Konfigurationen')}</Accordion.Header>
					<Accordion.Body>
						{phone_configurations_jsx}
						<Button variant="sm" className="primary-light-bg" onClick={handle_add_phone_config_click}>{t('Telefon/SIP Konfiguration hinzufügen')}</Button>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}

export default AlarmNotificationConfigurationWidget;
