import React from 'react';
import {useSelector} from 'react-redux'
import { useTranslation} from 'react-i18next';
import Device from '../device/Device';
import {
	useRouteMatch
} from "react-router-dom";
import Alert from 'react-bootstrap/Alert'

let DeviceDetailPage = () => {
	const {t} = useTranslation();
	
	const match = useRouteMatch()
	
	const { dev_eui } = match.params;
	
	const device = useSelector(state => state.devices[dev_eui])
	
	let device_obj = null
	let channels = {}
	let triggered_alarm_count = 0;
	let triggered_alarm_names = [];
	if(device !== undefined) {
		device_obj = device.device
		triggered_alarm_count = device['triggered_alarms_count']
		triggered_alarm_names = device['triggered_alarm_names']
		channels  = device['channels'];
	}
	
	if(device_obj !== null) {
		return (
			<Device key={device_obj.dev_eui} device_obj={device_obj} channels={channels} triggered_alarm_count={triggered_alarm_count} triggered_alarm_names={triggered_alarm_names} />
		);
	} else {
		return (
			<Alert variant="danger" key="device-does-not-exist-or-insufficient-permission">{t('Dieses Gerät existiert nicht, oder es fehlen die Berechtigungen es anzuzeigen.')}</Alert>
		);
	}
}

export default DeviceDetailPage;