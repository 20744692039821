import React from 'react';
import {useSelector} from 'react-redux'
import { useTranslation} from 'react-i18next';
import Project from '../project/Project';
import {
	useRouteMatch
} from "react-router-dom";
import Alert from 'react-bootstrap/Alert'

let ProjectDetailPage = () => {
	const {t} = useTranslation();
	const match = useRouteMatch()
	const { project_slug } = match.params;
	
	const project = useSelector(state => state.projects[project_slug])
	
	let project_obj = null;
	let project_address = null;
	if(project !== undefined) {
		project_obj = project['project'];
		project_address = project['address'];
	}
	
	if(project_obj !== null) {
		return (
			<Project key={project_slug} project_obj={project_obj} project_address={project_address} />
		);
	} else {
		return (
			<Alert variant="danger" key="project-does-not-exist-or-insufficient-permission">{t('Dieses Projekt existiert nicht, oder es fehlen die Berechtigungen es anzuzeigen.')}</Alert>
		);
	}
}

export default ProjectDetailPage;