import React from 'react';
import {useSelector} from 'react-redux'
import { useTranslation} from 'react-i18next';
import ProjectDashboardProject from './ProjectDashboardProject';
import Alert from 'react-bootstrap/Alert'

let ProjectDashboard = () => {
	const {t} = useTranslation();
	
	const projects = useSelector(state => state.projects)
	const devices = useSelector(state => state.devices)
	
	let all_projects = [];
	for(let key of Object.keys(projects)) {
		let project_obj = projects[key]['project'];
		let project_address = projects[key]['address'];
		// are there triggered alarms?
		let triggered_alarm_count = 0;
		let total_datapoints = 0;
		let device_count = 0
		for(let dev_key of project_obj['device_ids']) {
			if(devices[dev_key] !== undefined) { // only here if the user has permission
				triggered_alarm_count += devices[dev_key]['triggered_alarms_count'];
				total_datapoints += parseInt(devices[dev_key]['device']['total_datapoints']);
				device_count += 1
			}
		}
		console.log('device_count: '+device_count)
		all_projects.push(
			<ProjectDashboardProject key={project_obj.slug} 
									 project_obj={project_obj} 
									 project_address={project_address} 
									 triggered_alarm_count={triggered_alarm_count} 
									 total_datapoints={total_datapoints} 
									 device_count={device_count} />);
	}
		
	if(all_projects.length) {
		return (
			<div className="project-overview-grid">
				{all_projects}
			</div>
		);
	} else {
		return (
			<Alert variant="danger" key="no-devices-or-no-permission">{t('Es sind keine Projekte verfügbar, oder es fehlen die Berechtigungen diese anzuzeigen.')}</Alert>
		);
	}
}

export default ProjectDashboard;
