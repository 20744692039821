import React, { useState } from 'react';
import {useSelector} from 'react-redux'
import { useTranslation} from 'react-i18next';

import DashboardUserForm from '../forms/DashboardUserForm'
import DashboardSettingsForm from '../forms/DashboardSettingsForm'
import DashboardDevicesForm from '../forms/DashboardDevicesForm'
import DashboardProjectForm from '../forms/DashboardProjectForm'

import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import TabPane from 'react-bootstrap/TabPane'


/*
https://react-dnd.github.io/react-dnd/about
*/

const DashboardSettingsDetailPage = () => {
	const {t} = useTranslation();
	
	const auth = useSelector(state => state.auth)
	
	const [tab_key, set_tab_key] = useState('dashboard-settings');
	
	const [dashboard_data, set_dashboard_data] = useState({
		dashboard: {
			id: null,
			user_id: auth.id,
			item_order: '',
			item_order_choices: [],
			dashboard_devices: [], 
			na_devices: [],
			dashboard_projects: [],
			na_projects: []
		}
	})
	
	let dashboard_settings_jsx = []
	if(dashboard_data.dashboard.id !== null) {
		dashboard_settings_jsx.push(
			<Card key="dashboard_all_settings_card">
				<Card.Header>
					<Nav variant="tabs" defaultActiveKey={tab_key} onSelect={(k) => set_tab_key(k)}>
						<Nav.Item>
							<Nav.Link eventKey="dashboard-settings">{t('Dashboard Settings')}</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="dashboard-devices">{t('Dashboard Devices')}</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link eventKey="dashboard-projects">{t('Dashboard Projects')}</Nav.Link>
						</Nav.Item>
					</Nav>
				</Card.Header>
				<Card.Body>
					<Tabs activeKey={tab_key}>
						<TabPane eventKey="dashboard-settings">
							<DashboardSettingsForm event_key="0" item_order={dashboard_data.dashboard.item_order} item_order_choices={dashboard_data.dashboard.item_order_choices} user_id={dashboard_data.user_id} set_dashboard_data={set_dashboard_data} />
						</TabPane>
						<TabPane eventKey="dashboard-devices">
							<DashboardDevicesForm event_key="0" dashboard_data={dashboard_data} set_dashboard_data={set_dashboard_data} />
						</TabPane>
						<TabPane eventKey="dashboard-projects">
							<DashboardProjectForm event_key="0" dashboard_data={dashboard_data} set_dashboard_data={set_dashboard_data} />
						</TabPane>
					</Tabs>
				</Card.Body>
			</Card>
		)
	}
	
	return (
		<Card className="project-container">
			<Card.Header as="h5">
				{t('Dashboard Konfigurieren')}
			</Card.Header>
			<Card.Body>
				<DashboardUserForm dashboard_id={dashboard_data.dashboard.id} dashboard_user_id={dashboard_data.dashboard.user_id} set_dashboard_data={set_dashboard_data} />
				{dashboard_settings_jsx}
			</Card.Body>
		</Card>
	)
}

export default DashboardSettingsDetailPage;
