import React, { useState, useEffect, useCallback } from 'react';
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import {ExclamationTriangle} from 'react-bootstrap-icons'
import Fade from 'react-bootstrap/Fade'

const HeaderServerComponentAlert = (props) => {
	
	const dispatch = useDispatch()
	
	const server_components = useSelector(state => state.server_components)
	const ws_connected = useSelector(state => state.ws.connected)
	
	const [is_checked, set_is_checked] = useState(false)
	
	const initial_check = useCallback(() => {
		dispatch(send({'type': 'celery.worker.status'}));
	}, [dispatch]) 
	
	useEffect(() => {
		if(!is_checked && ws_connected) { // load data for configuration
			initial_check();
			set_is_checked(true)
		}
	}, [is_checked, initial_check, set_is_checked, ws_connected]);
	
	return (
		<div className="">
			<Alert className="mb-0" variant="danger" dismissible={true} onClose={() => {dispatch(send({'type': 'celery.worker.status'}))}} transition={Fade} show={server_components.celery.show}>
				<Badge bg="" className="no-color-bg"><ExclamationTriangle /></Badge>
				{server_components.celery.message}
			</Alert>
		</div>
	)
}

export default HeaderServerComponentAlert;