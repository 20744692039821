import React from 'react';
import Badge from 'react-bootstrap/Badge'
import {Broadcast} from 'react-bootstrap-icons'

const DeviceCountIndicator = ({ device_count }) => {
		
	return (
		<Badge bg='' className="blue-light-bg">
			<Broadcast /> {device_count}
		</Badge>
	);
}

export default DeviceCountIndicator;