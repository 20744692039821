import React, {forwardRef} from 'react';
import ProjectStatusIndicator from '../../indicators/ProjectStatusIndicator';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const DashboardProjectWidget = forwardRef(({ project_slug, project_status,  title, type, provided }, ref) => {
	
	return (
		<Card ref={ref} key={`project-id-${project_slug}`} {...provided.draggableProps} {...provided.dragHandleProps} className="default-setttings-container">
		<Card.Header>
			<Container fluid>
  				<Row>
    				<Col className="title">{title} ({project_slug})</Col>
					<Col className="indicator-outer-container">
						<Row className="align-items-center">
							<Col className="align-right">
								<ProjectStatusIndicator project_status={project_status} />
							</Col>
						</Row>
					</Col>
  				</Row>
			</Container>
		</Card.Header>
		</Card>
		
	);
})

export default DashboardProjectWidget;