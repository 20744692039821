import React, { useRef, useCallback, useEffect, useState } from 'react';
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';
import {array_equals} from '../../utils/data'
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const SWISS_CENTER = ['46.800663464', '8.222665776']

const CustomMarker = ({ coords, set_coords }) => {
	
	useMapEvents({
		click(event) {
			set_coords(event.latlng)
		},
	})
	if(coords !== null && !array_equals(coords, SWISS_CENTER)) {
		return (<Marker position={coords} />)
	} else {
		return null
	}
}

function CustomAccordionMapItem({ project_obj, project_address, event_key }) {
	const {t} = useTranslation();
	const dispatch = useDispatch()
	const urls = useSelector(state => state.urls)
	
	const prev_coords = useRef(null)
	const [coords, set_coords] = useState(null)
	const [initial_open, set_initial_open] = useState(true)
	
	const map_ref = useRef(null)
	const default_zoom = useRef(13)
	
	
	const on_accordion_item_expaned = () => {
		map_ref.current.invalidateSize()
		if(initial_open || (project_address && Object.keys(project_address).length > 0)) {
			map_ref.current.setView(coords, default_zoom.current);
			set_initial_open(false)
		} else {
			map_ref.current.setView(coords, map_ref.current.getZoom());
		}
	}
	
	useEffect(() => {
		let temp_coords = null
		if(project_address && Object.keys(project_address).length > 0) {
			if(project_address['location_coords'].length > 0) {
				temp_coords = [project_address['location_coords'][1], project_address['location_coords'][0]]
			}
		}
		if(prev_coords.current === null && coords === null && temp_coords !== null) {
			set_coords(temp_coords)
		}
		if(prev_coords.current === null && coords === null && temp_coords === null) { // all empty, no address, default to swiss center
			default_zoom.current = 8
			set_coords(SWISS_CENTER)
			if(map_ref.current !== null) {
				map_ref.current.setView(coords, default_zoom.current);
			}
		}
		if(JSON.stringify(prev_coords.current) !== JSON.stringify(temp_coords)) {
			prev_coords.current = coords
			set_coords(temp_coords)
			if(map_ref.current !== null) {
				map_ref.current.setView(coords, map_ref.current.getZoom());
			}
		}
		
	}, [project_address, map_ref, coords, prev_coords]);
	
	const handle_save_click = useCallback(event => {
		if(coords !== null && array_equals(coords, SWISS_CENTER)) {
			dispatch(set_header_form_alert(true, "danger", t('Es wurden keine Koordinaten gesetzt.')))
			return
		}
		let data = {
			"address_id": project_address.id,
			"operation": 'geocode_reverse',
			"project_id": project_obj.id,
			"coords": coords
		}
		const url = urls['iot-api:project-update-create-address']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				dispatch(set_header_form_alert(true, "success", t('Die Markierung wurde gespeichert.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [t, dispatch, urls, project_address.id, project_obj.id, coords])

	return (
		<Accordion.Item eventKey={event_key}>
      		<Accordion.Header>{t('Adresse auf Karte wählen')}</Accordion.Header>
			<Accordion.Collapse eventKey={event_key} onEntered={on_accordion_item_expaned}>
			<Accordion.Body>
				<p>
					{t('Markierung setzen, es wird versucht die Adresse zu evaluieren. Die Evaluation beschränkt sich auf PLZ und Ort.')}
				</p>
				<div key={`map-${project_obj.slug}`} className="map-container">
					<MapContainer center={coords} zoom={default_zoom.current} whenCreated={ mapInstance => { map_ref.current = mapInstance } } >
						<TileLayer
						    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						  />
						  <CustomMarker coords={coords} set_coords={set_coords} />
					</MapContainer>
				</div>
				<div className="save-btn-container">
					<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
				</div>
			</Accordion.Body>
			</Accordion.Collapse>
		</Accordion.Item>
  );
}

const ProjectAddressMapPartForm = ({ project_obj, project_address, event_key }) => {

	return (
		<CustomAccordionMapItem eventKey={event_key} project_obj={project_obj} project_address={project_address} />
	)
}

export default ProjectAddressMapPartForm;