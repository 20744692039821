import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useSelector} from 'react-redux'

const Loader = () => {
	const MAX_STEPS = 15;
	
	const interval = useRef(null);
	const timeout = useRef(null);
	const step_width_min = 1; // min percent
	const step_width_max = parseInt(100/MAX_STEPS);
	
	const [loader_data, set_loader_data] = useState({
		loader_bar_width: 0,
		show_end: false,
		show: false
	})
	
	const show_ctr = useSelector(state => state.loader.show_ctr)
	
	const header_form_alert_show = useSelector(state => state.header_form_alert.show)
	
	const get_random_number = (min, max) => {
		return Math.random() * (max - min) + min;
	}
	
	const animation_step = useCallback(() => {
		set_loader_data(state => {
			let cw = state.loader_bar_width
			if(cw === 0) {
				cw = step_width_min
			}
			let ew = get_random_number(cw, (cw + step_width_max));
			if(ew > 100) {
				ew = 0;
			}
			return {
				loader_bar_width: ew,
				show_end: state.show_end,
				show: state.show
			}
		})
	}, [step_width_max])
	
	const on_transition_end = () => {
		if(loader_data.show_end){
			const new_loader_data = Object.assign({}, loader_data, {
				show: false, 
				show_end: false, 
				loader_bar_width: 0
			})
			set_loader_data(new_loader_data);
		}
	}
	
	let main_cls_name = 'overlay';
	if(!loader_data.show) {
		main_cls_name += ' hidden';
	} else {
		if(header_form_alert_show) {
			main_cls_name += ' with-header-alert';
		}
	}
	
	const style_var = {
		width: loader_data.loader_bar_width+'%'
	}
	
	useEffect(() => {
		if(interval.current !== null && timeout.current === null && !show_ctr && loader_data.show) { // stop with delay
			timeout.current = setTimeout(() => {
				clearInterval(interval.current);
				interval.current = null
				const new_loader_data = Object.assign({}, loader_data, {
					show: false, 
					show_end: true,
					loader_bar_width: 100
				})
				set_loader_data(new_loader_data);
				clearTimeout(timeout.current)
				timeout.current = null
			}, 750)
		}
		if(interval.current !== null && timeout.current !== null && show_ctr) { // if still showing, but in delay, clear delay
			clearTimeout(timeout.current)
			timeout.current = null
		}
		if(interval.current === null && show_ctr && !loader_data.show) { // start
			const new_loader_data = Object.assign({}, loader_data, {
				show: true, 
				show_end: false, 
				loader_bar_width: 0
			})
			set_loader_data(new_loader_data);
			interval.current = setInterval(() => animation_step(), 1000);
		}
	}, [loader_data, show_ctr, step_width_max, animation_step]);
	
	return (
		<div className={main_cls_name}>
			<div className="loader-bar-container">
				<div className="loader-bar" onTransitionEnd={on_transition_end} style={style_var}/>
			</div>
		</div>
	)
}

export default Loader