import React, { useRef, useCallback, useState } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import ProjectAddressPartForm from './ProjectAddressPartForm'
import ProjectAddressMapPartForm from './ProjectAddressMapPartForm'
import InputFloatField from './fields/InputFloatField'
import TextAreaFloatField from './fields/TextAreaFloatField'
import SelectFloatField from './fields/SelectFloatField'
import SetAlarmNotificationForm from './SetAlarmNotificationForm'
import ProjectDeleteForm from './ProjectDeleteForm'

import slugify from 'react-slugify';
import {useFormError} from './formHooks'
import {usePermission} from '../../hooks'

const ProjectOverviewSettingsForm = ({ project_obj, project_address }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	const dispatch = useDispatch()
	
	const [validated, set_validated] = useState(false);
	const [, set_form_errors, get_error_state] = useFormError({})
	
	const form_ref = useRef(null); 
	
	const input_slug_ref = useRef(null)
	
	const has_perm = usePermission()
	
	const handle_save_click = useCallback(event => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			let data = {
				"id": project_obj.id,
				"operation": "update"
			}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['iot-api:project-general-settings']();
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
				if(status === 200 && !data.error) {
					dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
					dispatch(send({'type': 'initial.data.for.settings.change'}));
				} else {
					set_form_errors(data.errors)
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				}
			})
		}
		set_validated(true);
	}, [dispatch, t, project_obj.id, urls, set_form_errors])
	
	const input_name_changed = useCallback(event => {
		const slug_value = slugify(event.target.value)
		if(input_slug_ref.current !== null) {
			input_slug_ref.current.value = slug_value
		}
	}, []);
	
	let optional_form_jsx = []
	let settings_fields_jsx = [
		<React.Fragment key='settings-fields'>
			<SelectFloatField 
				is_required={true}
				choices={project_obj.status_choices} 
				select_choice={project_obj.status} 
				field_name="status"
				floating_label={t('Status')} />
		</React.Fragment>
	]
	
	let delete_project_form_jsx = []
	if(has_perm('iot.delete_project')) {
		delete_project_form_jsx = [
			<ProjectDeleteForm key={`project-delete-form-${project_obj.id}`} project_obj={project_obj} event_key={4} />
		]
	}
	
	if(project_obj['status'] === 'open') {
		optional_form_jsx = [
			<React.Fragment key={`optional-forms-${project_obj.id}`}>
				<SetAlarmNotificationForm obj={project_obj} obj_type='project' event_key={1} />
				<ProjectAddressPartForm project_obj={project_obj} project_address={project_address} event_key={2} />
				<ProjectAddressMapPartForm project_obj={project_obj} project_address={project_address} event_key={3} />
			</React.Fragment>
		]
		settings_fields_jsx = [
			<React.Fragment key='settings-fields'>
				<InputFloatField 
					is_required={true}
					label={t('Projekt Name')} 
					field_name="name" 
					field_type="text"
					errors={get_error_state("name")}
					default_value={project_obj.name}
					event_handlers={{onChange: input_name_changed}} />
				<InputFloatField 
					is_required={true}
					label={t('Projekt ID')}
					field_name="project_id" 
					field_type="text" 
					errors={get_error_state("project_id")}
					default_value={project_obj.project_id} />
				<InputFloatField 
					is_required={true}
					label={t('Projekt URL Part')}
					field_name="slug" 
					field_type="text"
					ref={input_slug_ref}
					errors={get_error_state("slug")} 
					default_value={project_obj.slug} />
				<TextAreaFloatField
					is_required={false}
					label={t('Projekt Beschreibung')}
					field_name="description" 
					field_type="text"
					errors={get_error_state("description")} 
					default_value={project_obj.description} />
				<SelectFloatField 
					is_required={true}
					choices={project_obj.status_choices} 
					select_choice={project_obj.status} 
					field_name="status"
					floating_label={t('Status')} />
			</React.Fragment>
		]
	}
	
	return (
		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header>{t('Generelle Einstellungen')}</Accordion.Header>
				<Accordion.Body>
					<Row>
						<Col>
							<Form ref={form_ref} validated={validated}>
								{settings_fields_jsx}
							</Form>
						</Col>
					</Row>
					<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
				</Accordion.Body>
			</Accordion.Item>
			{optional_form_jsx}
			{delete_project_form_jsx}
		</Accordion>
	)
}

export default ProjectOverviewSettingsForm;