import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation} from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import {Person, PersonLock} from 'react-bootstrap-icons'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Alert from 'react-bootstrap/Alert'

import InputFloatField from './fields/InputFloatField'

import UserGroupForm from './UserGroupForm'

const UserForm = ({ user_obj, 
					index,
					get_error_state,
					delete_user,
					update_settings,
					update_password,
					create_user,
					update_user_groups,
					remove_user_form,
					is_initial_open }) => {
	//console.log(config_obj)
	const {t} = useTranslation();
	
	const [settings_validated, set_settings_validated] = useState(false);
	const [passwd_validated, set_passwd_validated] = useState(false);
	
	const setting_form_error_id_prefix = 'settings_form'
	const password_form_error_id_prefix = 'password_form'
	
	const [popover_show, set_popover_show] = useState(false)
	
	// for elements to create id is not given, but the index (position in array) -> needed for delete
	const id = user_obj.id !== undefined ? user_obj.id : index
	
	const is_new_user = user_obj.type !== undefined ? true : false
	
	// if this user is just created, open the accordion
	
	// identify the form to mark with errors (one each time only)
	const setting_form_error_id = user_obj.id !== undefined ? `${setting_form_error_id_prefix}-update-${user_obj.id}` : `${setting_form_error_id_prefix}-create-${index}`
	const password_form_error_id = user_obj.id !== undefined ? `${password_form_error_id_prefix}-update-${user_obj.id}` : `${password_form_error_id_prefix}-create-${index}`
	
	const form_settings_ref = useRef(null);
	const form_passwd_ref = useRef(null);
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const popover = (
		<Popover key={`user-remove-popover-${id}`} id="popover-basic">
		    <Popover.Header as="h3">{t('Benutzer löschen')}</Popover.Header>
		    <Popover.Body>
		    	<p>{t('Soll dieser Benutzer wirklich gelöscht werrden?')}</p>
		      	<ButtonToolbar>
		      		<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {delete_user(id)}}>{t('OK')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
		    </Popover.Body>
		</Popover>
	)
	
	
	let default_value_props = {
		email: { default_value: user_obj.email },
		first_name: { default_value: user_obj.first_name },
		last_name: { default_value: user_obj.last_name },
	}
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const update_settings_click = (event) => {
		const form = form_settings_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {
				user_id: id
			}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			// add the choosen configurations
			update_settings(data, setting_form_error_id)
		}
		set_settings_validated(true);
	}
	
	const update_password_click = (event) => {
		const form = form_passwd_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {
				user_id: id
			}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			// add the choosen configurations
			update_password(data, password_form_error_id)
		}
		set_passwd_validated(true);
	}
	
	const create_user_click = (event) => {
		const form = form_settings_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			create_user(data, setting_form_error_id, id)
		}
		set_settings_validated(true);
	}
	
	const icon_jsx = []
	if(user_obj.is_superuser) {
		icon_jsx.push(
			<PersonLock key={`person-lock-icon${user_obj.id}`} />
		)
	} else {
		icon_jsx.push(
			<Person key={`person-icon${user_obj.id}`} />
		)
	}
	
	let delete_class_prop = {
		className: 'primary-light-bg'
	}
	let delete_button_text = t('Benutzer Formular Entfernen')
	let delete_button_jsx = [
		<Button key={`config-remove-button-${id}`} variant="sm" {...delete_class_prop} onClick={event => {remove_user_form(id)}}>{delete_button_text}</Button>
	]
	let save_settings_button_onclick_prop = {
		onClick: create_user_click
	}
	
	let accordion_title_str = t('Neuer Benutzer')
	let password_form_jsx = [
		<div key={`no-password-form-${id}`}>{t('Der Benutzer muss zuerst gespeichert werden, bevor ein Passwort gesetzt werden kann und die Gruppen Zugehörigkeiten verändert werden können.')}</div>
	]
	let group_management_jsx = []
	
	// open the accordion by default
	let accordion_default_key_prop = {
		defaultActiveKey: "-1"
	} // nothing open
	if(is_initial_open) {
		accordion_default_key_prop = {
			defaultActiveKey: "0"
		} // open
	}
	
	// do we show the password dialog and some button names
	if(!is_new_user) {
		
		delete_button_text = t('Benutzer löschen')
		delete_class_prop.className = 'danger-light-bg'
		
		save_settings_button_onclick_prop.onClick = update_settings_click
		
		delete_button_jsx = [
			<OverlayTrigger key="user-delete-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
				<Button key={`user-delete-button-${id}`} variant="sm" {...delete_class_prop}>{delete_button_text}</Button>
			</OverlayTrigger>
		]
		
		accordion_title_str = `${user_obj.email} (${user_obj.first_name} ${user_obj.last_name})`
		password_form_jsx = [
			<Card className='default-settings-card no-padding' key={`password-form-${id}`}>
				<Card.Header className='default-title'>
					{t('Passwort ändern')}
				</Card.Header>
				<Card.Body>
					<Form ref={form_passwd_ref} validated={passwd_validated}>
						<Container fluid>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Passwort')}
										field_name="password" 
										field_type="password"
										key={`password`} 
										errors={get_error_state(password_form_error_id, "password")} />
								</Col>
							</Row>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Passwort wiederholen')} 
										field_name="password_repeat" 
										field_type="password"
										key={`password_repeat`}
										errors={get_error_state(password_form_error_id, "password_repeat")} />
								</Col>
							</Row>
							{
								!user_obj.has_usable_password && 
								<Alert variant="danger" key={`no-password-alert-${id}`}>
									{t('Der Benutzer kann sich nicht anmelden, weil kein Passwort gesetzt wurde. Bitte Passwort setzen!')}
								</Alert>
							}
							<ButtonToolbar>
								<ButtonGroup className="me-2">
									<Button variant="sm" className="primary-light-bg" onClick={update_password_click}>{t('Speichern')}</Button>
								</ButtonGroup>
							</ButtonToolbar>
						</Container>
					</Form>
				</Card.Body>
			</Card>
		]
		if(!user_obj.is_superuser) {
			group_management_jsx = [
				<UserGroupForm user_obj={user_obj} key={`group-management-${id}`} update_user_groups={update_user_groups} />
			]
		}
	} 
	
	return (
		<Accordion key={`user-settings-container-${user_obj.id}`} className="default-setttings-container" {...accordion_default_key_prop}>
			<Accordion.Item eventKey="0">
				<Accordion.Header>
					<Col className="default-title">{accordion_title_str}</Col>
					<Col className="indicator-outer-container">
						<Row className="align-items-center">
							<Col className="align-right me-3">
								{icon_jsx}
							</Col>
						</Row>
					</Col>
				</Accordion.Header>
				<Accordion.Body>
					<Card className='default-settings-card no-padding'>
						<Card.Header className='default-title'>
							{t('Generelle Einstellungen')}
						</Card.Header>
						<Card.Body>
							<Form ref={form_settings_ref} validated={settings_validated}>
								<Container fluid>
									<Row>
										<Col lg={true}>
											<InputFloatField 
												is_required={true}
												label={t('E-Mail')} 
												field_name="email" 
												field_type="email"
												key={`email`} 
												errors={get_error_state(setting_form_error_id, "email")}
												{...default_value_props.email} />
										</Col>
									</Row>
									<Row>
										<Col lg={true}>
											<InputFloatField 
												is_required={true}
												label={t('Vorname')} 
												field_name="first_name" 
												field_type="text"
												key={`first_name`} 
												errors={get_error_state(setting_form_error_id, "first_name")}
												{...default_value_props.first_name} />
										</Col>
										<Col lg={true}>
											<InputFloatField 
												is_required={true}
												label={t('Nachname')} 
												field_name="last_name" 
												field_type="text"
												key={`last_name`} 
												errors={get_error_state(setting_form_error_id, "last_name")}
												{...default_value_props.last_name} />
										</Col>
									</Row>
									<ButtonToolbar>
										<ButtonGroup className="me-2">
											<Button variant="sm" className="primary-light-bg" {...save_settings_button_onclick_prop}>{t('Speichern')}</Button>
										</ButtonGroup>
									</ButtonToolbar>
								</Container>
							</Form>
						</Card.Body>
					</Card>
					{password_form_jsx}
					{group_management_jsx}
					{
						!user_obj.is_superuser && 
						<ButtonToolbar>
							<ButtonGroup className="me-2 mt-3">
								{delete_button_jsx}
							</ButtonGroup>
						</ButtonToolbar>
					}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default UserForm;
