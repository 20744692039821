import React, {forwardRef} from 'react';
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const GroupPermissionWidget = forwardRef(({ name, id, content_type, provided, drag_class }, ref) => {
	return (
		<Card ref={ref} key={`permission-id-${id}`} {...provided.draggableProps} {...provided.dragHandleProps} className={drag_class}>
		<Card.Header>
			<Container fluid>
  				<Row>
    				<Col className="title">{`${content_type.app_label} | ${name}`}</Col>
  				</Row>
			</Container>
		</Card.Header>
		</Card>
		
	);
})

export default GroupPermissionWidget;