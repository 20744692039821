import React, {useState} from 'react';
import { useTranslation} from 'react-i18next';

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import DeviceTitleContainer from '../../device/DeviceTitleContainer'
import DeviceInfoContainer from '../../device/DeviceInfoContainer'

const ProjectDeviceWidget = ({ project_obj, device_obj, remove_device_click }) => {
	const {t} = useTranslation();
	
	const [popover_show, set_popover_show] = useState(false)
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const popover = (
			<Popover key={`device-remove-popover-${device_obj.id}`} id="popover-basic">
		    	<Popover.Header as="h3">{t('Geräte aus Projekt entfernen')}</Popover.Header>
		    	<Popover.Body>
		    		<p>{t('Beim entfernen des Gerätes aus dem Projekt werden alle Datenpunkte gelöscht.')}</p>
		      		<p>{t('Bitte alle Daten vorher exportieren.')}</p>
		      		<ButtonToolbar>
		      			<ButtonGroup className="me-2">
							<Button key="alarm-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {remove_device_click(device_obj.dev_eui)}}>{t('OK')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							<Button key="alarm-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
		    	</Popover.Body>
			</Popover>
	)
	
	return (
		<Card className="device-container">
			<DeviceTitleContainer device_obj={device_obj} triggered_alarm_count={null} triggered_alarm_names={null} small_title={true} /> 
			<Card.Body>
				<DeviceInfoContainer device_obj={device_obj} />
				<OverlayTrigger key="alarm-reset-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
					<Button key={`button-${device_obj['dev_eui']}`} variant="" className="danger-light-bg">{t('Gerät aus Projekt entfernen')}</Button>
				</OverlayTrigger>
			</Card.Body>
		</Card>
		
	);
}

export default ProjectDeviceWidget;