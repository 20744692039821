import React, { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper'
import { send } from '@giantmachines/redux-websocket';
import { useTranslation} from 'react-i18next';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {useSelector, useDispatch} from 'react-redux'
import {ItemTypes} from '../../utils/drag_types'
import GroupDnDContainer from './components/GroupDnDContainer'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'

import {
	set_header_form_alert,
	async_do_simple_form_post} 
from '../../actions'

const GroupObjectPermissionForm = ({ obj,
								 	 obj_type, 
									 permission_codename,
									 settings_data,
									 set_settings_data }) => {
	
	const {t} = useTranslation();
	
	const [containers, set_containers] = useState([])
	
	const dispatch = useDispatch()
	
	const urls = useSelector(state => state.urls)
	
	const [warn_global_permission, set_warn_global_permission] = useState(false)
	
	let title_str = 'No title'
	let container_title = 'No title'
	if(obj_type === 'device') {
		if(permission_codename === 'view_device') {
			title_str = t('Dieses Gerät ansehen')
			container_title = t('Sichtbar für')
		} else if(permission_codename === 'export_device_data') {
			title_str = t('Daten von diesem Gerät exportieren')
			container_title = t('Exportierbar für')
		} 
	} else if(obj_type === 'project') {
		if(permission_codename === 'view_project') {
			title_str = t('Dieses Projekt ansehen')
			container_title = t('Sichtbar für')
		} 
	}
	
	const move_permission = (source, destination, droppable_source, droppable_dest) => {
		const source_groups = Array.from(source.groups);
	  	const dest_groups = Array.from(destination.groups);
	  	
	  	const [removed] = source_groups.splice(droppable_source.index, 1);

		// check if we have overlapping permissions, only if dropped in index 0 and if permissions are assigned
		const destination_container_index = parseInt(droppable_dest.droppableId)
		if(destination_container_index === 0 && removed.permissions.some(e => e.codename === permission_codename)) {
			set_warn_global_permission(true)
		} else {
			set_warn_global_permission(false)
		}
		
		dest_groups.splice(droppable_dest.index, 0, removed);
	
		const result = {};
		result[droppable_source.droppableId] = source_groups;
		result[droppable_dest.droppableId] = dest_groups;
	
		return result;
	}
	
	const drag_end = (result) => {
		
		const { source, destination, type } = result;
		
		// dropped outside the list
		if (!destination) {
			return
		}
		
		
		if(type === ItemTypes.GROUP) {
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
		
			if (source_index === dest_index) {
				return
			}
				
			const result = move_permission(containers[source_index], containers[dest_index], source, destination);
			const new_containers = update(containers, {
				[source_index]: {
					groups: {
						$set: result[source_index]
					}
				},
				[dest_index]: {
					groups: {
						$set: result[dest_index]
					}
				}
			});
			set_containers(new_containers);
		}
	}
	
	const save_group_object_permissions_clicked = useCallback((event) => {
		
		let form_data = {
			"operation": "save_group_object_permissions",
			"obj_id": obj.id,
			"obj_type": obj_type,
			"permission_codename": permission_codename,
			"groups": containers[0].groups
		}
		const url = urls['core-api:object-permissions']();
		console.log('save_group_object_permissions form_data: ',form_data)
		dispatch(async_do_simple_form_post(form_data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Die Berechtigungen wurden gesetzt.')))
				set_settings_data({
					device_permissions: {
						view_groups: data.device_permissions.view_groups,
						view_na_groups: data.device_permissions.view_na_groups,
						export_data_groups: data.device_permissions.export_data_groups,
						export_data_na_groups: data.device_permissions.export_data_na_groups
					},
					project_permissions: {
						view_groups: data.project_permissions.view_groups,
						view_na_groups: data.project_permissions.view_na_groups,
					}
				})
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, obj.id, obj_type, permission_codename, containers, set_settings_data])
	
	useEffect(() => {
		let groups = []
		let na_groups = []
		if(obj_type === 'device') {
			if(permission_codename === 'view_device') {
				groups = settings_data.device_permissions.view_groups
				na_groups = settings_data.device_permissions.view_na_groups
			} else if(permission_codename === 'export_device_data') {
				groups = settings_data.device_permissions.export_data_groups
				na_groups = settings_data.device_permissions.export_data_na_groups
			}
		} else if(obj_type === 'project') {
			if(permission_codename === 'view_project') {
				groups = settings_data.project_permissions.view_groups
				na_groups = settings_data.project_permissions.view_na_groups
			}
		}
		set_containers([
			{ title: container_title, groups: groups }, 
		   	{ title: t('Nicht zugeordnet'), groups: na_groups },
		])
	}, [t, settings_data, container_title, obj_type, permission_codename])
	
	let warn_global_permission_jsx = []
	if(warn_global_permission) {
		warn_global_permission_jsx = [
			<Alert key="global-permission-overlap" variant="danger" className="mt-3">
				{t('Diese Berechtigung ist bereits global der Gruppe zugeordnet!')}
			</Alert>
		]
	}
	
	return (
		<Accordion defaultActiveKey="0" className="default-setttings-container">
			<Accordion.Item eventKey="0">
				<Accordion.Header>{title_str}</Accordion.Header>
				<Accordion.Body>
					<DragDropContext onDragEnd={drag_end}>
						<div className="dnd-container-wrapper">
							{containers.map(({ title, groups }, index) => (
								<Droppable key={index} droppableId={`${index}`} type={ItemTypes.GROUP}>
								{(provided, snapshot) => (
									<GroupDnDContainer
										ref={provided.innerRef}
										title={title}
										groups={groups}
										key={index}
										provided={provided}
									/>
									
								)}
								</Droppable>
					        ))}
						</div>
					</DragDropContext>
					{warn_global_permission_jsx}
					<ButtonGroup className="mt-3">
						<Button variant="sm" className="primary-light-bg" onClick={save_group_object_permissions_clicked}>{t('Speichern')}</Button>
					</ButtonGroup>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default GroupObjectPermissionForm