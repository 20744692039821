import React from 'react';
import { useSelector } from 'react-redux'
import LoginForm from './forms/LoginForm'

const MainContainer = ({ children }) => {
	
	const is_authenticated = useSelector(state => state.auth.is_authenticated);
	
	return (
		<main className="app-main">
			{is_authenticated ? children
        			    	  : <LoginForm />      }
			
		</main>
	);
}

export default MainContainer;