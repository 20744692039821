import React from 'react';
import DeviceCountIndicator from '../indicators/DeviceCountIndicator';
import AlarmIndicator from '../indicators/AlarmIndicator';
import ProjectStatusIndicator from '../indicators/ProjectStatusIndicator';
import DataPointsIndicator from '../indicators/DataPointsIndicator';

import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ProjectTitleContainer = ({ project_obj, device_count, triggered_alarm_count, total_datapoints }) => {
	return (
		<Card.Header as="h5">
			<Container fluid>
  				<Row>
    				<Col className="title">{project_obj.name}</Col>
					<Col className="indicator-outer-container">
						<Row className="align-items-center">
							<Col className="align-right">
								<AlarmIndicator triggered_alarm_count={triggered_alarm_count} device_obj={null} />
							</Col>
							<Col className="align-right">
								<ProjectStatusIndicator project_status={project_obj.status} />
							</Col>
							<Col className="align-right">
								<DeviceCountIndicator device_count={device_count} />
							</Col>
							<Col className="align-right">
								<DataPointsIndicator total_datapoints={total_datapoints} />
							</Col>
						</Row>
					</Col>
  				</Row>
			</Container>
		</Card.Header>
	)
}

export default ProjectTitleContainer;

/*
	<div className="title-container">
			<div className="title">
				<h2 key="title" className="more-padding">{project_obj.name}</h2>
			</div>
			<div className="indicator-outer-container">
				<AlarmIndicator triggered_alarm_count={triggered_alarm_count} />
				<DeviceCountIndicator device_count={device_count} />
				<ProjectStatusIndicator project_status={project_obj.status} />
			</div>
		</div>
*/