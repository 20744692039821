import React, {useState, useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'

const ProjectDeleteForm = ({ project_obj, event_key }) => {
	const {t} = useTranslation();
	
	const dispatch = useDispatch()
	
	const urls = useSelector(state => state.urls)
	
	const [popover_show, set_popover_show] = useState(false)
	
	const device_count = project_obj.device_ids.length;
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const save_clicked = useCallback(() => {
		let data = {
			"id": project_obj.id
		}
		console.log('data: ',data)
		const url = urls['iot-api:project-delete']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Das Projekt wurde gelöscht.')))
				dispatch(send({'type': 'initial.data.for.auth.change'})); // if datapoints are affected, we need the new channel data
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, project_obj.id, urls, t])
	
	let popover_jsx = [
		<React.Fragment key="popovers-jsx-without-devices">
			<p>{t('Das Projekt wird unwiderruflich gelöscht.')}</p>
		</React.Fragment>
	]
	
	if(device_count) {
		popover_jsx = [
			<React.Fragment key="popovers-jsx-with-devices">
				<p>{t('Beim löschen des Projekts werden alle Geräte aus dem Projekt entfernt. Alle Datenpunkte aller Geräte aus dem Projekt werden gelöscht!')}</p>
				<p><span>{t('Betroffene Geräte')}</span>:<span>{` ${device_count}`}</span></p>
			    <p>{t('Bitte alle Daten aller Geräte vorher exportieren.')}</p>
			</React.Fragment>
		]
	}
	
	const popover = (
			<Popover key={`project-remove-popover-${project_obj.id}`} id="popover-basic">
		    	<Popover.Header as="h3">{t('Projekt löschen')}</Popover.Header>
		    	<Popover.Body>
		    		{popover_jsx}
		      		<ButtonToolbar>
		      			<ButtonGroup className="me-2">
							<Button key="project-remove-popover-ok-button"size="sm" variant="" className="danger-light-bg" onClick={save_clicked}>{t('OK')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							<Button key="project-remove-popover-cancel-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
		    	</Popover.Body>
			</Popover>
	)
	
	let body_str = t('Das Projekt wird unwiderruflich gelöscht.')
	if(device_count) {
		body_str += " " + t('Alle Daten aller Geräte die diesem Projekt zugeordnet sind, werden entfernt.')
	}
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('Projekt löschen')}</Accordion.Header>
			<Accordion.Body>
				<div>{body_str}</div>
				<OverlayTrigger key="project-remove-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
					<Button key={`button-${project_obj.id}`} variant="" className="danger-light-bg mt-3">{t('Projekt unwiderruflich löschen')}</Button>
				</OverlayTrigger>
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default ProjectDeleteForm;