import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation} from 'react-i18next';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Alert from 'react-bootstrap/Alert'

import InputFloatField from './fields/InputFloatField'

const EmailConfigurationForm = ({ config_obj, 
								  index, 
								  get_error_state, 
								  delete_configuration,
								  create_configuration,
								  update_configuration,
								  remove_configuration_form }) => {
	//console.log(config_obj)
	const {t} = useTranslation();
	
	const [validated, set_validated] = useState(false);
	const [popover_show, set_popover_show] = useState(false)
	const [use_django_default, set_use_django_default] = useState(config_obj.use_django_default)
	
	const form_ref = useRef(null);
	const use_tls_ref = useRef(null)
	
	// for elements to create id is not given, but the index (position in array) -> needed for delete
	const id = config_obj.id !== undefined ? config_obj.id : index
	// identify the form to mark with errors (one each time only)
	const form_error_id = config_obj.id !== undefined ? `update-${config_obj.channel}-${config_obj.id}` : `create-${config_obj.channel}-${index}`
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const create_email_configuration = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			if(use_tls_ref.current !== null) {
				data['use_tls'] = use_tls_ref.current.checked
			}
			data['use_django_default'] = use_django_default
			create_configuration(data, form_error_id)
		}
		set_validated(true);
		// leads to a redraw which is a bit ugly
	}
	
	const update_email_configuration = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {
				id: id
			}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			if(use_tls_ref.current !== null) {
				data['use_tls'] = use_tls_ref.current.checked
			}
			data['use_django_default'] = use_django_default
			update_configuration(data, form_error_id)
		}
		set_validated(true);
	}
	
	const change_default_mail_config = (event) => {
		const new_use_django_default = use_django_default? false : true
		set_use_django_default(new_use_django_default)
	}
	
	let class_prop = {
		className: 'default-settings-card'
	}
	
	const popover = (
		<Popover key={`config-remove-popover-${id}`} id="popover-basic">
		    <Popover.Header as="h3">{t('E-Mail Konfiguration löschen')}</Popover.Header>
		    <Popover.Body>
		    	<p>{t('Beim bestätigen wird diese E-Mail Konfiguration gelöscht.')}</p>
		      	<ButtonToolbar>
		      		<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {delete_configuration(id, 'email')}}>{t('OK')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
		    </Popover.Body>
		</Popover>
	)
	
	let default_value_props = {
		name: { default_value: config_obj.name },
		to_email: { default_value: config_obj.to_email },
		host: { default_value: config_obj.host },
		port: { default_value: config_obj.port },
		username: { default_value: config_obj.username },
		password: { default_value: config_obj.password },
		default_from_email: { default_value: config_obj.default_from_email },
		use_tls: { defaultChecked: config_obj.use_tls }
	}
	
	let delete_class_prop = {
		className: 'primary-light-bg'
	}
	let delete_button_text = t('Entfernen')
	let delete_button_jsx = [
		<Button key={`config-remove-button-${id}`} variant="sm" {...delete_class_prop} onClick={event => {remove_configuration_form(id, 'email')}}>{delete_button_text}</Button>
	]
	let save_button_onclick_prop = {
		onClick: create_email_configuration
	}
	if(config_obj.type === undefined) { // existing config (update)
		class_prop.className += ' existing'
		delete_button_text = t('Löschen')
		delete_class_prop.className = 'danger-light-bg'
		
		save_button_onclick_prop.onClick = update_email_configuration
		
		delete_button_jsx = [
			<OverlayTrigger key="config-delete-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
				<Button key={`config-delete-button-${id}`} variant="sm" {...delete_class_prop}>{delete_button_text}</Button>
			</OverlayTrigger>
		]
	}
	
	// if the use_django_default is used, everything is hidden, except: name & to_email
	let optional_form_part_jsx = []
	if(!use_django_default) {
		optional_form_part_jsx.push(
			<Row key='row-1'>
				<Col lg={true}>
					<InputFloatField 
						is_required={true}
						label={t('SMTP Server')} 
						field_name="host" 
						field_type="text"
						key={`host-${id}-${new Date().getTime()}`} 
						errors={get_error_state(form_error_id, "host")}
						{...default_value_props.host} />
				</Col>
				<Col lg={true}>
					<InputFloatField 
						is_required={true}
						label={t('Port')} 
						field_name="port" 
						field_type="number"
						key={`port-${id}-${new Date().getTime()}`} 
						errors={get_error_state(form_error_id, "port")}
						{...default_value_props.port} />
				</Col>
			</Row>
		)
		optional_form_part_jsx.push(
			<Row key='row-2'>
				<Col lg={true}>
					<InputFloatField 
						is_required={true}
						label={t('Benutzername')} 
						field_name="username" 
						field_type="text"
						key={`username-${id}-${new Date().getTime()}`} 
						errors={get_error_state(form_error_id, "username")}
						{...default_value_props.username} />
				</Col>
				<Col lg={true}>
					<InputFloatField 
						is_required={true}
						label={t('Passwort')} 
						field_name="password" 
						field_type="password"
						key={`password-${id}-${new Date().getTime()}`} 
						errors={get_error_state(form_error_id, "password")}
						{...default_value_props.password} />
				</Col>
			</Row>
		)
		optional_form_part_jsx.push(
			<Row key='row-3'>
				<Col lg={true}>
					<InputFloatField 
						is_required={true}
						label={t('Von E-Mail')} 
						field_name="default_from_email" 
						field_type="text"
						key={`default_from_email-${id}-${new Date().getTime()}`} 
						errors={get_error_state(form_error_id, "default_from_email")}
						{...default_value_props.default_from_email} />
				</Col>
			</Row>
		)
		optional_form_part_jsx.push(
			<Row key='row-4'>
				<Col lg={true}>
					<Form.Check 
							className="form-switch-lg"
					        type="switch"
					        id="use-tls"
					        key={`use_tls-${id}-${new Date().getTime()}`} 
					        label={t('TLS')}
					        ref={use_tls_ref}
					        {...default_value_props.use_tls}
					      />
				</Col>
			</Row>
		)
	}
	
	let additional_error_jsx = []
	if(get_error_state(form_error_id, "value").length) {
		additional_error_jsx = [
			<Alert key="constraint-error-email" variant='danger'>
				{t('Diese Konnfiguration exisitert bereits!')}
			</Alert>
		]
	}
	
	return (
		<Card {...class_prop}>
			<Form ref={form_ref} validated={validated}>
				<Container fluid>
					<Row>
						<Col lg={true}>
							<Form.Check 
								className="form-switch-lg"
						        type="switch"
						        id="custom-switch"
						        label={t('Django Standard Konfiguration benutzen')}
						        defaultChecked={use_django_default}
						        onChange={change_default_mail_config}
						      />
						</Col>
					</Row>
					<Row>
						<Col lg={true}>
							<InputFloatField 
								is_required={true}
								label={t('Name')} 
								field_name="name" 
								field_type="text"
								key={`name-${id}-${new Date().getTime()}`} 
								errors={get_error_state(form_error_id, "name")}
								{...default_value_props.name} />
						</Col>
						<Col lg={true}>
							<InputFloatField 
								is_required={true}
								label={t('Empfänger E-Mail(s)')} 
								field_name="to_email" 
								field_type="text"
								key={`to_email-${id}-${new Date().getTime()}`} 
								errors={get_error_state(form_error_id, "to_email")}
								{...default_value_props.to_email} />
							<input type="hidden" readOnly="" name="config_type" value="email" />
						</Col>
					</Row>
					{optional_form_part_jsx}
					{additional_error_jsx}
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							<Button variant="sm" className="primary-light-bg" {...save_button_onclick_prop} >{t('Speichern')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							{delete_button_jsx}
						</ButtonGroup>
					</ButtonToolbar>
				</Container>
			</Form>
		</Card>
	)
}

export default EmailConfigurationForm;