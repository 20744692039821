import React from 'react';
import { connect } from 'react-redux';
import { 
	set_urls,
	set_websocket_url
} from '../actions';
import Loader from './Loader';
import django_js_reverse from '..//utils/dj_reverse';
import app_db_conf from '../utils/app_db_conf';
import { connect as websocket_connect } from '@giantmachines/redux-websocket';

class WS extends React.Component {
	componentDidMount() {
		let that = this
		async function get_urls() {
			const urls = await django_js_reverse();
			that.props.set_urls(urls);
     	}
     	get_urls();
	}
	
	componentDidUpdate = (prev_props) => {
		if(Object.keys(this.props.urls).length !== 0 && this.props.websocket_url === '') { // if urls given, and websocket url not, load the app config
			let that = this
			const app_config_url = this.props.urls['core-api:app-conf']();
			async function get_app_db_conf() {
				const app_db_config_json = await app_db_conf(app_config_url)
				if(app_db_config_json.websocket_url === "") {
					console.error('There is no usable websocket URL given. Does the configuration on the server exists?')
				}
				that.props.set_websocket_url(app_db_config_json.websocket_url);
			}
			get_app_db_conf()
		}
		if(prev_props.websocket_url === '' && this.props.websocket_url !== '') { // set the websocket url and start connecting
			this.connect_ws()
		}
	}
	
	connect_ws = () => {
		this.props.websocket_connect(this.props.websocket_url)
	}
	
	render = () => {
		return (
			<Loader />
		)
	}
}

const map_state_to_props = state => {
	return {
		urls: state.urls,
		websocket_url: state.websocket_url
	}
}

const map_dispatch_to_props = dispatch => {
	return {
		websocket_connect: url => {
			dispatch(websocket_connect(url))
		},
		set_urls: urls => {
			dispatch(set_urls(urls));
		},
		set_websocket_url: url => {
			dispatch(set_websocket_url(url));
		}
	}
}

WS = connect(
	map_state_to_props,
	map_dispatch_to_props
)(WS)

export default WS;

