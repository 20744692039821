import React, { useState, useRef, useCallback } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import { useTranslation} from 'react-i18next';
import {async_do_login} from '../../actions'
import InputFloatField from './fields/InputFloatField'
import {
	set_loader_show
} from '../../actions'

const LoginForm = ({ props }) => {
	const {t} = useTranslation();
	const auth_error = useSelector(state => state.auth.error)
	const urls = useSelector(state => state.urls)
	const dispatch = useDispatch()
	const form_ref = useRef(null);
	const [validated, set_validated] = useState(false);
	
	const handle_submit = useCallback(event => {
		const form = form_ref.current;
		if (form.checkValidity() === false) {
			set_validated(true);
		} else {
			set_validated(true);
			let data = {}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['core-api:login']();
			//dispatch(async_do_login(data, url))
			dispatch(async_do_login(data, url)).then(payload => {
				const {data} = payload.value
				if(data.is_authenticated) {
					dispatch(send({'type': 'initial.data.for.auth.change'}));
					dispatch(set_loader_show(false))
				}
			})
		}
	}, [form_ref, dispatch, urls])
	
	const key_up = useCallback(event => {
		if(event.key === 'Enter'){
			handle_submit(event)
		}
	}, [handle_submit]);
	
	return (
		<div className="login-form-container">
			<Container fluid>
			<Form validated={validated} ref={form_ref}>
				<Form.Group className="mb-3">
					<InputFloatField 
						label={t('E-Mail Adresse')}
						field_name="email" 
						field_type="email"
						placeholder={t('name@example.com')}
						event_handlers={{onKeyUp: key_up}} />
				</Form.Group>
				<Form.Group className="mb-3">
					<InputFloatField 
						label={t('Passwort')}
						field_name="password" 
						field_type="password"
						placeholder={t('Passwort')}
						event_handlers={{onKeyUp: key_up}} />
				</Form.Group>
				{
					auth_error && 
					<Alert variant="danger">
						{t('Leider ist ein Problem aufgetreten, bitte versuchen Sie es erneut.')}
					</Alert>
				}
				<Form.Group className="mb-3">
					<Button onClick={handle_submit}>{t('Anmelden')}</Button>
				</Form.Group>
			</Form>
			</Container>
		</div>
	);
}

export default LoginForm;