import React from 'react';
import { useTranslation} from 'react-i18next';
import { local_time_converter } from "../../utils/time";

import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'

const DeviceInfoContainer = ({ device_obj }) => {
	const {t} = useTranslation();
	let last_gateway = <div key="last-gateway"></div>;
	const last_gateway_info = device_obj["last_gateway_info"];
	if(last_gateway_info !== null) {
		last_gateway = <Col sm className="info-col"><div className="title">{t('Letzte Gateway ID')}</div><div>{last_gateway_info["gtw_id"]} ({last_gateway_info["provider_display"]})</div></Col>;
	}
	return (
		<Container fluid className="device-info-container">
			<Row className="justify-content-evenly">
				<Col sm className="info-col">
					<div className="title">{t('Zuletzt gesehen am')}</div><div>{local_time_converter(device_obj['last_seen'])}</div>
				</Col>
				<Col sm className="info-col">
					<div className="title">{t('Letzter RSSI Wert')}</div><div>{device_obj['last_rssi']}</div>
				</Col>
				<Col sm className="info-col">
					<div className="title">{t('Letzter SNR Wert')}</div><div>{device_obj['last_snr']}</div>
				</Col>
				{last_gateway}
			</Row>
		</Container>
	)
}

export default DeviceInfoContainer;