import React from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux'

import Card from 'react-bootstrap/Card'

import UserProfileForm from '../forms/UserProfileForm'

const UserProfileSettingsDetailPage = () => {
	const {t} = useTranslation();
	
	const auth = useSelector(state => state.auth)
	
	return (
		<Card className="settings-container">
			<Card.Header as="h5">
				{t('Benutzerprofil von')} {auth.username}
			</Card.Header>
			<Card.Body>
				<UserProfileForm auth_obj={auth} />
			</Card.Body>
		</Card>
	)
}

export default UserProfileSettingsDetailPage;
