import React, { forwardRef } from 'react';
import { Draggable } from "react-beautiful-dnd";
import Accordion from 'react-bootstrap/Accordion'
import DashboardDeviceChannelWidget from '../widgets/DashboardDeviceChannelWidget'

const ChannelDnDContainer = forwardRef(({title, channels, provided}, ref) =>  {
	
	return (
		<Accordion className="default-setttings-container mt-1-5">
			<Accordion.Item eventKey="0">
				<Accordion.Header>
					<div className="default-title">{title}</div>
				</Accordion.Header>
				<Accordion.Body ref={ref} {...provided.droppableProps}>
					{channels.map(({ id, channel_id_display, type }, index) => (
						<Draggable
	                      key={`${id}`}
	                      draggableId={`${id}`}
	                      index={index}
	                    >
	                    {(provided, snapshot) => (
				    	<DashboardDeviceChannelWidget
				    		ref={provided.innerRef}
				    		id={id}
							title={channel_id_display}
				            type={type}
				            key={index}
				            provided={provided}
				          />
				         )}
				         </Draggable>
					))}
					{provided.placeholder}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
})

export default ChannelDnDContainer;