import React, {forwardRef, useState, useLayoutEffect} from 'react';
import { Droppable } from "react-beautiful-dnd";
import { useTranslation} from 'react-i18next';
import {ItemTypes} from '../../../utils/drag_types'

import Card from 'react-bootstrap/Card'

import ChannelDnDContainer from '../components/ChannelDnDContainer'

const DashboardDeviceWidget = forwardRef(({ device_eui, title, type, all_shown_channels, all_not_shown_channels, dnd_channels_shown, provided }, ref) => {
	const {t} = useTranslation();
	
	const [containers, set_containers] = useState([])
	
	// useEffect leads to jumping and render sideeffects
	useLayoutEffect(() => {
		set_containers([
			{ title: t('Gezeigte Kanäle'), channels: all_shown_channels },
    		{ title: t('Nicht gezeigte Kanäle'), channels: all_not_shown_channels },
		])
	}, [all_shown_channels, all_not_shown_channels, t])
	
	return (
		<Card ref={ref} key={`device-id-${device_eui}`} {...provided.draggableProps} {...provided.dragHandleProps} className="default-setttings-container">
		<Card.Header>
			<div className="default-title">{title} ({device_eui})</div>
		</Card.Header>
		{dnd_channels_shown &&
		<Card.Body className="channel-dnd-container-wrapper">
			{containers.map(({ title, channels }, index) => (
				<Droppable key={`droppable-channel-${index}`} droppableId={`${device_eui}-${index}`} type={`${ItemTypes.CHANNEL}-${device_eui}`}>
				{(provided, snapshot) => (
					<ChannelDnDContainer
						ref={provided.innerRef}
						title={title}
						channels={channels}
						key={`channel-container-${index}`}
						provided={provided}
					/>
					
				)}
				</Droppable>
	        ))}
		</Card.Body>
		}
		</Card>
		
	);
})

export default DashboardDeviceWidget;