import React from 'react';
import { Provider } from 'react-redux';
import websocket from '@giantmachines/redux-websocket';
import promiseMiddleware from 'redux-promise-middleware';
import { createStore, applyMiddleware, compose } from 'redux';
import iot_app from './reducer';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

const webocket_middleware = websocket({
	reconnectInterval: 2500,
	reconnectOnClose: true,
	reconnectOnError: true,
	dateSerializer: (date) => date.getTime(),
	deserializer: JSON.parse
});

export const store = createStore(
	iot_app,
	compose(
		applyMiddleware(webocket_middleware, promiseMiddleware),
		window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
	)
)

//applyMiddleware(webocket_middleware, promiseMiddleware),

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>, 
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
