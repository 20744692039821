import React from 'react';
import { useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import DeviceTitleContainer from '../device/DeviceTitleContainer';
import DeviceInfoContainer from '../device/DeviceInfoContainer';

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const ProjectDevice = ({ device_obj, triggered_alarm_count, triggered_alarm_names }) => {
	const {t} = useTranslation();
	
	return (
		<Card className="device-container">
			<DeviceTitleContainer device_obj={device_obj} triggered_alarm_count={triggered_alarm_count} triggered_alarm_names={triggered_alarm_names} /> 
			<Card.Body>
				<DeviceInfoContainer device_obj={device_obj} />
				<Button key={`button-${device_obj['dev_eui']}`} as={Link} to={`/devices/${device_obj['dev_eui']}`} variant="" className="primary-light-bg">{t('Gerät Detail')}</Button>
			</Card.Body>
		</Card>
	);
}

export default ProjectDevice;
