import React, {useState, useEffect, useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'

import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import TabPane from 'react-bootstrap/TabPane'

import {
	set_header_form_alert,
	async_do_simple_form_post 
} from '../../actions'

import AlarmNotificationConfigurationWidget from '../forms/widgets/AlarmNotificationConfigurationWidget'
import AlarmNotificationWidget from '../forms/widgets/AlarmNotificationWidget' 
import AlarmConfigurationTestWidget from '../forms/widgets/AlarmConfigurationTestWidget'

const AlarmNotificationsSettingsDetailPage = () => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [tab_key, set_tab_key] = useState('notifications');
	
	const [is_initialized, set_is_initialized] = useState(false)
	
	const [settings_data, set_settings_data] = useState({
		notifications: [],
		notifications_to_create: [],
		configurations: {
			email: [],
			phone: [],
		},
		configurations_to_create: {
			email: [],
			phone: []
		}
	})
	
	const initial_load = useCallback(() => {
		let data = {
			"operation": "get_all_notifications"
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				set_settings_data({
					notifications: data.notifications,
					notifications_to_create: data.notifications_to_create,
					configurations: {
						email: data.configurations.email,
						phone: data.configurations.phone,
					},
					configurations_to_create: {
						email: data.configurations_to_create.email,
						phone: data.configurations_to_create.phone,
					}
				})
				set_is_initialized(true)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls])
	
	useEffect(() => {
		if(!is_initialized) { // load data for configuration
			initial_load();
		}
	}, [is_initialized, initial_load]);
	
	return (
		<Card className="settings-container">
			<Card.Header as="h5">
				{t('Alarmierungs Benachrichtigungs Einstellungen/Konfigurationen')}
			</Card.Header>
			<Card.Body>
				<p>{t('Alarm Benachrichtigungen steuern wer für einen Alarm über welche Konfiguration informiert wird. Es sind Mitteilungen über E-Mail oder Telefon/SIP möglich. Hierzu müssen der Benachrichtigung entsprechende Konfigurationen zugeordnet werden.')}</p>
				<Card key="dashboard_all_settings_card">
					<Card.Header>
						<Nav variant="tabs" defaultActiveKey={tab_key} onSelect={(k) => set_tab_key(k)}>
							<Nav.Item>
								<Nav.Link eventKey="notifications">{t('Benachrichtigungen')}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="configs">{t('Konfigurationen')}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey="test-configs">{t('Konfigurationen testen')}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Card.Header>
					<Card.Body>
						<Tabs activeKey={tab_key}>
							<TabPane eventKey="notifications">
								<AlarmNotificationWidget settings_data={settings_data} set_settings_data={set_settings_data} />
							</TabPane>
							<TabPane eventKey="configs">
								<AlarmNotificationConfigurationWidget settings_data={settings_data} set_settings_data={set_settings_data} />
							</TabPane>
							<TabPane eventKey="test-configs">
								<AlarmConfigurationTestWidget settings_data={settings_data} set_settings_data={set_settings_data} />
							</TabPane>
						</Tabs>
					</Card.Body>
				</Card>
			</Card.Body>
		</Card>
	)
}

export default AlarmNotificationsSettingsDetailPage;
