import React, { forwardRef } from 'react';
import { Draggable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import DashboardDeviceWidget from '../widgets/DashboardDeviceWidget'

const DeviceDnDContainer = forwardRef(({title, devices, dnd_channels_shown, provided}, ref) =>  {
	return (
		<Card className="half-width-dnd-container with-min-height">
			<Card.Header as="h5">
				{title}
			</Card.Header>
			<Card.Body ref={ref} {...provided.droppableProps}>
				{devices.map(({ device_eui, device_title, type, all_shown_channels, all_not_shown_channels }, index) => (
					<Draggable
                      key={device_eui}
                      draggableId={`device-${device_eui}`}
                      index={index}
                    >
                    {(provided) => (
			    	<DashboardDeviceWidget
			    		ref={provided.innerRef}
			    		device_eui={device_eui}
						title={device_title}
			            type={type}
			            key={index}
			            all_shown_channels={all_shown_channels}
			            all_not_shown_channels={all_not_shown_channels}
			            dnd_channels_shown={dnd_channels_shown}
			            provided={provided}
			          />
			         )}
			         </Draggable>
				))}
				{provided.placeholder}
			</Card.Body>
		</Card>
	)
})

export default DeviceDnDContainer;