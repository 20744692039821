import React from 'react';
import update from 'immutability-helper'
import { useTranslation} from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Bell, BellSlash} from 'react-bootstrap-icons'
import Badge from 'react-bootstrap/Badge'

import ChannelAlarmForm from '../ChannelAlarmForm'

const DeviceChannelAlarmWidget = ({ get_error_state,
									channel_alarm_obj, 
									update_channel_alarm_obj_state, 
									delete_alarm_configuration,
									create_alarm_configuration,
									update_alarm_configuration, 
									threshold_type_choices }) => {
	
	const {t} = useTranslation();
	
	const {channel, channel_alarms, channel_alarms_to_create} = channel_alarm_obj
	
	const {id, channel_id, channel_id_display} = channel
	
	const remove_alarm_form = (index) => {
		let new_channel_alarms_to_create = Array.from(channel_alarms_to_create)
		new_channel_alarms_to_create.splice(index, 1);
		const new_channel_alarm_obj = update(channel_alarm_obj, {
			channel_alarms_to_create: {
				$set: new_channel_alarms_to_create
			}
		})
		update_channel_alarm_obj_state(new_channel_alarm_obj, channel_id)
	}
	
	const badge_jsx = []
	const body_jsx = []
	let default_props = {} // only extend elements with alarm
	if(channel_alarms.length || channel_alarms_to_create.length) {
		const count = channel_alarms.length + channel_alarms_to_create.length
		badge_jsx.push(
			<React.Fragment key={`with-alarms-${channel_id}`}><Bell /><span className='ps-2 d-inline-block'>{count}</span></React.Fragment>
		)
		for(const alarm_obj of channel_alarms) {
			body_jsx.push(
				<ChannelAlarmForm key={`alarm-id-${alarm_obj.id}`}
								  get_error_state={get_error_state} 
								  alarm_obj={alarm_obj}
								  remove_alarm_form={undefined} 
								  index={undefined} 
								  delete_alarm_configuration={delete_alarm_configuration}
								  create_alarm_configuration={undefined}
								  update_alarm_configuration={update_alarm_configuration} />
			)
		}
		let create_counter = 0
		for(const alarm_obj of channel_alarms_to_create) {
			alarm_obj.index = create_counter
			body_jsx.push(
				<ChannelAlarmForm key={`alarm-id-${channel_id}-${create_counter}`}
								  get_error_state={get_error_state} 
								  alarm_obj={alarm_obj} 
								  remove_alarm_form={remove_alarm_form} 
								  index={create_counter} 
								  delete_alarm_configuration={undefined}
								  create_alarm_configuration={create_alarm_configuration}
								  update_alarm_configuration={undefined} />
			)
			create_counter += 1
		}
		default_props = {
			defaultActiveKey: "0"
		}
	} else {
		badge_jsx.push(
			<BellSlash key={`without-alarms-${channel_id}`} />
		)
		body_jsx.push(
			<div className="default-settings-card" key={`no-alarm-${channel_id}`}>{t('Kein Alarm definiert')}</div>
		)
	}
	
	const handle_add_alarm_click = (event) => {
		// add new form element
		let channel_alarms_to_create = channel_alarm_obj.channel_alarms_to_create
		channel_alarms_to_create.push({
			name: '',
			channel: id,
			threshold_type: undefined,
			threshold_type_choices: threshold_type_choices,
			trigger_pause_duration: '00:00:00',
			message: '',
			type: 'create'
		})
		
		const new_channel_alarm_obj = update(channel_alarm_obj, {
			channel_alarms_to_create: {
				$set: channel_alarms_to_create
			}
		})
		update_channel_alarm_obj_state(new_channel_alarm_obj, channel_id)
	}
	
	return (
		<Accordion key={`widget-${channel_id}`} {...default_props} className="default-setttings-container">
			<Accordion.Item eventKey="0">
				<Accordion.Header>
					<Col className="default-title">{channel_id_display}</Col>
					<Col className="indicator-outer-container">
						<Row className="align-items-center">
							<Col className="align-right me-3">
								<Badge key= "alarm-button" className="no-color-bg" bg=''>
									{badge_jsx}
								</Badge>
							</Col>
						</Row>
					</Col>
				</Accordion.Header>
				<Accordion.Body>
					{body_jsx}
					<Button variant="sm" className="primary-light-bg" onClick={handle_add_alarm_click}>{t('Alarm hinzufügen')}</Button>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
}

export default DeviceChannelAlarmWidget;