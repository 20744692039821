import React, {forwardRef} from 'react';
import Card from 'react-bootstrap/Card'

const AlarmNotificationConfigurationDndWidget = forwardRef(({ id, name,  config_type, provided }, ref) => {
	
	return (
		<Card ref={ref} key={`project-id-${id}`} {...provided.draggableProps} {...provided.dragHandleProps} className="default-setttings-container">
		<Card.Header>
			<div className="title">{name} ({config_type})</div>
		</Card.Header>
		</Card>
		
	);
})

export default AlarmNotificationConfigurationDndWidget;