import React, {useState, useCallback, forwardRef, useImperativeHandle} from 'react';
import { useTranslation} from 'react-i18next';

const DeviceChartZoomButton = forwardRef(({ chart_ref }, parent_ref) => {
	const {t} = useTranslation();
	
	const [is_zoom_button_showed, set_is_zoom_button_showed] = useState(false)
	
	useImperativeHandle(parent_ref, () => ({
	    set_zoom_button_state(show) {
	      set_is_zoom_button_showed(show)
	    }
  	}));
	
	const reset_button_clicked = useCallback(() => {
		chart_ref.current.resetZoom(chart_ref)
	}, [chart_ref])
	
	const button_style = {display: is_zoom_button_showed ? 'block': 'none'}
	
	return (
		<button className="primary-light-bg btn zoom-btn" onClick={reset_button_clicked} style={button_style}>{t('Zoom zurücksetzen')}</button>
	)
})

export default DeviceChartZoomButton;