function ctrl_zeroes(str) {
	return ("0"+str).slice(-2)
}

export const local_time_input_default_value = (iso_str) => {
	/*
		used to generate date and time string
		for default input values
	*/
	let res = {date: null, time: null};
	let dt = new Date(iso_str);
	res.date = `${dt.getFullYear()}-${ctrl_zeroes(dt.getMonth()+1)}-${ctrl_zeroes(dt.getDate())}`
	res.time = `${ctrl_zeroes(dt.getHours())}:${ctrl_zeroes(dt.getMinutes())}:${ctrl_zeroes(dt.getSeconds())}`
	return res
}

export const local_time_converter = (iso_str) => {
	let dt = new Date(iso_str);
	let res_str = `${ctrl_zeroes(dt.getDate())}.${ctrl_zeroes(dt.getMonth()+1)}.${ctrl_zeroes(dt.getFullYear())} ${ctrl_zeroes(dt.getHours())}:${ctrl_zeroes(dt.getMinutes())}:${ctrl_zeroes(dt.getSeconds())}`
	return res_str
}

export const local_time_from_timestamp = (timestamp) => {
	let dt = new Date(timestamp);
	let res_str = `${ctrl_zeroes(dt.getDate())}.${ctrl_zeroes(dt.getMonth()+1)}.${ctrl_zeroes(dt.getFullYear())} ${ctrl_zeroes(dt.getHours())}:${ctrl_zeroes(dt.getMinutes())}:${ctrl_zeroes(dt.getSeconds())}`
	return res_str
}