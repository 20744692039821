import React, {useState} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux'
import ProjectTitleContainer from './ProjectTitleContainer';
import ProjectInfoContainer from './ProjectInfoContainer';
import ProjectDevice from './ProjectDevice';
import ProjectSettingsForm from '../forms/ProjectSettingsForm';
import ProjectDevicesForm from '../forms/ProjectDevicesForm'
import GroupObjectPermissionContainer from '../GroupObjectPermissionContainer'

import Card from 'react-bootstrap/Card'
import Nav from 'react-bootstrap/Nav'
import Tabs from 'react-bootstrap/Tabs'
import TabPane from 'react-bootstrap/TabPane'

import {usePermission} from '../../hooks'

const Project = ({ project_obj, project_address }) => {
	const {t} = useTranslation();
	
	const devices = useSelector(state => state.devices)
	
	const [tab_key, set_tab_key] = useState('info');
	
	const has_perm = usePermission()
	
	let all_project_devices = [];
	let triggered_alarm_count = 0;
	let total_datapoints = 0;
	let device_count = 0
	for(let dev_key of project_obj['device_ids']) {
		if(devices[dev_key] !== undefined) { // only here if the user has permission
			device_count += 1
			let device_obj = devices[dev_key]['device'];
			triggered_alarm_count += devices[dev_key]['triggered_alarms_count'];
			total_datapoints += parseInt(devices[dev_key]['device']['total_datapoints']);
			all_project_devices.push(<ProjectDevice key={dev_key} device_obj={device_obj} triggered_alarm_count={devices[dev_key]['triggered_alarms_count']} triggered_alarm_names={devices[dev_key]['triggered_alarm_names']} />)
		}
	}
	
	let devices_nav_item_jsx = []
	let devices_tab_pane_jsx = []
	if(project_obj['status'] === 'open' && has_perm('iot.change_project')) {
		devices_nav_item_jsx = [
			<Nav.Item key={`devices-nav-item-${project_obj.id}`}>
				<Nav.Link eventKey="project-devices">{t('Geräte')}</Nav.Link>
			</Nav.Item>
		]
		devices_tab_pane_jsx = [
			<TabPane key={`devices-pane-${project_obj.id}`} eventKey="project-devices">
				<ProjectDevicesForm project_obj={project_obj} />
			</TabPane>
		]
	}
	
	let project_settings_nav_item_jsx = []
	let project_settings_tab_pane_jsx = []
	if(has_perm('iot.change_project')) {
		project_settings_nav_item_jsx = [
			<Nav.Item key="project-settings-nav-item">
				<Nav.Link eventKey="project-settings">{t('Einstellungen')}</Nav.Link>
			</Nav.Item>
		]
		project_settings_tab_pane_jsx = [
			<TabPane key="project-settings-pane" eventKey="project-settings">
				<ProjectSettingsForm project_obj={project_obj} project_address={project_address} />
			</TabPane>
		]
	}
	
	let project_object_permission_nav_item_jsx = []
	let project_object_permission_tab_pane_jsx = []
	if(has_perm('auth.change_permission')) {
		project_object_permission_nav_item_jsx = [
			<Nav.Item key="project-object-permission-nav-item">
				<Nav.Link eventKey="project-permission">{t('Berechtigungen')}</Nav.Link>
			</Nav.Item>
		]
		project_object_permission_tab_pane_jsx = [
			<TabPane eventKey="project-permission" key="project-object-permission-tab-pane">
				<GroupObjectPermissionContainer obj={project_obj} obj_type="project" event_key="project-permission" tab_key={tab_key} />
			</TabPane>
		]
	}
	
	return (
		<Card className="project-container">
			<ProjectTitleContainer project_obj={project_obj} device_count={device_count} triggered_alarm_count={triggered_alarm_count} total_datapoints={total_datapoints} />
			<Card.Body>
				<Card>
					<Card.Header>
						<Nav variant="tabs" defaultActiveKey={tab_key} onSelect={(k) => set_tab_key(k)}>
							<Nav.Item>
								<Nav.Link eventKey="info">{t('Info')}</Nav.Link>
							</Nav.Item>
							{project_settings_nav_item_jsx}
							{devices_nav_item_jsx}
							{project_object_permission_nav_item_jsx}
						</Nav>
					</Card.Header>
					<Card.Body>
						<Tabs activeKey={tab_key}>
							<TabPane eventKey="info">
								<ProjectInfoContainer project_obj={project_obj} project_address={project_address} />
								<div className="device-overview-grid">
									{all_project_devices}
								</div>
							</TabPane>
							{project_settings_tab_pane_jsx}
							{devices_tab_pane_jsx}
							{project_object_permission_tab_pane_jsx}
						</Tabs>
					</Card.Body>
				</Card>
			</Card.Body>
		</Card>
	);
}

export default Project;
