import React, {Fragment} from 'react';
import Accordion from 'react-bootstrap/Accordion'
import {useSelector} from 'react-redux'
import DashboardDevice from './device/DashboardDevice';
import ProjectDashboardProject from './project/ProjectDashboardProject';
import { useTranslation} from 'react-i18next';
import {usePermission} from '../hooks'
import Alert from 'react-bootstrap/Alert'

let Dashboard = () => {
	const {t} = useTranslation();
	
	const has_perm = usePermission()
	
	
	
	const devices = useSelector(state => state.devices)
	const dashboard_devices = useSelector(state => state.dashboard.dashboard_devices)
	const projects = useSelector(state => state.projects)
	const dashboard_projects = useSelector(state => state.dashboard.dashboard_projects)
	const item_order = useSelector(state => state.dashboard.item_order)
	
	console.log('item_order: ',item_order)
	
	let all_dashboard_devices = [];
	
	for(let key of Object.keys(dashboard_devices)) {
		const chart_channels = dashboard_devices[key].shown_channel_ids;
		if(devices[key] !== undefined) {
			const device_obj = devices[key]['device'];
			const triggered_alarm_count = devices[key]['triggered_alarms_count'];
			const triggered_alarm_names = devices[key]['triggered_alarm_names'];
			const channels = devices[key]['channels'];
			all_dashboard_devices.push(<DashboardDevice key={device_obj.dev_eui} 
														device_obj={device_obj} 
														channels={channels}
														chart_channels={chart_channels} 
														triggered_alarm_count={triggered_alarm_count}
														triggered_alarm_names={triggered_alarm_names}
														show_charts={true} />)
		}
	}
	
	let all_dashboard_projects = []
	for(let key of Object.keys(dashboard_projects)) {
		if(projects[key] !== undefined) {
			let project_obj = projects[key]['project'];
			let project_address = projects[key]['address'];
			// are there triggered alarms?
			let triggered_alarm_count = 0;
			let total_datapoints = 0;
			let device_count = 0
			for(let dev_key of project_obj['device_ids']) {
				if(devices[dev_key] !== undefined) {
					triggered_alarm_count += devices[dev_key]['triggered_alarms_count'];
					total_datapoints += parseInt(devices[dev_key]['device']['total_datapoints']);
					device_count += 1
				}
			}
			all_dashboard_projects.push(
				<ProjectDashboardProject key={project_obj.slug} 
										 project_obj={project_obj} 
										 project_address={project_address} 
										 triggered_alarm_count={triggered_alarm_count} 
										 total_datapoints={total_datapoints} 
										 device_count={device_count} />);
		}
	}
	
	let dashboard_devices_jsx = []
	if(all_dashboard_devices.length) {
		dashboard_devices_jsx = [
			<Accordion defaultActiveKey="0" key="dashboard-devices-accordion">
				<Accordion.Item eventKey="0">
					<Accordion.Header className="accordion-header-big">{t('Geräte')}</Accordion.Header>
					<Accordion.Body>
						<div className="device-overview-grid">
							{all_dashboard_devices}
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>	
		]
	}
	
	let dashboard_project_jsx = []
	if(all_dashboard_projects.length) {
		dashboard_project_jsx = [
			<Accordion defaultActiveKey="0" key="dashboard-projects-accordion">
				<Accordion.Item eventKey="0">
					<Accordion.Header className="accordion-header-big">{t('Projekte')}</Accordion.Header>
					<Accordion.Body>
						<div className="project-overview-grid">
							{all_dashboard_projects}
						</div>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		]
	}
	
	const no_dashboard_data_jsx = [
		<Alert variant="danger" key="no-dashboard-data-available">{t('Es sind keine Projekte oder Geräte konfiguriert, die auf dem Dashboard angezeigt werden könnten. Oder dem Benutzer fehlen die Berechtigungen für die Konfigurierten Geräte.')}</Alert>
	]
	
	const no_permission_jsx = [
		<Alert variant="danger" key="insufficient-permission-to-show-dashboard">{t('Die Berechtigungs Einstellungen für diesen Benutzer erlauben es nicht, dass Dashboard darzustellen.')}</Alert>
	]
	
	if(has_perm('iot.view_dashboard')) {
		if(all_dashboard_devices.length || dashboard_project_jsx.length) {
			let ordered_jsx = [
				<Fragment key="dashboard-jsx-fragment">
					{dashboard_devices_jsx}
					<div className="item-divider" />
					{dashboard_project_jsx}
				</Fragment>
			]
			if(item_order === 'projects') {
				ordered_jsx = [
					<Fragment key="dashboard-jsx-fragment">
						{dashboard_project_jsx}
						<div className="item-divider" />
						{dashboard_devices_jsx}
					</Fragment>
				]
			}
			return ordered_jsx
		} else {
			return no_dashboard_data_jsx;
		}
	} else { // no permissions
		return no_permission_jsx
	}
}

export default Dashboard;
