import React, { forwardRef } from 'react';
import { Draggable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import AlarmNotificationConfigurationDndWidget from '../widgets/AlarmNotificationConfigurationDndWidget'

const AlarmNotificationConfigurationDnDContainer = forwardRef(({title, configurations, provided}, ref) =>  {
	return (
		<Card className="half-width-dnd-container">
			<Card.Header className="default-title">
				{title}
			</Card.Header>
			<Card.Body ref={ref} {...provided.droppableProps}>
				{configurations.map(({ id, name, config_type }, index) => (
					<Draggable
                      key={id}
                      draggableId={`configuration-${id}`}
                      index={index}
                    >
                    {(provided) => (
			    	<AlarmNotificationConfigurationDndWidget
			    		ref={provided.innerRef}
			    		id={id}
			    		name={name}
						config_type={config_type}
			            key={index}
			            provided={provided}
			          />
			         )}
			         </Draggable>
				))}
				{provided.placeholder}
			</Card.Body>
		</Card>
	)
})

export default AlarmNotificationConfigurationDnDContainer;
