import React, { useState, useCallback } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';
import { send } from '@giantmachines/redux-websocket';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'

const DeviceDataDeletePartForm = ({ device_obj, event_key }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [popover_show, set_popover_show] = useState(false)
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const handle_delete_all_data = useCallback(() => {
		let data = {
			"dev_eui": device_obj.dev_eui
		}
		const url = urls['iot-api:device-delete-data']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Alle Daten wurden gelöscht.')))
				dispatch(send({'type': 'initial.data.for.auth.change'}));
				set_popover_show(false)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [device_obj, t, dispatch, urls])
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const popover = (
			<Popover key={`device-remove-popover-${device_obj.id}`} id="popover-basic">
		    	<Popover.Header as="h3">{t('Geräte aus Projekt entfernen')}</Popover.Header>
		    	<Popover.Body>
		    		<p>{t('Beim bestätigen werden alle Daten gelöscht.')}</p>
		      		<p>{t('Bitte alle Daten vorher exportieren.')}</p>
		      		<ButtonToolbar>
		      			<ButtonGroup className="me-2">
							<Button key="alarm-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {handle_delete_all_data()}}>{t('OK')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							<Button key="alarm-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
		    	</Popover.Body>
			</Popover>
	)
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('Alle Daten löschen')}</Accordion.Header>
			<Accordion.Body>
				<p>{t('Es werden alle Daten des Gerätes über den ganzen Aufzeichnungszeitraum gelöscht.')}</p>
				<OverlayTrigger key="alarm-reset-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
					<Button key={`button-${device_obj['dev_eui']}`} variant="" className="danger-light-bg">{t('Alle Daten löschen')}</Button>
				</OverlayTrigger>
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default DeviceDataDeletePartForm;