import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation} from 'react-i18next';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import {async_do_logout} from '../actions'
import {
	set_loader_show
} from '../actions'

const Navigation = (props) => {
	const auth = useSelector(state => state.auth)
	const urls = useSelector(state => state.urls)
	const dispatch = useDispatch()
	const {t} = useTranslation();
	
	const navigation = useSelector(state => state.navigation)
	
	let nav_elements = []
	let kctr = 0;
	
	for(const obj of navigation) {
		let child_elements = []
		kctr += 1
		if(obj.children.length) {
			for(const child_obj of obj.children) {
				if(child_obj.type === 'nav_item') {
					child_elements.push(
						<NavDropdown.Item key={`dd-item-${kctr}`} as={Link} to={child_obj.path}>{child_obj.label}</NavDropdown.Item>
					)
				} else if(child_obj.type === 'divider') {
					child_elements.push(
						<NavDropdown.Divider key={`dd-item-${kctr}`} />
					)
				}
				kctr += 1
			}
		} else {
			nav_elements.push(
				<Nav.Link key={`link-item-${kctr}`} as={Link} to={obj.path}>{obj.label}</Nav.Link>
			)
		}
		if(child_elements.length) {
			kctr += 1
			nav_elements.push(
				<NavDropdown key={`dd-${kctr}`} title={obj.label} menuVariant="dark">
				{child_elements}
				</NavDropdown>
			)
		}
	}
	
	const logout_clicked = (event) => {
		event.preventDefault();
		const url = urls['core-api:logout']();
		dispatch(async_do_logout(url)).then(payload => {
			dispatch(set_loader_show(false))
		})
	}
	
	let auth_elements = []
	if(auth.is_authenticated) {
		console.log('backend-link: '+auth.backend_link)
		auth_elements.push(
			<Nav key="additional-information">
				<NavDropdown key="user-menu-dropdown" title={`${auth.username} (${auth.email})`} menuVariant="dark">
					<NavDropdown.Item key="user-profile" as={Link} to="/user/profile">{t('Benutzer Profil')}</NavDropdown.Item>
					{auth.is_superuser && auth.backend_link !== '' &&
						<NavDropdown.Item key="backend-link" href={auth.backend_link}>{t('Django-Administration')}</NavDropdown.Item>
					}
					<NavDropdown.Item key="logout" onClick={logout_clicked}>{t('Abmelden')}</NavDropdown.Item>
				</NavDropdown>
			</Nav>
		)
	}
	
	return (
		<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
			<Container fluid={true}>
				<Navbar.Brand href="/">M^2</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
  				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						{nav_elements}
					</Nav>
					{auth_elements}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
}

export default Navigation;