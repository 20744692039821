import React, { useState, useRef } from 'react';
import { useTranslation} from 'react-i18next';
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import InputFloatField from './fields/InputFloatField'
import SelectFloatField from './fields/SelectFloatField'

const ChannelAlarmForm = ({ alarm_obj, 
							remove_alarm_form,
							delete_alarm_configuration,
							create_alarm_configuration,
							update_alarm_configuration,
							get_error_state,
							index }) => {
	
	const {t} = useTranslation();

	const [popover_show, set_popover_show] = useState(false)
	
	const form_ref = useRef(null); 
	
	// for elements to create id is not given, but the index (position in array) -> needed for delete
	const id = alarm_obj.id !== undefined ? alarm_obj.id : index
	// identify the form to mark with errors (one each time only)
	const form_error_id = alarm_obj.id !== undefined ? `update-${alarm_obj.channel}-${alarm_obj.id}` : `create-${alarm_obj.channel}-${index}`
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const create_alarm = (event) => {
		const form = form_ref.current;
		const fdata = new FormData(form);
		let data = {}
		for (let [key, value] of fdata) {
			data[key] = value;
		}
		create_alarm_configuration(data, form_error_id)
	}
	
	const update_alarm = (event) => {
		const form = form_ref.current;
		const fdata = new FormData(form);
		let data = {
			id: id
		}
		for (let [key, value] of fdata) {
			data[key] = value;
		}
		update_alarm_configuration(data, form_error_id)
	}
	
	let class_prop = {
		className: 'default-settings-card'
	}
	
	const popover = (
		<Popover key={`alarm-remove-popover-${id}`} id="popover-basic">
		    <Popover.Header as="h3">{t('Alarm Konfiguration löschen')}</Popover.Header>
		    <Popover.Body>
		    	<p>{t('Beim bestätigen wird dieser Alarm gelöscht.')}</p>
		      	<ButtonToolbar>
		      		<ButtonGroup className="me-2">
						<Button key="alarm-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {delete_alarm_configuration(id)}}>{t('OK')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button key="alarm-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
		    </Popover.Body>
		</Popover>
	)
	
	let default_value_props = {
		name: { default_value: alarm_obj.name },
		threshold_type: { select_choice: alarm_obj.threshold_type },
		value: { default_value: alarm_obj.value},
		trigger_pause_duration: { default_value: alarm_obj.trigger_pause_duration },
		message: { default_value: alarm_obj.message }
	}
	
	let delete_class_prop = {
		className: 'primary-light-bg'
	}
	let delete_button_text = t('Entfernen')
	let delete_button_jsx = [
		<Button key={`alarm-remove-button-${id}`} onClick={event => {remove_alarm_form(id)}} variant="sm" {...delete_class_prop}>{delete_button_text}</Button>
	]
	let save_button_onclick_prop = {
		onClick: create_alarm
	}
	if(alarm_obj.type === undefined) { // existing alarm (update)
		class_prop.className += ' existing'
		delete_button_text = t('Löschen')
		delete_class_prop.className = 'danger-light-bg'
		
		save_button_onclick_prop.onClick = update_alarm
		
		delete_button_jsx = [
			<OverlayTrigger key="alarm-delete-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
				<Button key={`alarm-delete-button-${id}`} variant="sm" {...delete_class_prop}>{delete_button_text}</Button>
			</OverlayTrigger>
		]
	}
	
	return (
		<Card {...class_prop}>
			<Form ref={form_ref}>
				<Container fluid>
					<Row>
						<Col lg={true}>
							<InputFloatField 
								is_required={true}
								label={t('Name')} 
								field_name="name" 
								field_type="text"
								key={`name-${id}-${new Date().getTime()}`} 
								errors={get_error_state(form_error_id, "name")}
								{...default_value_props.name} />
						</Col>
						<Col lg={true}>
							<SelectFloatField 
								is_required={true}
								choices={alarm_obj.threshold_type_choices} 
								{...default_value_props.threshold_type}
								field_name="threshold_type"
								key={`threshold_type-${id}-${new Date().getTime()}`} 
								floating_label={t('Schwellenwert Typ')} />
						</Col>
					</Row>
					<Row>
						<Col lg={true}>
							<InputFloatField 
								is_required={true}
								label={t('Wert')} 
								field_name="value" 
								field_type="number"
								key={`value-${id}-${new Date().getTime()}`}
								errors={get_error_state(form_error_id, "value")}
								{...default_value_props.value} />
						</Col>
						<Col lg={true}>
							<InputFloatField 
								is_required={true}
								label={t('Auslöser Pause')} 
								field_name="trigger_pause_duration" 
								field_type="text"
								placeholder="00:00:00"
								key={`trigger_pause_duration-${id}-${new Date().getTime()}`}
								errors={get_error_state(form_error_id, "trigger_pause_duration")}
								class_name="mb-1"
								{...default_value_props.trigger_pause_duration} />
							<small className="form-text text-muted mb-3 d-inline-block">
							  {t("Pause zwischen der Auslösung.")}
							</small>
						</Col>
					</Row>
					<Row>
						<Col lg={true}>
							<InputFloatField 
								is_required={true}
								label={t('Alarm Nachricht')} 
								field_name="message" 
								field_type="text"
								placeholder="holdrio placeholder time"
								key={`message-${id}-${new Date().getTime()}`}
								errors={get_error_state(form_error_id, "message")}
								class_name="mb-1"
								{...default_value_props.message} />
							<small className="form-text text-muted mb-3 d-inline-block">
							  {t('Der Alarm Wert kann mit {value}, der Alarm Name mit {name} substituiert werden.')}
							</small>
							<input type="hidden" readOnly="" name="channel" value={alarm_obj.channel} />
						</Col>
					</Row>
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							<Button variant="sm" className="primary-light-bg" {...save_button_onclick_prop} >{t('Speichern')}</Button>
						</ButtonGroup>
					</ButtonToolbar>
					<ButtonToolbar>
						<ButtonGroup className="me-2">
							{delete_button_jsx}
						</ButtonGroup>
					</ButtonToolbar>
				</Container>
			</Form>
		</Card>
	);
}

export default ChannelAlarmForm;