import React, {useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'
import update from 'immutability-helper'
//import { send } from '@giantmachines/redux-websocket';
import Button from 'react-bootstrap/Button'
import {useFormIDError} from '../formHooks'
import UserForm from '../UserForm'

import {
	set_header_form_alert,
	set_authentication,
	async_do_simple_form_post
} from '../../../actions'

import {
	anonymous_auth_obj
} from '../../../reducer'

const UserWidget = ({ settings_data, set_settings_data }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [, set_form_errors, get_error_state] = useFormIDError({})
	
	const delete_user = useCallback((id) => {
		let data = {
			"operation": "delete_user",
			"user_id": id
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				console.log('data is: ',data)
				dispatch(set_header_form_alert(true, "success", t('Der Benutzer wurde entfernt.')))
				const new_settings_data = update(settings_data, {
					users: {
						$set: data.users
					}
				})
				set_settings_data(new_settings_data)
				if(data.do_logout) {
					console.log('logout is asked')
					dispatch(set_authentication(anonymous_auth_obj, false))
				}
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, settings_data, set_settings_data])
	
	const update_settings = useCallback((form_data, form_error_id) => {
		let data = {
			"operation": "update_settings",
			...form_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			set_form_errors({[form_error_id]: {}})
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				const new_settings_data = update(settings_data, {
					users: {
						$set: data.users
					}
				})
				set_settings_data(new_settings_data) 
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [dispatch, set_form_errors, t, urls, settings_data, set_settings_data])
	
	const update_password = useCallback((form_data, form_error_id) => {
		let data = {
			"operation": "update_password",
			...form_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			set_form_errors({[form_error_id]: {}})
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				dispatch(set_header_form_alert(true, "success", t('Das Passwort wurde aktualisiert.')))
				const new_settings_data = update(settings_data, {
					users: {
						$set: data.users
					}
				})
				set_settings_data(new_settings_data) 
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [dispatch, set_form_errors, t, urls, settings_data, set_settings_data])
	
	const create_user = useCallback((form_data, form_error_id, index) => {
		let data = {
			"operation": "create_user",
			...form_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			set_form_errors({[form_error_id]: {}})
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				const new_users_to_create = Array.from(settings_data.users_to_create)
				new_users_to_create.splice(index, 1)
				const new_settings_data = update(settings_data, {
					users: {
						$set: data.users
					},
					users_to_create: {
						$set: new_users_to_create
					},
					just_created_user_id: {
						$set: data.just_created_user_id
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [dispatch, set_form_errors, t, urls, settings_data, set_settings_data])
	
	const update_user_groups = useCallback((group_data) => {
		let data = {
			"operation": "update_user_groups",
			...group_data
		}
		const url = urls['core-api:user-groups']();
		
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				const new_settings_data = update(settings_data, {
					users: {
						$set: data.users
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Die Gruppen wurden aktualisiert.')))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, settings_data, set_settings_data])
	
	const add_user_click = (event) => {
		let users_to_create = Array.from(settings_data.users_to_create)
		users_to_create.push({
			email: '',
			first_name: '',
			last_name: '',
			has_usable_password: false,
			type: 'create'
		})
		
		const new_settings_data = update(settings_data, {
			users_to_create: {
				$set: users_to_create
			}
		}) 
		
		set_settings_data(new_settings_data)
	}
	
	const remove_user_form = (index) => {
		const new_users_to_create = Array.from(settings_data.users_to_create)
		new_users_to_create.splice(index, 1)
		const new_settings_data = update(settings_data, {
			users_to_create: {
				$set: new_users_to_create
			}
		})
		set_settings_data(new_settings_data) 
	}
	
	let user_forms_jsx = []
	if(settings_data.users.length || settings_data.users_to_create.length) {
		for(const user_obj of settings_data.users) {
			let is_initial_open = (user_obj.id === settings_data.just_created_user_id || !user_obj.has_usable_password) ? true : false
			user_forms_jsx.push(
				<UserForm key={`user-update-${user_obj.id}`} 
						  user_obj={user_obj}
						  index={undefined}
						  get_error_state={get_error_state}
						  delete_user={delete_user}
						  update_settings={update_settings}
						  update_password={update_password}
						  create_user={undefined}
						  update_user_groups={update_user_groups}
						  remove_user_form={undefined}
						  is_initial_open={is_initial_open} />
			)
		}
		let create_counter = 0
		for(const user_obj of settings_data.users_to_create) {
			user_obj.index = create_counter
			user_forms_jsx.push(
				<UserForm key={`user-create-${create_counter}`} 
						  user_obj={user_obj}
						  index={create_counter}
						  get_error_state={get_error_state}
						  delete_user={undefined}
						  update_settings={undefined}
						  update_password={undefined}
						  create_user={create_user}
						  update_user_groups={undefined}
						  remove_user_form={remove_user_form}
						  is_initial_open={true} />
			)
			create_counter += 1
		}
	} else {
		user_forms_jsx.push(
			<div key="no-users" className="default-settings-card">{t('Keine Benutzer vorhanden')}</div>
		)
	}
	
	return (
		<>
			{user_forms_jsx}
			<Button variant="sm" className="primary-light-bg mt-3" onClick={add_user_click}>{t('Benutzer hinzufügen')}</Button>
		</>
	)
}

export default UserWidget;