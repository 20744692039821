import React, {forwardRef} from 'react';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

const TextAreaFloatField = forwardRef(({ label, field_name, default_value, field_type, placeholder, event_handlers, is_required, errors }, ref) => { 
	
	if(placeholder === undefined) {
		placeholder = ""
	}
	
	if(event_handlers === undefined) {
		event_handlers = {}
	}
	
	let required = false
	if(is_required !== undefined) {
		if(is_required) {
			required = true
			label = `${label}*`
		}
	}
	
	let invalid = false
	let error_arr = []
	if(errors !== undefined && errors.length) {
		error_arr = errors
		invalid = true
	}
	
	return (
		<FloatingLabel
			label={label}
			className="mb-3"
			key={`fl-${field_name}-${error_arr.length}`}>
			<Form.Control
				as="textarea"
				required={required}
				type={field_type} 
				name={field_name}
				placeholder={placeholder}
				defaultValue={default_value}
				isInvalid={invalid}
				style={{ height: '100px' }}
				ref={ref}
				{...event_handlers} />
				<Form.Control.Feedback type="invalid">
              		{errors}
            	</Form.Control.Feedback>
		</FloatingLabel>

	)
})

//controlId="floatingtextarea"

export default TextAreaFloatField;