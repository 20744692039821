import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumb from 'react-bootstrap/Breadcrumb'

const BreadcrumbTrail = ({ items }) => {
	let result_jsx = null
	if(items.length) {
		result_jsx = []
		let ctr = 0
		for(const bobj of items) {
			ctr += 1
			if(bobj.path === undefined) {
				result_jsx.push(
					<Breadcrumb.Item key={`item-${ctr}`} active>{bobj.label}</Breadcrumb.Item>
				)	
			} else {
				result_jsx.push(
					<Breadcrumb.Item key={`item-${ctr}`} linkAs={Link} linkProps={{to: bobj.path}}>{bobj.label}</Breadcrumb.Item>
				)
			}
		}		
	}
	return (
		<Breadcrumb key="breadcrum-trail" className="breadcrumb-container">
		{result_jsx}
		</Breadcrumb>
	)
}

export default BreadcrumbTrail