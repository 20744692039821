import React from 'react';
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

const SelectFloatField = ({ choices, select_choice, field_name, floating_label }) => {
	
	let options_jsx = []
	let ctr = 0
	for(let index in choices) {
		let choice = choices[index]
		if(select_choice === undefined && !ctr) { // if there is no selected choice, we select the first one as default
			select_choice = choice
		}
		options_jsx.push(			
			<option key={`option-${choice.value}`} value={choice.value}>{choice.label}</option>
		)
		ctr += 1
	}
	
	return (

		<FloatingLabel
			label={floating_label}
			className="mb-3">
			<Form.Select
				key={`fl-${field_name}-${new Date().getTime()}`}
				defaultValue={select_choice}
				name={field_name}>
					{options_jsx}
			</Form.Select>
		</FloatingLabel>
	)
}

export default SelectFloatField;