import React, {useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'
import {useFormError} from '../formHooks'
import EmailConfigurationTestForm from '../EmailConfigurationTestForm'
import PhoneConfigurationTestForm from '../PhoneConfigurationTestForm'

import {
	set_header_form_alert,
	async_do_simple_form_post} 
from '../../../actions'

const AlarmConfigurationTestWidget = ({ settings_data, set_settings_data }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [, set_form_errors, get_error_state] = useFormError({})
	
	const test_config = useCallback((form_data) => {
		let data = {
			"operation": "test_config",
			...form_data
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				if(form_data.config_type === 'email') {
					dispatch(set_header_form_alert(true, "success", t('Die Nachricht wurde verschickt.')))
				} else {
					dispatch(set_header_form_alert(true, "success", t('Der Anruf wurde ausgelöst.')))
				}
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors(data.errors)
			}
		})
	}, [dispatch, t, urls, set_form_errors])
	
	return (
		<>
			<Accordion className="default-setttings-container">
				<Accordion.Item>
					<Accordion.Header>{t('E-Mail Benachrichtigungs Konfigurationen')}</Accordion.Header>
					<Accordion.Body>
						<p>{t('Für eine Test Nachricht die gewünschte Nachricht eingeben und die passende E-Mail Konfiguration wählen.')}</p>
						<EmailConfigurationTestForm configurations={settings_data.configurations.email} 
													get_error_state={get_error_state}
													test_config={test_config}/>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			<Accordion className="default-setttings-container">
				<Accordion.Item>
					<Accordion.Header>{t('Telefon/SIP Benachrichtigungs Konfigurationen')}</Accordion.Header>
					<Accordion.Body>
						<p>{t('Für einen Test Anruf die gewünschte Nachricht eingeben und die passende Telefon/SIP Konfiguration wählen.')}</p>
						<PhoneConfigurationTestForm configurations={settings_data.configurations.phone} 
													get_error_state={get_error_state}
													test_config={test_config}/>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	)
}

export default AlarmConfigurationTestWidget;
