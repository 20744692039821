import React, { useState, useEffect } from 'react';
import update from 'immutability-helper'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import { useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import {ItemTypes} from '../../utils/drag_types'
import GroupDnDContainer from './components/GroupDnDContainer'

const UserGroupForm = ({ user_obj,
						 update_user_groups }) => {
	
	const {t} = useTranslation();
	
	const [containers, set_containers] = useState([])
	
	useEffect(() => {
		set_containers([
			{ title: t('Benutzer ist Mitglied'), groups: user_obj.groups }, 
		   	{ title: t('Nicht zugeordnet'), groups: user_obj.na_groups },
		])
	}, [user_obj, t])
	
	const move_group = (source, destination, droppable_source, droppable_dest) => {
		const source_groups = Array.from(source.groups);
	  	const dest_groups = Array.from(destination.groups);
	  	
	  	const [removed] = source_groups.splice(droppable_source.index, 1);
	
		dest_groups.splice(droppable_dest.index, 0, removed);
	
		const result = {};
		result[droppable_source.droppableId] = source_groups;
		result[droppable_dest.droppableId] = dest_groups;
	
		return result;
	}
	
	const drag_end = (result) => {
		const { source, destination, type } = result;
		
		// dropped outside the list
		if (!destination) {
			return
		}
		
		
		if(type === ItemTypes.GROUP) {
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
		
			if (source_index === dest_index) {
				return
			}
				
			const result = move_group(containers[source_index], containers[dest_index], source, destination);
			const new_containers = update(containers, {
				[source_index]: {
					groups: {
						$set: result[source_index]
					}
				},
				[dest_index]: {
					groups: {
						$set: result[dest_index]
					}
				}
			});
			set_containers(new_containers);
		}
	}
	
	const update_user_groups_click = (event) => {
		let data = {
			user_id: user_obj.id
		}
		// add the choosen configurations
		data['groups'] = containers[0].groups
		update_user_groups(data)
	}
	
	return (
		<Card className='default-settings-card no-padding'>
			<Card.Header className='default-title'>
				{t('Gruppen')}
			</Card.Header>
			<Card.Body>
				<DragDropContext onDragEnd={drag_end}>
					<div className="dnd-container-wrapper">
						{containers.map(({ title, groups }, index) => (
							<Droppable key={index} droppableId={`${index}`} type={ItemTypes.GROUP}>
							{(provided, snapshot) => (
								<GroupDnDContainer
									ref={provided.innerRef}
									title={title}
									groups={groups}
									key={index}
									provided={provided}
								/>
								
							)}
							</Droppable>
				        ))}
					</div>
				</DragDropContext>
					<ButtonGroup className="mt-3">
						<Button variant="sm" className="primary-light-bg" onClick={update_user_groups_click}>{t('Speichern')}</Button>
					</ButtonGroup>
			</Card.Body>
		</Card>
	)
}

export default UserGroupForm;

