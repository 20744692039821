import update from 'immutability-helper'
import React, { useCallback, useState, useEffect } from 'react';
import { send } from '@giantmachines/redux-websocket';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import ProjectDnDContainer from './components/ProjectDnDContainer'
import {ItemTypes} from '../../utils/drag_types'

const DashboardProjectForm = ({dashboard_data, set_dashboard_data}) =>  {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const {dashboard_projects, na_projects} = dashboard_data.dashboard
	const [containers, set_containers] = useState([])
	
	const handle_save_click = useCallback(event => {
		let data = {
			"operation": "update_dashboard_projects",
			"id": dashboard_data.dashboard.id,
			"user_id": dashboard_data.dashboard.user_id,
			"dashboard_projects": containers[0].projects,
			"na_projects": containers[1].projects
		}
		const url = urls['iot-api:dashboard-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				dispatch(send({'type': 'dashboard.update'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [containers, dashboard_data, dispatch, t, urls])
	
	useEffect(() => {
		set_containers([
			{ title: t('Auf Dashboard gezeigt'), projects: dashboard_projects }, // only show channels dnd container for devices that are shown
		   	{ title: t('Nicht auf Dashboard gezeigt'), projects: na_projects },
		])
	}, [dashboard_projects, na_projects, t])
	
	const reorder_project = (list, start_index, end_index) => {
	  	const result = Array.from(list.projects)
		const [removed] = result.splice(start_index, 1)
		result.splice(end_index, 0, removed)
		return result
	}
	
	const move_project = (source, destination, droppable_source, droppable_dest) => {
		const source_projects = Array.from(source.projects);
	  	const dest_projects = Array.from(destination.projects);
	  	
	  	const [removed] = source_projects.splice(droppable_source.index, 1);
	
		dest_projects.splice(droppable_dest.index, 0, removed);
	
		const result = {};
		result[droppable_source.droppableId] = source_projects;
		result[droppable_dest.droppableId] = dest_projects;
	
		return result;
	}
	
	const drag_end = (result) => {
		const { source, destination, type } = result;
		console.log('source: ', source)
		console.log('destination: ', destination)
		console.log('type: ',type)

	    // dropped outside the list
		if (!destination) {
			return
		}
		
		if(type === ItemTypes.PROJECT) {
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
		
			if (source_index === dest_index) {
				const reordered_projects = reorder_project(containers[source_index], source.index, destination.index);
				const new_containers = update(containers, {
					[source_index]: {
						projects: {
							$set: reordered_projects
						}
					}
				})
				set_containers(new_containers);
			} else {
				const result = move_project(containers[source_index], containers[dest_index], source, destination);
				const new_containers = update(containers, {
					[source_index]: {
						projects: {
							$set: result[source_index]
						}
					},
					[dest_index]: {
						projects: {
							$set: result[dest_index]
						}
					}
				});
				set_containers(new_containers);
		    }
		}
	}
	
	return (
		<>
		<DragDropContext onDragEnd={drag_end}>
		<div className="dnd-container-wrapper">
			{containers.map(({ title, projects }, index) => (
				<Droppable key={index} droppableId={`${index}`} type={ItemTypes.PROJECT}>
				{(provided, snapshot) => (
					<ProjectDnDContainer
						ref={provided.innerRef}
						title={title}
						projects={projects}
						key={index}
						provided={provided}
					/>
					
				)}
				</Droppable>
	        ))}
		</div>
        </DragDropContext>
		<div className="mt-3">
			<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
		</div>
		</>
	)
}

export default DashboardProjectForm;