import update from 'immutability-helper'
import {
	SET_URLS,
	SET_WEBSOCKET_URL,
	SET_AUTHENTICATION,
	SET_HEADER_FORM_ALERT,
	SET_LOADER_SHOW,
	SET_DATA_PREFETCH_LOADING,
	IOT_WEBSOCKET_OPEN,
	IOT_WEBSOCKET_MESSAGE,
	IOT_WEBSOCKET_SEND,
	IOT_WEBSOCKET_CLOSED,
	IOT_WEBSOCKET_BROKEN,
	async_do_login,
	async_do_logout,
	async_do_simple_form_post,
	async_do_http_csv_export
} from './actions';


export const initial_state = {
	ws: {
		connected: false
	},
	loader: {
		show_ctr: 0 // counter, positive is showing, 0 is hiding (as the loader could called showning at the same time from multiple sources)
	},
	urls: {},
	websocket_url: '',
	auth: {
		is_superuser: false,
		is_authenticated: false,
		all_permissions: new Set([]),
		all_user_group_object_permissions: new Set([]),
		username: "",
		email: "",
		first_name: "",
		last_name: "",
		backend_link: "",
		id: 0,
		error: false
	},
	server_components: {
		celery: {
			show: false,
			message: ''
		}
	},
	navigation: [],
	projects: {}, // all projects
	devices: {}, // all devices
	dashboard: { // dashboard specific
		item_order: '',
		dashboard_devices: [], // all devices on the current user dashboard
		dashboard_projects: [] // all projects on the current user dashboard
	},
	settings: { // some setting specific data
		projects: {
			na_devices: {}, // all devices that are not assigned to a project
		},
		all_users: [], // all available users, only given if the user is superuser or can edit dashboards
		alarm_notifications: {
			all_notifications: [],
			devices_with_project_notifications: {}
		},
		data_prefetch: {
			max_initial_values_loaded: null,
			loading: false,
			all_loaded_euis: [] // list with all device euis that have all data loaded -> if the user visited detail view
		}
	},
	header_form_alert: {
		show: false,
		cls: 'success',
		message: ""
	}
}

///////////////// helper data
export const anonymous_auth_obj = { // default on error or similar
	is_authenticated: false,
	is_superuser: false,
	all_permissions: [],
	all_user_group_object_permissions: [],
	first_name: "",
	last_name: "",
	username: "",
	email: "",
	id: 0,
	backend_link: ""
}

///////////////// helper functions

const handle_loader_show_ctr = (show, old_show_ctr) => { // check to what count we set the show loader ctr, used multiple times
	let new_show_counter = 0
	if(show) {
		new_show_counter = old_show_ctr + 1	
	} else {
		new_show_counter = old_show_ctr - 1
		if(new_show_counter < 0) {
			new_show_counter = 0
		}
	}
	return {
		loader: {
			show_ctr: new_show_counter
		}
	}
}

const handle_initial_devices_update = (devices, old_devices) => {
	/*
		if we just update settings, we overtake channel_data
		channel_data is undefined on a settings update
	*/
	let new_devices = {...devices} // shallow copy, take it as it is, if channel_data is given
	for(let dev_eui of Object.keys(devices)) {
		const device_entry = devices[dev_eui] 
		const channels = device_entry['channels'];
		for(let channel_id of Object.keys(channels)) {
			if(channels[channel_id]['channel_data'] === undefined) { // does not exist, override with old device value
				new_devices[dev_eui]['channels'][channel_id]['channel_data'] = old_devices[dev_eui]['channels'][channel_id]['channel_data']
			}
		}
	}
	return new_devices
}

const handle_websocket_message = (payload, state) => {
	/*
		a big part of the reducer is just
		websocket message handling, outsource
		this to a specific function
	*/
	const message = payload.message
	console.log('message: ',message)
	const {type} = message
	if(type === 'need_login') {
		return Object.assign({}, state, {
			...handle_loader_show_ctr(false, state.loader.show_ctr)
		});
	} else if(type === 'initial.data') {
		const new_auth = update(state.auth, {
			$set: message.auth
		})
		const new_settings = update(state.settings, {
			all_users: {
				$set: message.settings.all_users
			},
			projects: {
				na_devices: {
					$set: message.settings.projects.na_devices
				}
			},
			alarm_notifications: {
				all_notifications: {
					$set: message.settings.alarm_notifications.all_notifications
				},
				devices_with_project_notifications: {
					$set: message.settings.alarm_notifications.devices_with_project_notifications
				}
			},
			data_prefetch: {
				max_initial_values_loaded: {
					$set: message.settings.data_prefetch.max_initial_values_loaded
				}
				// all_loaded_euis is never modified on the server
			}
		})
		const new_navigation = update(state.navigation, {
			$set: message.navigation
		})
		// handle device updates different for auth or for settings update
		const new_devices = handle_initial_devices_update(message.devices, state.devices)
		const new_projects = update(state.projects, {
			$set: message.projects
		})
		const new_dashboard = update(state.dashboard, {
			item_order: {
				$set: message.dashboard.item_order
			},
			dashboard_devices: {
				$set: message.dashboard.dashboard_devices
			},
			dashboard_projects: {
				$set: message.dashboard.dashboard_projects
			}
		})
			 
		return Object.assign({}, state, {
			auth: new_auth,
			navigation: new_navigation,
			devices: new_devices,
			projects: new_projects,
			dashboard: new_dashboard,
			settings: new_settings,
			...handle_loader_show_ctr(false, state.loader.show_ctr)
		});
	} else if(type === 'dashboard.update') {
		const new_dashboard = update(state.dashboard, {
			item_order: {
				$set: message.dashboard.item_order
			},
			dashboard_devices: {
				$set: message.dashboard.dashboard_devices
			},
			dashboard_projects: {
				$set: message.dashboard.dashboard_projects
			}
		})
		return Object.assign({}, state, {
			dashboard: new_dashboard,
			...handle_loader_show_ctr(false, state.loader.show_ctr)
		});
	} else if(type === 'device.reset.alarms'){
		const device_info = message.device_info;
		const all_keys = Object.keys(device_info)
		if(all_keys.length) {
			const dev_eui = all_keys[0];
			let channels_alarm_info = {}
			const triggered_alarms_count = device_info[dev_eui]["triggered_alarms_count"]
			const triggered_alarm_names = device_info[dev_eui]["triggered_alarm_names"]
			const channels = device_info[dev_eui]["channels"];
			for(let channel_id of Object.keys(channels)) {
				let channel = channels[channel_id];
				channels_alarm_info[channel_id] = { "channel": {
					"channel_alarm": channel["channel_alarm"], 
					"channel_alarm_triggered": channel["channel_alarm_triggered"]
				}}
			}
					
			//
			let alarm_device = { ...state.devices[dev_eui] }; // make shallow copy
			alarm_device.triggered_alarms_count = triggered_alarms_count;
			alarm_device.triggered_alarm_names = triggered_alarm_names;
					
			for(let alarm_channel_id of Object.keys(channels_alarm_info)) {
				const channel_alarm = channels_alarm_info[alarm_channel_id]["channel_alarm"];
				const channel_alarm_triggered = channels_alarm_info[alarm_channel_id]["channel_alarm_triggered"];
				alarm_device['channels'][alarm_channel_id]["channel"] = Object.assign({}, alarm_device["channels"][alarm_channel_id]["channel"], {
					"channel_alarm": channel_alarm,
					"channel_alarm_triggered": channel_alarm_triggered
				});
			}
				
			const new_devices = update(state.devices, {
				[dev_eui]: {
					$set: alarm_device
				}
			})
					
			return Object.assign({}, state, {
				devices: new_devices,
				...handle_loader_show_ctr(false, state.loader.show_ctr)
			});
		} else {
			return Object.assign({}, state, {
				...handle_loader_show_ctr(false, state.loader.show_ctr)
			});
		}
	} else if(type === 'device.update') {
		if(state.auth.is_authenticated) {
			const device_info = message.device_info
			const all_keys = Object.keys(device_info)
			if(all_keys.length) {
				const dev_eui = all_keys[0];
				const device = device_info[dev_eui];
				
				let update_device = { ...state.devices[dev_eui] }; // make shallow copy
				
				if(Object.entries(update_device).length === 0) {
					console.warn('we can not update the device, because the user has not enough permission')
					return state
				}
				
				let new_channels = {}
					
				for(let update_channel_id of Object.keys(device.channels)) {
					let update_channel = update_device.channels[update_channel_id];
					let action_update_channel = device.channels[update_channel_id];
							
					update_channel.channel_alarm = action_update_channel.channel_alarm;
					update_channel.channel_alarm_triggered = action_update_channel.channel_alarm_triggered;
					update_channel.channel_data = [...update_channel.channel_data, ...action_update_channel.channel_data];
					
					new_channels[update_channel_id] = update_channel
				}
					
				// assign all attributes
				const new_update_device = update(update_device, {
					$set: device,
				})
				// assign new channel data
				const new_channel_update_device = update(new_update_device, {
					channels: {
						$set: new_channels
					}
				})
				// assign new devices
				const new_devices = update(state.devices, {
					[dev_eui]: {
						$set: new_channel_update_device
					}
				})
				// udpate state
				return Object.assign({}, state, {
					devices: new_devices
				});
			} else {
				console.warn('device.update: There is no usable device key! Abort!');
				return state
			}
		} else {
			console.warn('device.update: The user is not authenticated, no data available, skip update!')
			return state
		}
	} else if (type === 'load.missing.device.data') {
		console.log('load.missing_device.data, message: ',message)
		if(state.auth.is_authenticated) {
			const device_info = message.device_info
			const all_keys = Object.keys(device_info)
			if(all_keys.length) {
				const dev_eui = all_keys[0];
				const device = device_info[dev_eui];
				
				// see if we skip this message, because that request is from another browser
				if(state.settings.data_prefetch.all_loaded_euis.includes(dev_eui)) {
					console.log('skip this message, we are already loaded')
					return state
				}
				
				let update_device = { ...state.devices[dev_eui] }; // make shallow copy
				
				let new_channels = {}
					
				for(let update_channel_id of Object.keys(device.channels)) {
					let update_channel = update_device.channels[update_channel_id];
					let action_update_channel = device.channels[update_channel_id];
							
					update_channel.channel_alarm = action_update_channel.channel_alarm;
					update_channel.channel_alarm_triggered = action_update_channel.channel_alarm_triggered;
					update_channel.channel_data = [...action_update_channel.channel_data, ...update_channel.channel_data];
					
					new_channels[update_channel_id] = update_channel
				}
					
				// assign all attributes
				const new_update_device = update(update_device, {
					$set: device,
				})
				// assign new channel data
				const new_channel_update_device = update(new_update_device, {
					channels: {
						$set: new_channels
					}
				})
				// assign new devices
				const new_devices = update(state.devices, {
					[dev_eui]: {
						$set: new_channel_update_device
					}
				})
				
				const new_settings = update(state.settings, {
					data_prefetch: {
						all_loaded_euis: {
							$push: all_keys
						},
						loading: {
							$set: false
						}
					}
				})
				return Object.assign({}, state, {
					devices: new_devices,
					settings: new_settings,
					...handle_loader_show_ctr(false, state.loader.show_ctr)
				});
			} else {
				console.warn('load.missing.device.data: There is no usable device key! Abort!');
				return state
			}
		} else {
			console.warn('load.missing.device.data: The user is not authenticated, no data available, skip update!')
			return state
		}
	} else if(type === 'celery.worker.status') {
		const new_server_components = update(state.server_components, {
			celery: {
				$set: {show: message.show, message: message.message}
			}
		})
		return Object.assign({}, state, {
			server_components: new_server_components,
			...handle_loader_show_ctr(false, state.loader.show_ctr)
		});
	} else {
		console.warn('message type not handled: '+type)
		return state
	}
}

const handle_authentication = (auth_obj, error, state) => {
	auth_obj.error = error
	const new_auth_obj = update(state.auth, {
		$merge: auth_obj
	})
	if(auth_obj.is_authenticated) {
		return Object.assign({}, state, {
			auth: new_auth_obj
		});
	} else { // clear out some data
		return Object.assign({}, state, {
			auth: new_auth_obj,
			navigation: [],
			projects: {},
			dashboard: {
				item_order: '',
				dashboard_devices: [],
				dashboard_projects: []
			},
			settings: { 
				projects: {
					na_devices: {}, 
				},
				all_users: [],
				alarm_notifications: {
					all_notifications: [],
					devices_with_project_notifications: {}
				},
				data_prefetch: {
					max_initial_values_loaded: null,
					loading: false,
					all_loaded_euis: []
				}
			},
		});
	}
}

///////////////// reducer itself

const iot_app = (state = initial_state, action) => {
	/*console.log('>>>>>>>>>>>>>>>>>>>> iot_app, action.type: ', action.type)
	console.log('state: ',state)*/
	
	switch (action.type) {
		case IOT_WEBSOCKET_OPEN:
			return Object.assign({}, state, {
				ws: {
					connected: true
				}
			});
		case IOT_WEBSOCKET_BROKEN:
		case IOT_WEBSOCKET_CLOSED:
			return Object.assign({}, state, {
				ws: {
					connected: false
				}
			});
		case IOT_WEBSOCKET_SEND: // just used to show the loader
			return Object.assign({}, state, {
				...handle_loader_show_ctr(true, state.loader.show_ctr)
			});
		case IOT_WEBSOCKET_MESSAGE: // on websocket message, heavy workload
			return handle_websocket_message(action.payload, state)
		case SET_URLS:
			return Object.assign({}, state, {
				urls: action.urls
			});
		case SET_WEBSOCKET_URL:
			return Object.assign({}, state, {
				websocket_url: action.url
			});
		case SET_HEADER_FORM_ALERT:
			return Object.assign({}, state, {
				header_form_alert: {
					show: action.show,
					cls: action.cls,
					message: action.message
				}
			});
		case SET_LOADER_SHOW:
			return Object.assign({}, state, {
				...handle_loader_show_ctr(action.show, state.loader.show_ctr)
			});
		case SET_AUTHENTICATION:
			return handle_authentication(action.auth_obj, action.error, state)
		// async http posts
		case async_do_login.fulfilled.toString():
			console.log('action.payload: ', action.payload)
			if(action.payload.data.is_authenticated) {
				return Object.assign({}, state, {
					...handle_loader_show_ctr(true, state.loader.show_ctr)
				});
			} else {
				return handle_authentication(anonymous_auth_obj, true, state)
			}
		case SET_DATA_PREFETCH_LOADING:
			const new_settings = update(state.settings, {
				data_prefetch: {
					loading: {
						$set: action.loading
					}
				}
			})
			return Object.assign({}, state, {
				settings: new_settings
			});
		case async_do_login.rejected.toString():
			return handle_authentication(anonymous_auth_obj, true, state)
		case async_do_logout.fulfilled.toString():
			const new_state = handle_authentication(anonymous_auth_obj, false, state)
			return Object.assign({}, new_state, {
				...handle_loader_show_ctr(true, state.loader.show_ctr)
			});
		case async_do_logout.rejected.toString():
			return Object.assign({}, state, {
				header_form_alert: {
					show: true,
					cls: 'danger',
					message: action.payload.toString()
				}
			});
		case async_do_simple_form_post.pending.toString():
			return Object.assign({}, state, {
				...handle_loader_show_ctr(true, state.loader.show_ctr)
			});
		case async_do_simple_form_post.fulfilled.toString():
			return Object.assign({}, state, {
				...handle_loader_show_ctr(false, state.loader.show_ctr)
			});
		case async_do_http_csv_export.pending.toString():
			return Object.assign({}, state, {
				...handle_loader_show_ctr(true, state.loader.show_ctr)
			});
		case async_do_http_csv_export.fulfilled.toString():
			return Object.assign({}, state, {
				...handle_loader_show_ctr(false, state.loader.show_ctr)
			});
		default:
			return state
	}
}

export default iot_app
