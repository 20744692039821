export const array_equals = (a, b) => {
  return Array.isArray(a) &&
		 Array.isArray(b) &&
    	 a.length === b.length &&
		 a.every((val, index) => val === b[index]);
}

export const prepare_channel_data = (channels, max_count=null, excluded_channels=[], filter_values={}) => {
	/*
		prepare the data for the graph
		save it as x, y object
		
		max count restricts the data to
		a specific count from end, means
		last x values, if 0 it is unlimited
		
		build in some null values if the
		timespan is bigger than 1 hour, 
		this will "break" the line in the
		chart and prevent to much interpolation
		
		always exclude battery_voltage
	*/
	
	if(!excluded_channels.includes('battery_voltage')) {
		excluded_channels.push('battery_voltage');
	}
	
	// save the data
	let data = {
		'temperature': [],
		'temperature_alarm': [],
		'humidity': [],
		'humidity_alarm': [],
		'pressure': [],
		'pressure_alarm': [],
		'pressure_1': [],
		'pressure_1_alarm': [],
		'pressure_2': [],
		'pressure_2_alarm': [],
		'temperature_1': [],
		'temperature_1_alarm': [],
		'temperature_2': [],
		'temperature_2_alarm': [],
		'object_temperature': [],
		'object_temperature_alarm': [],
		'dew_point': [],
		'dew_point_alarm': [],
		'dew_point_delta': [],
		'dew_point_delta_alarm': [],
	}
	
	// save the min and max values for the y axis scale
	let yaxis_config = {
		'temperature': {min: null, max: null},
		'humidity': {min: null, max: null},
		'pressure': {min: null, max: null},
		'pressure_1': {min: null, max: null},
		'temperature_1': {min: null, max: null},
		'pressure_2': {min: null, max: null},
		'temperature_2': {min: null, max: null},
		'object_temperature': {min: null, max: null},
		'dew_point': {min: null, max: null},
		'dew_point_delta': {min: null, max: null},
	}
	
	// save first and last datetime to use it in filter as initial value
	let first_datetime_entry = null;
	let last_datetime_entry = null;
	
	let filter_start_dt = null;
	let filter_end_dt = null;
	if(Object.keys(filter_values).length !== 0) {
		filter_start_dt = new Date(`${filter_values.start_d} ${filter_values.start_t}`)
		filter_end_dt = new Date(`${filter_values.end_d} ${filter_values.end_t}`)
	}
	
	// not using map
	for(let channel_id of Object.keys(channels)) {
		if(excluded_channels.includes(channel_id)) {
			continue;
		}
		
		// prepare y marker
		let channel_alarm_arr = channels[channel_id]['channel_alarm'];
		let alarm_value_min = null
		let alarm_value_max = null
		for(let y = 0; y < channel_alarm_arr.length; y++) {
			const alarm = channel_alarm_arr[y];
			if(alarm_value_min === null && alarm_value_max === null) {
				alarm_value_min = alarm.value
				alarm_value_max = alarm.value
			} else {
				if(alarm.value < alarm_value_min) {
					alarm_value_min = alarm.value
				}
				if(alarm.value > alarm_value_max) {
					alarm_value_max = alarm.value
				}
			}
		}
		// prepare channel data
		let channel_data_arr = channels[channel_id]['channel_data'];
		let i = 0;
		if(max_count !== null) {
			i = channel_data_arr.length - max_count;
			if(i < 0) {
				i = 0;
			}
		}
		
		let last_timestamp = null;
		for(i; i < channel_data_arr.length; i++) {
			let channel_data = channel_data_arr[i];
			let value = channel_data.value;
			let timestamp = channel_data.js_timestamp;
			
			if(i === channel_data_arr.length - 1) {
				last_datetime_entry = new Date(timestamp);
			}
			
			if(filter_start_dt !== null && filter_end_dt !== null) {
				let cdt = new Date(timestamp)
				if(cdt < filter_start_dt) {
					continue;
				}
				if(cdt > filter_end_dt) {
					break;
				}
			}
			
			// evaluate min and max for the y axis
			if(yaxis_config[channel_id]['min'] === null) {
				yaxis_config[channel_id]['min'] = Math.floor(value)
				if(alarm_value_min !== null && alarm_value_min < yaxis_config[channel_id]['min']) {
					yaxis_config[channel_id]['min'] = Math.floor(alarm_value_min)
				}
				yaxis_config[channel_id]['min'] -= 1
			} else {
				const tmp_min = yaxis_config[channel_id]['min']
				if(value < yaxis_config[channel_id]['min']) {
					yaxis_config[channel_id]['min'] = Math.floor(value)
				}
				if(alarm_value_min !== null && alarm_value_min < yaxis_config[channel_id]['min']) {
					yaxis_config[channel_id]['min'] = Math.floor(alarm_value_min)
				}
				if(tmp_min !== yaxis_config[channel_id]['min']) {
					yaxis_config[channel_id]['min'] -= 1
				}
			}
			if(yaxis_config[channel_id]['max'] === null) {
				yaxis_config[channel_id]['max'] = Math.ceil(value)
				if(alarm_value_max !== null && alarm_value_max > yaxis_config[channel_id]['max']) {
					yaxis_config[channel_id]['max'] = Math.ceil(alarm_value_max)
				}
				yaxis_config[channel_id]['max'] += 1
			} else {
				const tmp_max = yaxis_config[channel_id]['max']
				if(value > yaxis_config[channel_id]['max']) {
					yaxis_config[channel_id]['max'] = Math.ceil(value)
				}
				if(alarm_value_max !== null && alarm_value_max > yaxis_config[channel_id]['max']) {
					yaxis_config[channel_id]['max'] = Math.ceil(alarm_value_max)
				}
				if(tmp_max !== yaxis_config[channel_id]['max']) {
					yaxis_config[channel_id]['max'] += 1
				}
			}
			
			// check if there is an hour gap, to split the graph
			if(last_timestamp !== null) {
				let difference = timestamp - last_timestamp ;
				if(difference >= 3.6e6) { // more than 1 hour difference, gap it
					let null_timestamp = last_timestamp += 10000; // timestamp + 10 seconds
					data[channel_id].push({x: null_timestamp, y: null});
				}
				data[channel_id].push({x: timestamp, y: value});
			} else {
				first_datetime_entry = new Date(timestamp);
				data[channel_id].push({x: timestamp, y: value});
			}
			//data[channel_id].push({x: datetime, y: value});
			last_timestamp = timestamp;
		}
	}
	
	return { 
		data: data, 
		yaxis_config: yaxis_config, 
		first_datetime_entry: first_datetime_entry, 
		last_datetime_entry: last_datetime_entry
	};
}