import React, { useRef, useCallback, useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import { useTranslation} from 'react-i18next';
import { useHistory } from 'react-router-dom'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import InputFloatField from './fields/InputFloatField'
import TextAreaFloatField from './fields/TextAreaFloatField'

import slugify from 'react-slugify';
import {useFormError} from './formHooks'

const ProjectAddForm = ({ children }) => {
	const {t} = useTranslation();
	
	const history = useHistory();
	const urls = useSelector(state => state.urls)
	const projects = useSelector(state => state.projects)
	const dispatch = useDispatch()
	
	const [validated, set_validated] = useState(false);
	const [, set_form_errors, get_error_state] = useFormError({})
	
	const created_project_slug_ref = useRef(null)
	const form_ref = useRef(null)
	const input_slug_ref = useRef(null)
	
	// forward the user to the created project with useEffect.
	useEffect(() => {
		if(created_project_slug_ref.current !== null) {
			const project_slug = created_project_slug_ref.current
			created_project_slug_ref.current = null
			history.push(`/projects/${project_slug}`)
		}
	}, [projects, history])
	
	const handle_save_click = useCallback(event => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			let data = {
				"operation": "create"
			}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['iot-api:project-general-settings']();
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
				if(status === 200 && !data.error) {
					dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
					dispatch(send({'type': 'initial.data.for.settings.change'}));
					// assign the slug to the ref, to use it in useEffect and prevent a "does not exist page" if forwarded to early
					created_project_slug_ref.current = data.slug
				} else {
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
					set_form_errors(data.errors)
				}
			})
		}
		set_validated(true);
	}, [dispatch, t, urls, set_form_errors])
	
	const input_name_changed = useCallback(event => {
		const slug_value = slugify(event.target.value)
		if(input_slug_ref.current !== null) {
			input_slug_ref.current.value = slug_value
		}
	}, []);
	
	return (
		<Form ref={form_ref} validated={validated}>
			<Row>
				<Col>
					<InputFloatField 
						is_required={true}
						label={t('Projekt Name')} 
						field_name="name" 
						field_type="text"
						errors={get_error_state("name")}
						event_handlers={{onChange: input_name_changed}} />
				</Col>
				<Col>
					<InputFloatField 
						is_required={true}
						label={t('Projekt ID')}
						field_name="project_id" 
						field_type="text"
						errors={get_error_state("project_id")} />
				</Col>
				<Col>
					<InputFloatField 
						is_required={true}
						label={t('Projekt URL')}
						field_name="slug" 
						field_type="text"
						ref={input_slug_ref}
						errors={get_error_state("slug")} />
				</Col>
			</Row>
			<Row>
				<Col>
					<TextAreaFloatField
						is_required={true}
						label={t('Projekt Beschreibung')}
						field_name="description" 
						field_type="text"
						errors={get_error_state("description")} />
				</Col>
			</Row>
			<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
		</Form>
	)
}

export default ProjectAddForm;
