import React, {useRef, useState, useEffect, useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import { 
	ALL_EXISTING_CHANNEL_IDS, 
	AVAILABLE_CHANNELS,
	CHART_TITLE_BIG,
	CHART_X_SCALE_BIG,
	CHART_Y_SCALE_BIG,
	CHART_CHANNEL_DATASET_CONFIG,
	CHART_ANNOTATION_LABEL,
	CHART_ANNOTATION,
	CHART_ZOOM_PLUGIN,
	CHART_ANNOTATIONS,
	CHART_PLUGINS_BIG,
	CHART_OPTIONS_BIG,
	CHART_CONFIG_BIG
} from '../../Config';
import ResponsiveLineChartCanvas from '../ResponsiveLineChartCanvas';
//import ResponsiveLineChartSVG from '../ResponsiveLineChartSVG';
import LastValuesTable from '../LastValuesTable'
import { prepare_channel_data } from "../../utils/data";
import DeviceContainer from "./DeviceContainer"
import {de} from 'date-fns/locale';
import {
	set_data_prefetch_loading,
	set_loader_show
} from '../../actions'


const Device = ({ device_obj, triggered_alarm_count, triggered_alarm_names, channels }) => {
	const {t} = useTranslation();
	
	const all_loaded_euis = useSelector(state => state.settings.data_prefetch.all_loaded_euis)
	
	const loading = useSelector(state => state.settings.data_prefetch.loading)
	const loading_ref = useRef(loading) // save the previous state of redux state above
	
	const dispatch = useDispatch()
	
	const [rendered_chart_count, set_rendered_chart_count] = useState(0)
	
	let chart_options = {};
	
	console.log('prepare data')
	let all_channel_data = prepare_channel_data(channels);
	
	// called from chart afterRender, this happens often
	const chart_after_render_callback = useCallback(() => {
		if(loading_ref.current && !loading) { // only if it was loading and it is not loading right now
			if(rendered_chart_count < AVAILABLE_CHANNELS[device_obj.dev_type].length) {
				set_rendered_chart_count((rendered_chart_count) =>  rendered_chart_count + 1)
			}
		}
	}, [rendered_chart_count, device_obj.dev_type, loading])
	
	// show loader during drawing, multiple effects
	
	useEffect(() => { // update prev reference
		//console.log('useeffect loading')
		loading_ref.current = loading
	}, [loading])
	
	useEffect(() => { // decide if finished
		console.log('decide if finished')
		if(rendered_chart_count === AVAILABLE_CHANNELS[device_obj.dev_type].length && 
		   !loading_ref.current && 
		   !loading) {
			dispatch(set_loader_show(false))
		}
	}, [loading, device_obj.dev_type, loading_ref, rendered_chart_count, dispatch])
	
	// decide if we need to load data
	
	useEffect(() => {
		//console.log('decide if we need to load data')
		if(!all_loaded_euis.includes(device_obj.dev_eui)) {
			dispatch(send({'type': 'load.missing.device.data', 'json_content': {"dev_eui": device_obj.dev_eui}}));
			dispatch(set_data_prefetch_loading(true))
			dispatch(set_loader_show(true))
		}
	}, [dispatch, all_loaded_euis, device_obj])
	
	let data = all_channel_data.data;
	let yaxis_config = all_channel_data.yaxis_config;
	let all_charts = {};
	let all_values_tables = {};
		
	for(const channel_id of ALL_EXISTING_CHANNEL_IDS) { // initialize them all empty
		all_charts[channel_id] = []
		all_values_tables[channel_id] = []
	}
	let has_data = true; // check if we have data, else do not render chart infos
	// create all charts/axis config for all available channels
	for(let i = 0; i < AVAILABLE_CHANNELS[device_obj.dev_type].length; i++) {
		//console.log('loop through channels')
		const channel_id = AVAILABLE_CHANNELS[device_obj.dev_type][i]
		if(!data[channel_id].length) {
			has_data = false;
			break; // break the whole loop
		}
		
		let channel_alarm_arr = channels[channel_id]['channel_alarm'];
		let chart_annotations = []
		for(let y = 0; y < channel_alarm_arr.length; y++) {
			const alarm = channel_alarm_arr[y];
			const chart_annotation_label = Object.assign({}, CHART_ANNOTATION_LABEL, {
				content: alarm.name
			})
			const chart_annotation_obj = Object.assign({}, CHART_ANNOTATIONS, {
				id: `line_${alarm.id}`,
				value: parseFloat(alarm.value),
				label: chart_annotation_label
			})
			chart_annotations.push(chart_annotation_obj)
		}
			
			
		const x_scale_title = Object.assign({}, CHART_TITLE_BIG, {
			text: t('Zeit')
		})
		const x_scale = Object.assign({}, CHART_X_SCALE_BIG, {
			suggestedMin: all_channel_data.first_datetime_entry,
			suggestedMax: all_channel_data.last_datetime_entry,
			adapters: {
				date: {
					locale: de,
				}
			},
			title: x_scale_title
		})
		const y_scale_title = Object.assign({}, CHART_TITLE_BIG, {
			text: `${channels[channel_id]['channel']['channel_id_display']} (${channels[channel_id]['channel']['unit_display']})`
		})
		const y_scale = Object.assign({}, CHART_Y_SCALE_BIG, {
			suggestedMin: yaxis_config[channel_id].min,
			suggestedMax: yaxis_config[channel_id].max,
			title: y_scale_title
		})
		
		let data_set_config = Object.assign({}, CHART_CHANNEL_DATASET_CONFIG[channel_id], {
			label: `${channels[channel_id]['channel']['channel_id_display']} (${channels[channel_id]['channel']['unit_display']})`,
			data: [...data[channel_id]]
		})
		
		const chart_annotation = Object.assign({}, CHART_ANNOTATION, {
			annotations: chart_annotations
		})
		
		const chart_zoom = Object.assign({}, CHART_ZOOM_PLUGIN, {
			onZoomComplete: undefined
		})
		
		const chart_plugins = Object.assign({}, CHART_PLUGINS_BIG, {
			annotation: chart_annotation,
			zoom: {
				pan: {
					enabled: true,
					mode: 'x',
					modifierKey: 'ctrl'
				},
				zoom: chart_zoom
			}
		})
			
		// this would update the chart all the time, because the axis are updated
		/*const chart_options = Object.assign({}, CHART_OPTIONS_BIG, {
			scales: {
				x: x_scale,
				y: y_scale
			},
			plugins: chart_plugins
		})*/
			
		// this keeps zoom, but, if the value would be over axis, it would not show until reload
		/*if(!(`${device_obj.dev_eui}-${channel_id}` in chart_options)) {
			chart_options[`${device_obj.dev_eui}-${channel_id}`] = Object.assign({}, CHART_OPTIONS_BIG, {
				scales: {
					x: x_scale,
					y: y_scale
				},
				plugins: chart_plugins
			})
		}*/
			
			
		// the alarms would not be updated, if we use the method # 2 above. Use still a ref, that we do not have to rewrite.
		chart_options[`${device_obj.dev_eui}-${channel_id}`] = Object.assign({}, CHART_OPTIONS_BIG, {
			scales: {
				x: x_scale,
				y: y_scale
			},
			plugins: chart_plugins
		})
		
		let chart_info = Object.assign({}, CHART_CONFIG_BIG, {
			options: chart_options[`${device_obj.dev_eui}-${channel_id}`],
			datasets: [data_set_config]
		})
		
		all_charts[channel_id].push(<ResponsiveLineChartCanvas key={channel_id} 
															   channel_id={channel_id} 
															   device_obj={device_obj} 
															   chart_info={chart_info} 
															   chart_type="big" 
															   chart_after_render_callback={chart_after_render_callback} />);
		
		all_values_tables[channel_id].push(<LastValuesTable key={`last-values-table-${channel_id}`}
															values={data[channel_id].slice(-5)}
															channel_id={channel_id} 
															channel_name={channels[channel_id]['channel']['channel_id_display']}
															channel_unit={channels[channel_id]['channel']['unit_display']} />)
	}
	let chart_items = []
	let table_items = []
	if(!has_data) {
		chart_items.push(<div className="sensor-container error" key='error'>
							<div>{t('Keine Daten vorhanden.')}</div>
						 </div>);
	} else {
		if(AVAILABLE_CHANNELS[device_obj.dev_type].includes('temperature') && AVAILABLE_CHANNELS[device_obj.dev_type].includes('humidity')) {
			chart_items.push(
				<div className="sensor-container" key='container-1'>
					<h3>{t('SHT-85 Temp/Feuchte')}</h3>
					<div className="sensor-chart-container">
						{all_charts['temperature'][0]}
						{all_charts['humidity'][0]}
					</div>
				</div>
			);
			table_items.push(
				<div className='outer-wrapper' key='table-1'>
					<div className="inner-wrapper">
						<h4>{t('SHT-85 Temp/Feuchte')}</h4>
						{all_values_tables['temperature'][0]}
						{all_values_tables['humidity'][0]}
					</div>
				</div>
			);
		}
		if(AVAILABLE_CHANNELS[device_obj.dev_type].includes('pressure')) {
			chart_items.push(
				<div className="sensor-container" key='container-2'>
					<h3>{t('SDP-810 Drucksensor')}</h3>
					<div className="sensor-chart-container">
						{all_charts['pressure'][0]}
					</div>
				</div>
			);
			table_items.push(
				<div className='outer-wrapper' key='table-2'>
					<div className="inner-wrapper">
						<h4>{t('SDP-810 Drucksensor')}</h4>
						{all_values_tables['pressure'][0]}
					</div>
				</div>
			);
		}
		if(AVAILABLE_CHANNELS[device_obj.dev_type].includes('pressure_1') && AVAILABLE_CHANNELS[device_obj.dev_type].includes('temperature_1')) {
			chart_items.push(
				<div className="sensor-container" key='container-3'>
					<h3>{t('SDP-810 Drucksensor 1')}</h3>
					<div className="sensor-chart-container">
						{all_charts['pressure_1'][0]}
						{all_charts['temperature_1'][0]}
					</div>
				</div>
			);
			table_items.push(
				<div className='outer-wrapper' key='table-3'>
					<div className="inner-wrapper">
						<h4>{t('SDP-810 Drucksensor 1')}</h4>
						{all_values_tables['pressure_1'][0]}
						{all_values_tables['temperature_1'][0]}
					</div>
				</div>
			);
		}
		if(AVAILABLE_CHANNELS[device_obj.dev_type].includes('pressure_2') && AVAILABLE_CHANNELS[device_obj.dev_type].includes('temperature_2')) {
			chart_items.push(
				<div className="sensor-container" key='container-4'>
					<h3>{t('SDP-810 Drucksensor 2')}</h3>
					<div className="sensor-chart-container">
						{all_charts['pressure_2'][0]}
						{all_charts['temperature_2'][0]}
					</div>
				</div>
			);
			table_items.push(
				<div className='outer-wrapper' key='table-4'>
					<div className="inner-wrapper">
						<h4>{t('SDP-810 Drucksensor 2')}</h4>
						{all_values_tables['pressure_2'][0]}
						{all_values_tables['temperature_2'][0]}
					</div>
				</div>
			);
		}
		if(AVAILABLE_CHANNELS[device_obj.dev_type].includes('object_temperature')) {
			chart_items.push(
				<div className="sensor-container" key='container-5'>
					<h3>{t('Thermocouple K')}</h3>
					<div className="sensor-chart-container">
						{all_charts['object_temperature'][0]}
					</div>
				</div>
			);
			table_items.push(
				<div className='outer-wrapper' key='table-5'>
					<div className="inner-wrapper">
						<h4>{t('Thermocouple K')}</h4>
						{all_values_tables['object_temperature'][0]}
					</div>
				</div>
			);
		}
		if(AVAILABLE_CHANNELS[device_obj.dev_type].includes('dew_point') && AVAILABLE_CHANNELS[device_obj.dev_type].includes('dew_point_delta')) {
			chart_items.push(
				<div className="sensor-container" key='container-6'>
					<h3>{t('Taupunkt')}</h3>
					<div className="sensor-chart-container">
						{all_charts['dew_point'][0]}
						{all_charts['dew_point_delta'][0]}
					</div>
				</div>
			);
			table_items.push(
				<div className='outer-wrapper' key='table-6'>
					<div className="inner-wrapper">
						<h4>{t('Taupunkt')}</h4>
						{all_values_tables['dew_point'][0]}
						{all_values_tables['dew_point_delta'][0]}
					</div>
				</div>
			);
		}
	}
	return (
		<DeviceContainer with_link={false} device_obj={device_obj}  table_items={table_items} chart_items={chart_items} triggered_alarm_count={triggered_alarm_count} triggered_alarm_names={triggered_alarm_names} />
	);
}

export default Device;
