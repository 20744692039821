import React, { useRef, useState, useCallback } from 'react';
import Form from 'react-bootstrap/Form'
import {useSelector, useDispatch} from 'react-redux'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation} from 'react-i18next';
import {useFormIDError} from './formHooks'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'

import {
	set_header_form_alert,
	set_authentication,
	async_do_simple_form_post
} from '../../actions'

import InputFloatField from './fields/InputFloatField'

/*
	User Profile settings, only edited through the
	user itself.
*/

const UserProfileForm = ({ auth_obj }) => {
	//console.log(config_obj)
	const {t} = useTranslation();

	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [settings_validated, set_settings_validated] = useState(false);
	const [passwd_validated, set_passwd_validated] = useState(false);
	
	const [, set_form_errors, get_error_state] = useFormIDError({})
	const setting_form_error_id = 'settings_form'
	const password_form_error_id = 'password_form'
	
	const form_settings_ref = useRef(null);
	const form_passwd_ref = useRef(null);
	
	let default_value_props = {
		email: { default_value: auth_obj.email },
		first_name: { default_value: auth_obj.first_name },
		last_name: { default_value: auth_obj.last_name },
	}
	
	const update_settings = useCallback(() => {
		const form = form_settings_ref.current;
		if(form.checkValidity()) {
			let data = {
				"operation": "update_settings",
				"user_id": auth_obj.id,
			}
			
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			
			const url = urls['core-api:user-profile']();
			
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
				set_form_errors({[setting_form_error_id]: {}})
				const {status, data} = payload.value
				if(status === 200 && !data.error) {
					dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
					console.log('data.auth: ',data.auth)
					dispatch(set_authentication(data.auth))
				} else {
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
					// set errors on a specific form with a specific error identifier
					set_form_errors({[setting_form_error_id]: data.errors})
				}
			})
		}
		set_settings_validated(true);
	}, [auth_obj.id, dispatch, t, urls, set_form_errors])
	
	const update_password = useCallback(() => {
		const form = form_passwd_ref.current;
		if(form.checkValidity()) {
			let data = {
				"operation": "update_password",
				"user_id": auth_obj.id,
			}
			
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			
			const url = urls['core-api:user-profile']();
			
			dispatch(async_do_simple_form_post(data, url)).then(payload => {
				set_form_errors({[password_form_error_id]: {}})
				const {status, data} = payload.value
				if(status === 200 && !data.error) {
					dispatch(set_header_form_alert(true, "success", t('Das Passwort wurde aktualisiert.')))
				} else {
					dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
					// set errors on a specific form with a specific error identifier
					set_form_errors({[password_form_error_id]: data.errors})
				}
			})
		}
		set_passwd_validated(true);
	}, [auth_obj.id, dispatch, t, urls, set_form_errors])
	
	let group_info_jsx = []
	if(auth_obj.is_superuser) {
		group_info_jsx.push(
			<div key="user-is-superuser">{t('Dieser Benutzer ist Administrator und keiner speziellen Gruppe zugeordnet.')}</div>
		)
	} else{
		if(auth_obj.group_names.length) {
			group_info_jsx.push(
				<div key="stringified-group-names">{auth_obj.group_names.join(', ')}</div>
			)
		} else {
			group_info_jsx.push(
				<div key="user-is-not-in-any-groups">{t('Der Benutzer ist nicht Mitglied von Gruppen.')}</div>
			)
		}
	}
	
	return (
		<>
			<Card className='default-settings-card'>
				<Card.Header className='default-title'>
					{t('Generelle Einstellungen')}
				</Card.Header>
				<Card.Body>
					<Form ref={form_settings_ref} validated={settings_validated}>
						<Container fluid>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('E-Mail')} 
										field_name="email" 
										field_type="email"
										key={`email`} 
										errors={get_error_state(setting_form_error_id, "email")}
										{...default_value_props.email} />
								</Col>
							</Row>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Vorname')} 
										field_name="first_name" 
										field_type="text"
										key={`first_name`} 
										errors={get_error_state(setting_form_error_id, "first_name")}
										{...default_value_props.first_name} />
								</Col>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Nachname')} 
										field_name="last_name" 
										field_type="text"
										key={`last_name`} 
										errors={get_error_state(setting_form_error_id, "last_name")}
										{...default_value_props.last_name} />
								</Col>
							</Row>
							<ButtonToolbar>
								<ButtonGroup className="me-2">
									<Button className="primary-light-bg" onClick={update_settings}>{t('Speichern')}</Button>
								</ButtonGroup>
							</ButtonToolbar>
						</Container>
					</Form>
				</Card.Body>
			</Card>
			<Card className='default-settings-card'>
				<Card.Header className='default-title'>
					{t('Passwort ändern')}
				</Card.Header>
				<Card.Body>
					<Form ref={form_passwd_ref} validated={passwd_validated}>
						<Container fluid>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Passwort')} 
										field_name="password" 
										field_type="password"
										key={`password`} 
										errors={get_error_state(password_form_error_id, "password")} />
								</Col>
							</Row>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Passwort wiederholen')}  
										field_name="password_repeat" 
										field_type="password"
										key={`password_repeat`}
										errors={get_error_state(password_form_error_id, "password_repeat")} />
								</Col>
							</Row>
							<ButtonToolbar>
								<ButtonGroup className="me-2">
									<Button className="primary-light-bg" onClick={update_password}>{t('Speichern')}</Button>
								</ButtonGroup>
							</ButtonToolbar>
						</Container>
					</Form>
				</Card.Body>
			</Card>
			<Card className='default-settings-card'>
				<Card.Header className='default-title'>
					{t('Gruppen Informationen')}
				</Card.Header>
				<Card.Body>
					{group_info_jsx}
				</Card.Body>
			</Card>
		</>
	)
}

export default UserProfileForm;