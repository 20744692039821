import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation} from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import InputFloatField from './fields/InputFloatField'

import GroupPermissionForm from './GroupPermissionForm'

const UserForm = ({ group_obj, 
					index,
					get_error_state,
					delete_group,
					update_group,
					create_group,
					update_group_permissions,
					remove_group_form,
					is_initial_open }) => {
	//console.log(config_obj)
	const {t} = useTranslation();
	
	const [validated, set_validated] = useState(false);
	
	const [popover_show, set_popover_show] = useState(false)
	
	// for elements to create id is not given, but the index (position in array) -> needed for delete
	const id = group_obj.id !== undefined ? group_obj.id : index
	
	const is_new_group = group_obj.type !== undefined ? true : false
	
	// identify the form to mark with errors (one each time only)
	const form_error_id = group_obj.id !== undefined ? `update-group-${group_obj.id}` : `create-group-${index}`
	
	const form_ref = useRef(null);
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const popover = (
		<Popover key={`group-remove-popover-${id}`} id="popover-basic">
		    <Popover.Header as="h3">{t('Gruppe löschen')}</Popover.Header>
		    <Popover.Body>
		    	<p>{t('Soll diese Gruppe wirklich gelöscht werrden?')}</p>
		      	<ButtonToolbar>
		      		<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {delete_group(id)}}>{t('OK')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
		    </Popover.Body>
		</Popover>
	)
	
	
	let default_value_props = {
		name: { default_value: group_obj.name },
	}
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const update_group_click = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {
				group_id: id
			}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			// add the choosen configurations
			update_group(data, form_error_id)
		}
		set_validated(true);
	}
	
	const create_group_click = (event) => {
		const form = form_ref.current;
		if(form.checkValidity()) {
			const fdata = new FormData(form);
			let data = {}
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			// add the choosen configurations
			create_group(data, form_error_id, id)
		}
		set_validated(true);
	}
	
	let delete_class_prop = {
		className: 'primary-light-bg'
	}
	let delete_button_text = t('Gruppen Formular Entfernen')
	let delete_button_jsx = [
		<Button key={`config-remove-button-${id}`} variant="sm" {...delete_class_prop} onClick={event => {remove_group_form(id)}}>{delete_button_text}</Button>
	]
	let save_button_onclick_prop = {
		onClick: create_group_click
	}
	
	let accordion_title_str = t('Neue Gruppe')
	let group_management_jsx = [
		<div key={`no-password-form-${id}`}>{t('Die Gruppe muss zuerst gespeichert werden, bevor die Berechtigungen verändert werden können.')}</div>
	]
	
	// open the accordion by default
	let accordion_default_key_prop = {
		defaultActiveKey: "-1"
	} // nothing open
	if(is_initial_open) {
		accordion_default_key_prop = {
			defaultActiveKey: "0"
		} // open
	}
	
	// do we show the password dialog and some button names
	if(!is_new_group) {
		
		delete_button_text = t('Gruppe löschen')
		delete_class_prop.className = 'danger-light-bg'
		
		save_button_onclick_prop.onClick = update_group_click
		
		delete_button_jsx = [
			<OverlayTrigger key="group-delete-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
				<Button key={`group-delete-button-${id}`} variant="sm" {...delete_class_prop}>{delete_button_text}</Button>
			</OverlayTrigger>
		]
		accordion_title_str = group_obj.name
		group_management_jsx = [
			<GroupPermissionForm group_obj={group_obj} key={`permission-management-${id}`} update_group_permissions={update_group_permissions} />
		]
	} 
	
	return (
		<Accordion key={`group-settings-container-${group_obj.id}`} className="default-setttings-container" {...accordion_default_key_prop}>
			<Accordion.Item eventKey="0">
				<Accordion.Header>
					{accordion_title_str}
				</Accordion.Header>
				<Accordion.Body>
					<Card className='default-settings-card no-padding'>
						<Card.Header className='default-title'>
							{t('Generelle Einstellungen')}
						</Card.Header>
						<Card.Body>
							<Form ref={form_ref} validated={validated}>
								<Container fluid>
									<Row>
										<Col lg={true}>
											<InputFloatField 
												is_required={true}
												label={t('Name')} 
												field_name="name" 
												field_type="text"
												key={`name`} 
												errors={get_error_state(form_error_id, "name")}
												{...default_value_props.name} />
										</Col>
									</Row>
									<ButtonToolbar>
										<ButtonGroup className="me-2">
											<Button variant="sm" className="primary-light-bg" {...save_button_onclick_prop}>{t('Speichern')}</Button>
										</ButtonGroup>
									</ButtonToolbar>
								</Container>
							</Form>
						</Card.Body>
					</Card>
					{group_management_jsx}
					<ButtonToolbar>
						<ButtonGroup className="me-2 mt-3">
							{delete_button_jsx}
						</ButtonGroup>
					</ButtonToolbar>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

export default UserForm;
