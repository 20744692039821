import React, {useCallback} from 'react';
import { useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux'
import update from 'immutability-helper'

import Button from 'react-bootstrap/Button'
import {useFormIDError} from '../formHooks'

import AlarmNotificationForm from '../../forms/AlarmNotificationForm'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../../actions'

const AlarmNotificationWidget = ({ settings_data, set_settings_data }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [, set_form_errors, get_error_state] = useFormIDError({})
	
	const delete_notification = useCallback((id, config_type) => {
		let data = {
			"operation": "delete_notification",
			"id": id
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				const new_settings_data = update(settings_data, {
					notifications: {
						$set: data.notifications
					}
				})
				set_settings_data(new_settings_data) 
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [settings_data, set_settings_data, dispatch, t, urls])
	
	const create_notification = useCallback((form_data, form_error_id, index) => {
		let data = {
			"operation": "create_notification",
			...form_data
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				const new_notifications_to_create = Array.from(settings_data.notifications_to_create)
				new_notifications_to_create.splice(index, 1)
				const new_settings_data = update(settings_data, {
					notifications: {
						$set: data.notifications
					},
					notifications_to_create: {
						$set: new_notifications_to_create
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [settings_data, set_settings_data, dispatch, t, urls, set_form_errors])
	
	const update_notification = useCallback((form_data, form_error_id, index) => {
		let data = {
			"operation": "update_notification",
			...form_data
		}
		const url = urls['iot-api:alarm-notifications-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				const new_settings_data = update(settings_data, {
					notifications: {
						$set: data.notifications
					}
				})
				set_settings_data(new_settings_data) 
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [settings_data, set_settings_data, dispatch, t, urls, set_form_errors])
	
	const add_notfication_click = (event) => {
		let notifications_to_create = Array.from(settings_data.notifications_to_create)
		notifications_to_create.push({
			name: '',
			configurations: [], // nothing assigned
			na_configurations: [...settings_data.configurations.email, ...settings_data.configurations.phone], // merge all
			type: 'create'
		})
		
		const new_settings_data = update(settings_data, {
			notifications_to_create: {
				$set: notifications_to_create
			}
		}) 
		
		set_settings_data(new_settings_data)
	}
	
	const remove_notification_form = (index) => {
		const new_notifications_to_create = Array.from(settings_data.notifications_to_create)
		new_notifications_to_create.splice(index, 1)
		const new_settings_data = update(settings_data, {
			notifications_to_create: {
				$set: new_notifications_to_create
			}
		})
		set_settings_data(new_settings_data) 
	}
	
	let notifications_jsx = []
	if(settings_data.notifications.length || settings_data.notifications_to_create.length) {
		for(const notification_obj of settings_data.notifications) {
			notifications_jsx.push(
				<AlarmNotificationForm key={`notification-config-update-${notification_obj.id}`}
									   notification_obj={notification_obj}
									   get_error_state={get_error_state}
									   delete_notification={delete_notification}
									   create_notification={undefined}
									   update_notification={update_notification}
									   remove_notification_form={undefined}
									   index={undefined} />
			)
		}
		let create_counter = 0
		for(const notification_obj of settings_data.notifications_to_create) {
			notification_obj.index = create_counter
			notifications_jsx.push(
				<AlarmNotificationForm key={`notification-config-create${create_counter}`}
									   notification_obj={notification_obj} 
									   get_error_state={get_error_state}
									   delete_notification={undefined}
									   create_notification={create_notification}
									   update_notification={undefined}
									   remove_notification_form={remove_notification_form}
									   index={create_counter} />
			)
			create_counter += 1
		}
	} else {
		notifications_jsx.push(
			<div key="no-notifications" className="default-settings-card">{t('Keine Benachrichtigunskonfigurationen vorhanden')}</div>
		)
	}
	
	return (
		<>
			{notifications_jsx}
			<Button variant="sm" className="primary-light-bg" onClick={add_notfication_click} >{t('Benachrichtigungskonfiguration hinzufügen')}</Button>
		</>
	)
}

export default AlarmNotificationWidget;
