import React, { useState, useEffect, useCallback } from 'react';
import update from 'immutability-helper'
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';
import {useFormIDError} from './formHooks'

import DeviceChannelAlarmWidget from './widgets/DeviceChannelAlarmWidget'

import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'

const DeviceChannelAlarmForm = ({ device_obj, event_key, current_active_key }) => {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const [is_initialized, set_is_initialized] = useState(false)
	const [, set_form_errors, get_error_state] = useFormIDError({})
	
	const [channel_alarm_data, set_channel_alarm_data] = useState({
		channels: {}
	})
	
	const update_channel_alarm_obj_state = (new_channel_alarm_obj, channel_id) => {
		// update, some new forms are created in child
		const new_channels = update(channel_alarm_data.channels, {
			[channel_id]: {
				$set: new_channel_alarm_obj
			}
		}) 
		set_channel_alarm_data(Object.assign({}, channel_alarm_data, {
			channels: new_channels
		}))
	}
	
	const delete_alarm_configuration = useCallback((id) => {
		let data = {
			"operation": "delete_alarm_configuration",
			"device_eui": device_obj.dev_eui,
			"id": id
		}
		const url = urls['iot-api:channel-alarm-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				set_channel_alarm_data({channels: data.channels, threshold_type_choices: data.threshold_type_choices})
				// a bit overkill
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [device_obj.dev_eui, dispatch, t, urls])
	
	const create_alarm_configuration = useCallback((form_data, form_error_id) => {
		let data = {
			"operation": "create_alarm_configuration",
			"device_eui": device_obj.dev_eui,
			...form_data
		}
		const url = urls['iot-api:channel-alarm-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				set_channel_alarm_data({channels: data.channels, threshold_type_choices: data.threshold_type_choices})
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [device_obj.dev_eui, dispatch, t, urls, set_form_errors])
	
	const update_alarm_configuration = useCallback((form_data, form_error_id) => {
		let data = {
			"operation": "update_alarm_configuration",
			"device_eui": device_obj.dev_eui,
			...form_data
		}
		const url = urls['iot-api:channel-alarm-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200 && !data.error) {
				set_form_errors({})
				set_channel_alarm_data({channels: data.channels, threshold_type_choices: data.threshold_type_choices})
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
				// set errors on a specific form with a specific error identifier
				set_form_errors({[form_error_id]: data.errors})
			}
		})
	}, [device_obj.dev_eui, dispatch, t, urls, set_form_errors])
	
	const initial_configuration_load = useCallback(() => {
		let data = {
			"operation": "get_channel_alarm_configuration",
			"device_eui": device_obj.dev_eui
		}
		const url = urls['iot-api:channel-alarm-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200) {
				set_channel_alarm_data({channels: data.channels, threshold_type_choices: data.threshold_type_choices})
				set_is_initialized(true)
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [device_obj.dev_eui, dispatch, t, urls])
	
	useEffect(() => {
		if(current_active_key === event_key && !is_initialized) { // load data for configuration
			initial_configuration_load();
		}
	}, [is_initialized, initial_configuration_load, current_active_key, event_key]);
	
	let channel_config_widgets_jsx = []
	for(const key in channel_alarm_data.channels) {
		channel_config_widgets_jsx.push(
			<DeviceChannelAlarmWidget key={key} 
									  get_error_state={get_error_state}
									  channel_alarm_obj={channel_alarm_data.channels[key]} 
									  threshold_type_choices={channel_alarm_data.threshold_type_choices} 
									  update_channel_alarm_obj_state={update_channel_alarm_obj_state} 
									  delete_alarm_configuration={delete_alarm_configuration}
									  create_alarm_configuration={create_alarm_configuration}
									  update_alarm_configuration={update_alarm_configuration} />
		)
	}
	
	return (
		<Accordion.Item eventKey={event_key}>
			<Accordion.Header>{t('Kanal Alarm Einstellungen')}</Accordion.Header>
			<Accordion.Body>
				{channel_config_widgets_jsx}
			</Accordion.Body>
		</Accordion.Item>
	)
}

export default DeviceChannelAlarmForm;