import React from 'react';
import Badge from 'react-bootstrap/Badge'
import {Lock, Unlock} from 'react-bootstrap-icons'

const ProjectStatusIndicator = ({ project_status }) => {
		
	let logo = <Lock />;
	let bg_color = 'danger-light-bg'
	if(project_status === 'open') {
		logo = <Unlock />
		bg_color = 'success-light-bg'
	}
		
	return (
		<Badge bg="" className={bg_color}>
			{logo}
		</Badge>
	);
}

export default ProjectStatusIndicator;