import React from 'react';
import Navigation from './components/Navigation';
import Dashboard from './components/Dashboard';
import DeviceDashboard from './components/device/DeviceDashboard'
import ProjectDashboard from './components/project/ProjectDashboard';
import DeviceDetailPage from './components/pages/DeviceDetailPage';
import ProjectDetailPage from './components/pages/ProjectDetailPage';
import ProjectAddDetailPage from './components/pages/ProjectAddDetailPage'
import AlarmNotificationsSettingsDetailPage from './components/pages/AlarmNotificationsSettingsDetailPage'
import UsersGroupsSettingsDetailPage from './components/pages/UsersGroupsSettingsDetailPage'
import UserProfileSettingsDetailPage from './components/pages/UserProfileSettingsDetailPage'
import DashboardSettingsDetailPage from './components/pages/DashboardSettingsDetailPage'

import BreadcrumbTrail from './components/BreadcrumbTrail';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainContainer from './components/MainContainer';
import WS from './components/Websocket';
import { useTranslation} from 'react-i18next';
import HeaderFormAlert from './components/HeaderFormAlert'
import HeaderServerComponentAlert from './components/HeaderServerComponentAlert'

const App = (props) => {
	const {t} = useTranslation();
	return(
		<div>
			<Router>
				<div className="app-container">
					<HeaderFormAlert />
					<HeaderServerComponentAlert />
					<header className="app-header">
		        		<Navigation />
					</header>
					<Switch>
						<Route exact path="/devices" render={routeProps => (
       						<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Geräte Übersicht'), path: undefined}]} />
								<DeviceDashboard />
       						</MainContainer>
     						)} />
						<Route exact path="/devices/:dev_eui" render={routeProps => (
       						<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Geräte'), path: '/devices'}, 
															 {label: routeProps.match.params.dev_eui, path: undefined}]} />
								<DeviceDetailPage />
       						</MainContainer>
     						)} />
						<Route exact path="/projects">
							<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Projekt Übersicht'), path: undefined}]} />
								<ProjectDashboard />
       						</MainContainer>
						</Route>
						<Route exact path="/projects/add">
							<MainContainer>
         						<BreadcrumbTrail items={[{label: t('Projekt Hinzufügen'), path: undefined}]} />
								<ProjectAddDetailPage />
       						</MainContainer>
						</Route>
						<Route exact path="/projects/:project_slug" render={routeProps => (
       						<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Projekte'), path: '/projects'}, 
															 {label: routeProps.match.params.project_slug, path: undefined}]} />
								<ProjectDetailPage />
       						</MainContainer>
     						)} />
     					<Route exact path="/dashboard/settings">
							<MainContainer>
         						<BreadcrumbTrail items={[{label: t('Dashboard konfigurieren'), path: undefined}]} />
								<DashboardSettingsDetailPage />
       						</MainContainer>
						</Route>
						<Route exact path="/alarm/notifications">
							<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Alarmierungs Benachrichtigungen'), path: undefined}]} />
									<AlarmNotificationsSettingsDetailPage />
       						</MainContainer>
						</Route>
						<Route exact path="/users-and-groups">
							<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Benutzer und Gruppen'), path: undefined}]} />
									<UsersGroupsSettingsDetailPage />
       						</MainContainer>
						</Route>
						<Route exact path="/user/profile">
							<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Benutzerprofil'), path: undefined}]} />
									<UserProfileSettingsDetailPage />
       						</MainContainer>
						</Route>
						<Route exact path="/" render={routeProps => (
       						<MainContainer>
         							<BreadcrumbTrail items={[{label: t('Dashboard'), path: undefined}]} />
								<Dashboard />
       						</MainContainer>
     						)} />
					</Switch>
				</div>
			</Router>
			<WS />
		</div>
	)
}

export default App;
