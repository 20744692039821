import React from 'react';

import Badge from 'react-bootstrap/Badge'
import {Battery, BatteryFull, BatteryHalf} from 'react-bootstrap-icons'

const BatteryIndicator = ({ battery_level }) => {
	const warn_level = 3.8;
	const low_level = 3.5;
		
	let level_class = 'success-light-bg';
	let bt = parseFloat(battery_level);
	
	let badge_jsx = [
		<span key="batt-full">
		<BatteryFull /> {bt.toFixed(2)} V
		</span>
	]
	if(bt <= warn_level && bt > low_level) {
		level_class = 'warning-light-bg';
		badge_jsx = [
			<span key="batt-half">
			<BatteryHalf /> {bt.toFixed(2)} V
			</span>
		]
	} else if(bt <= low_level) {
		level_class = 'danger-light-bg';
		badge_jsx = [
			<span key="batt-low">
			<Battery /> {bt.toFixed(2)} V
			</span>
		]
	}
			

	return (
		<Badge bg="" className={level_class}>
		{badge_jsx}
		</Badge>
	);
}

export default BatteryIndicator;