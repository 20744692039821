// get some value from a cookie
export const get_cookie_value = (name) => {
   	let cookie_value = null;
   	if (document.cookie && document.cookie !== '') {
       	var cookies = document.cookie.split(';');
       	for (var i = 0; i < cookies.length; i++) {
           	var cookie = cookies[i].trim();
           	if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookie_value = decodeURIComponent(cookie.substring(name.length + 1));
   	            break;
       	    }
       	}
   	}
   	return cookie_value;
}

export const prepare_headers = () => {
	const csrftoken = get_cookie_value('csrftoken')
	const request_options = {
       	headers: { 'X-CSRFToken': csrftoken }
   	}
	return request_options
}
