import React from 'react';
import Badge from 'react-bootstrap/Badge'
import {GraphUp} from 'react-bootstrap-icons'

const DataPointsIndicator = ({ total_datapoints }) => {
	return (
		<Badge bg='' className="no-color-bg">
		<GraphUp /> {total_datapoints}
		</Badge>
	);
}

export default DataPointsIndicator;