import React from 'react';
import { useTranslation} from 'react-i18next';

import Card from 'react-bootstrap/Card'

import {usePermission} from '../../hooks'

import ProjectAddForm from '../forms/ProjectAddForm'

import Alert from 'react-bootstrap/Alert'

let ProjectAddDetailPage = ({ children }) => {
	const {t} = useTranslation();
	
	const has_perm = usePermission()
	
	if(has_perm('iot.add_project')) {
		return (
			<Card className="project-container">
				<Card.Header as="h5">
					{t('Projekt hinzufügen')}
				</Card.Header>
				<Card.Body>
					<p>{t('Basisinformationen des Projekts angeben.')}</p>
					<ProjectAddForm />
				</Card.Body>
			</Card>
		)
	} else {
		return (
			<Alert variant="danger" key="project-add-insufficient-permission">{t('Dieser Benutzer darf keine Projekte hinzufügen.')}</Alert>
		)
	}
}

export default ProjectAddDetailPage;