import { useCallback } from 'react';
import {useSelector} from 'react-redux'

// normal global permissions
export const usePermission = () => {
	const is_superuser = useSelector(state => state.auth.is_superuser)
	const all_permissions = useSelector(state => state.auth.all_permissions)
	
	
	const has_perm = useCallback((code_name) => {
		if(is_superuser) { // has anyway all permissions
			return true
		}
		if(Array.isArray(code_name)) {
			for(const cn of code_name) {
				if(!all_permissions.includes(cn)) { // if only one is not, return false
					return false
				}
			}
			return true
		} else {
			if(all_permissions.includes(code_name)) {
				return true
			} else {
				return false
			}
		}
	}, [is_superuser, all_permissions])
	
	return has_perm;
}
// django guardian object permissions
export const useObjectPermission = () => {
	const is_superuser = useSelector(state => state.auth.is_superuser)
	const all_user_group_object_permissions = useSelector(state => state.auth.all_user_group_object_permissions)
	
	const has_object_perm = useCallback((code_name, object_type, object_pk) => {
		if(is_superuser) {
			return true
		}
		
		const splitted_codename = code_name.split('.')
		const app_label = splitted_codename[0]
		const codename = splitted_codename[1]
		
		if (all_user_group_object_permissions.some(e =>
			parseInt(e.object_pk) === parseInt(object_pk) &&
			e.permission.content_type.model === object_type &&
			e.permission.content_type.app_label === app_label &&
			e.permission.codename === codename
		)) {
  			return true
		} else {
			return false
		}
	}, [is_superuser, all_user_group_object_permissions])
	
	return has_object_perm;
}