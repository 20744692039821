import React from 'react';
import { 
	AVAILABLE_CHANNELS
} from '../../Config';
import {useSelector} from 'react-redux'
import DashboardDevice from './DashboardDevice';
import { useTranslation} from 'react-i18next';
import Alert from 'react-bootstrap/Alert'

let DeviceDashboard = () => {
	const {t} = useTranslation();
	
	const devices = useSelector(state => state.devices)
	
	let all_dashboard_devices = [];
		
	
	for(let key of Object.keys(devices)) {
		const device_obj = devices[key]['device'];
		const chart_channels = AVAILABLE_CHANNELS[device_obj.dev_type];
		const triggered_alarm_count = devices[key]['triggered_alarms_count'];
		const triggered_alarm_names = devices[key]['triggered_alarm_names'];
		const channels = devices[key]['channels'];
		all_dashboard_devices.push(<DashboardDevice key={device_obj.dev_eui} 
													device_obj={device_obj} 
													channels={channels}
													chart_channels={chart_channels} 
													triggered_alarm_count={triggered_alarm_count}
													triggered_alarm_names={triggered_alarm_names}
													show_charts={true} />)
	}
	
	if(all_dashboard_devices.length) {
		return (
			<div className="device-overview-grid">
				{all_dashboard_devices}
			</div>
		)
	} else {
		return (
			<Alert variant="danger" key="no-devices-or-no-permission">{t('Es sind keine Geräte vorhanden, oder es fehlen die Berechtigungen diese anzuzeigen.')}</Alert>
		)
	}
}

export default DeviceDashboard;
