import React from 'react';
import { useTranslation} from 'react-i18next';
import Table from 'react-bootstrap/Table';
import {local_time_from_timestamp} from '../utils/time'

const LastValuesTable = ({ values,
						   channel_id, 
						   channel_name, 
						   channel_unit }) => {
	const {t} = useTranslation();
	
	let tbody = []
	let ctr = 0
	for(let value of values.reverse()) {
		ctr += 1
		tbody.push(
			<tr key={`${channel_id}-${ctr}`}>
				<td>{local_time_from_timestamp(value.x)}</td>
				<td>{`${value.y} ${channel_unit}`}</td>
			</tr>
		)
	}
	
	return (
		<Table striped bordered hover>
			<thead>
				<tr>
					<th>{t('Datum')}</th>
					<th>{channel_name}</th>
				</tr>
			</thead>
			<tbody>
				{tbody}
			</tbody>
		</Table>
	)
};

export default LastValuesTable;