import React, {forwardRef} from 'react';

const DashboardDeviceChannelWidget = forwardRef(({ id, title, provided }, ref) => {
	
	return (
		<div ref={ref} key={`channel-id-${id}`} {...provided.draggableProps} {...provided.dragHandleProps}>
			{title}
		</div>
	);
})

export default DashboardDeviceChannelWidget;