import React, { useState, useEffect } from 'react';
import update from 'immutability-helper'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Card from 'react-bootstrap/Card'
import { useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Alert from 'react-bootstrap/Alert'
import {ItemTypes} from '../../utils/drag_types'
import PermissionDnDContainer from './components/PermissionDnDContainer'

const GroupPermissionForm = ({ group_obj,
						 update_group_permissions }) => {
	
	const {t} = useTranslation();
	
	const [containers, set_containers] = useState([])
	
	useEffect(() => {
		set_containers([
			{ title: t('Gruppen Berechtigungen'), permissions: group_obj.permissions }, 
		   	{ title: t('Nicht zugeordnet'), permissions: group_obj.na_permissions },
		])
	}, [group_obj, t])
	
	const move_permission = (source, destination, droppable_source, droppable_dest) => {
		const source_permissions = Array.from(source.permissions);
	  	const dest_permissions = Array.from(destination.permissions);
	  	
	  	const [removed] = source_permissions.splice(droppable_source.index, 1);
	
		dest_permissions.splice(droppable_dest.index, 0, removed);
	
		const result = {};
		result[droppable_source.droppableId] = source_permissions;
		result[droppable_dest.droppableId] = dest_permissions;
	
		return result;
	}
	
	const drag_end = (result) => {
		console.log('drag_end result: ', result)
		
		const { source, destination, type } = result;
		
		// dropped outside the list
		if (!destination) {
			return
		}
		
		
		if(type === ItemTypes.PERMISSION) {
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
		
			if (source_index === dest_index) {
				return
			}
				
			const result = move_permission(containers[source_index], containers[dest_index], source, destination);
			const new_containers = update(containers, {
				[source_index]: {
					permissions: {
						$set: result[source_index]
					}
				},
				[dest_index]: {
					permissions: {
						$set: result[dest_index]
					}
				}
			});
			set_containers(new_containers);
		}
	}
	
	const update_group_permissions_click = (event) => {
		let data = {
			group_id: group_obj.id
		}
		// add the choosen configurations
		data['permissions'] = containers[0].permissions
		update_group_permissions(data)
	}
	
	return (
		<Card className='default-settings-card no-padding'>
			<Card.Header className='default-title'>
				{t('Standardberechtigungen')}
			</Card.Header>
			<Card.Body>
				<Alert variant="warning">
					{t('Diese Berechtigungen werden auf alle Objekte angewandt. Wird z.B. der Gruppe "Kann Geräte Daten exportieren" zugewiesen, kann die Gruppe alle Daten von allen Geräten exportieren.')}
					<br />
					{t('Berechtigungen die sich auf einzelne Objekte beziehen sollen, werden beim Objekt selbst vergeben. Soll eine Gruppe nur auf ein bestimmtes Gerät zugreifen, sollten hier keine Berechtigungen vergeben werden die sich auf Geräte beziehen.')}
					<br />
					{t('Alle Berechtigungen beziehen sich immer auf Gruppen.')}
				</Alert>
				<DragDropContext onDragEnd={drag_end}>
					<div className="dnd-container-wrapper">
						{containers.map(({ title, permissions }, index) => (
							<Droppable key={index} droppableId={`${index}`} type={ItemTypes.PERMISSION}>
							{(provided, snapshot) => (
								<PermissionDnDContainer
									ref={provided.innerRef}
									title={title}
									permissions={permissions}
									key={index}
									provided={provided}
									with_search={index === 1 ? true : false}
								/>
								
							)}
							</Droppable>
				        ))}
					</div>
				</DragDropContext>
				<ButtonGroup className="mt-3">
					<Button variant="sm" className="primary-light-bg" onClick={update_group_permissions_click}>{t('Speichern')}</Button>
				</ButtonGroup>
			</Card.Body>
		</Card>
	)
}

export default GroupPermissionForm;

