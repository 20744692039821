import React, { useRef, useCallback } from 'react';
import { send } from '@giantmachines/redux-websocket';
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import Form from 'react-bootstrap/Form'
import SelectFloatField from './fields/SelectFloatField'

const DashboardSettingsForm = ({event_key, item_order, item_order_choices, user_id, set_dashboard_data}) => {
	const {t} = useTranslation();
	
	const form_ref = useRef(null); 
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const handle_save_click = useCallback(event => {
		const form = form_ref.current;
		
		let data = {
			"user_id": user_id,
			"operation": "update_dashboard_settings"
		}
		const fdata = new FormData(form);
		for (let [key, value] of fdata) {
			data[key] = value;
		}
		const url = urls['iot-api:dashboard-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status, data} = payload.value
			if(status === 200  && !data.error) {
				if(event !== undefined) {
					dispatch(set_header_form_alert(true, "success", t('Die Konfiguration wurde gespeichert.')))
				}
				set_dashboard_data(data)
				dispatch(send({'type': 'dashboard.update'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls, user_id, set_dashboard_data])
	
	return (
		<>
			<Form ref={form_ref}>
				<SelectFloatField
					key="item-order" 
					choices={item_order_choices} 
					select_choice={item_order} 
					field_name="item_order"
					floating_label={t('Anzeige Element Reihenfolge')} />
			</Form>
			<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
		</>
	)
}

export default DashboardSettingsForm;