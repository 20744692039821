import React from 'react';
import {useDispatch} from 'react-redux'
import {Bell} from 'react-bootstrap-icons'
import Button from 'react-bootstrap/Button'
import { send } from '@giantmachines/redux-websocket';
import { useTranslation} from 'react-i18next';

import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const AlarmIndicator = ({ triggered_alarm_count, triggered_alarm_names, device_obj }) => {
	const dispatch = useDispatch()
	const {t} = useTranslation();

	let alarm_icon_jsx = null
	let result_jsx = null
	if(triggered_alarm_count) {
		
		alarm_icon_jsx = (
			<Button key= "alarm-button" size="sm" className="position-relative warning-light-bg">
				<Bell key="alarm-icon" />
				<span key="alarm-warn-icon" className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
					{triggered_alarm_count}<span className="visually-hidden">{t('Alarm')}</span>
				</span>
			</Button>
		)
		
		if(device_obj !== null) {
			const reset_alarm_click = (event) => {
				dispatch(send({"type":"device.reset.alarms", 'json_content': {"dev_eui": device_obj.dev_eui}}));
			}
			
			let alarm_list = []
			let alarm_ctr = 0;
			for(let name of triggered_alarm_names) {
				alarm_list.push(<React.Fragment key={`alarm-name-${alarm_ctr}`}>{name}<br /></React.Fragment>)
				alarm_ctr += 1
			}
			
			const popover = (
				<Popover key="alarm-reset-popover" id="popover-basic">
	    			<Popover.Header key="alarm-reset-popover-header" as="h3">{t('Alarm zurücksetzen')}</Popover.Header>
	    			<Popover.Body key="alarm-reset-popover-body">
	    				<p><strong>{t('Aktive Alarme')}</strong></p>
	    				<p>{alarm_list}</p>
	      				<p key="alarm-reset-popover-info-text">{t('Wollen Sie alle aktiven Alarme zurücksetzen?')}</p>
						<Button key="alarm-reset-popover-button"size="sm" onClick={reset_alarm_click}>{t('OK')}</Button>
	    			</Popover.Body>
	  			</Popover>
			)
			result_jsx = [
				<OverlayTrigger key="alarm-reset-overlay-trigger" trigger="click" placement="bottom" overlay={popover} rootClose>
					{alarm_icon_jsx}
				</OverlayTrigger>
			]
		} else {
			result_jsx = alarm_icon_jsx;
		}
	}
	
	return (
		<>
		{result_jsx}
		</>
	);
}

export default AlarmIndicator;