import React, { useRef, useState, useEffect } from 'react';
import update from 'immutability-helper'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation} from 'react-i18next';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonGroup'
import {ItemTypes} from '../../utils/drag_types'
import AlarmNotificationConfigurationDnDContainer from './components/AlarmNotificationConfigurationDnDContainer'
import InputFloatField from './fields/InputFloatField'

const AlarmNotificationForm = ({ notification_obj, 
								 index, 
								 get_error_state, 
								 delete_notification,
								 create_notification,
								 update_notification,
								 remove_notification_form }) => {
	//console.log(notification_obj)
	const {t} = useTranslation();
	
	const [popover_show, set_popover_show] = useState(false)
	
	const [containers, set_containers] = useState([])
	
	const form_ref = useRef(null);
	
	// for elements to create id is not given, but the index (position in array) -> needed for delete
	const id = notification_obj.id !== undefined ? notification_obj.id : index
	// identify the form to mark with errors (one each time only)
	const form_error_id = notification_obj.id !== undefined ? `update-${notification_obj.id}` : `create-${index}`
	
	useEffect(() => {
		set_containers([
			{ title: t('Dieser Benachrichtigung zugeordnet'), configurations: notification_obj.configurations }, // only show channels dnd container for devices that are shown
		   	{ title: t('Nicht zugeordnet'), configurations: notification_obj.na_configurations },
		])
	}, [notification_obj, t])
	
	const close_popover_click = ref => {
		set_popover_show(false)
	}
	
	const on_toggle_click = event => {
		if(!popover_show) {
			set_popover_show(true)
		} else {
			set_popover_show(false);
		}
	}
	
	const create_notification_click = (event) => {
		const form = form_ref.current;
		const fdata = new FormData(form);
		let data = {}
		for (let [key, value] of fdata) {
			data[key] = value;
		}
		// add the choosen configurations
		data['configurations'] = containers[0].configurations
		create_notification(data, form_error_id)
	}
	
	const update_notification_click = (event) => {
		const form = form_ref.current;
		const fdata = new FormData(form);
		let data = {
			id: id
		}
		for (let [key, value] of fdata) {
			data[key] = value;
		}
		// add the choosen configurations
		data['configurations'] = containers[0].configurations
		update_notification(data, form_error_id)
	}
	
	let class_prop = {
		className: 'default-settings-card'
	}
	
	const move_project = (source, destination, droppable_source, droppable_dest) => {
		const source_configurations = Array.from(source.configurations);
	  	const dest_configurations = Array.from(destination.configurations);
	  	
	  	const [removed] = source_configurations.splice(droppable_source.index, 1);
	
		dest_configurations.splice(droppable_dest.index, 0, removed);
	
		const result = {};
		result[droppable_source.droppableId] = source_configurations;
		result[droppable_dest.droppableId] = dest_configurations;
	
		return result;
	}
	
	const drag_end = (result) => {
		const { source, destination, type } = result;
		
		// dropped outside the list
		if (!destination) {
			return
		}
		
		
		if(type === ItemTypes.NOTIFICATIONCONFIGURATION) {
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
		
			if (source_index === dest_index) {
				return
			}
				
			const result = move_project(containers[source_index], containers[dest_index], source, destination);
			const new_containers = update(containers, {
				[source_index]: {
					configurations: {
						$set: result[source_index]
					}
				},
				[dest_index]: {
					configurations: {
						$set: result[dest_index]
					}
				}
			});
			set_containers(new_containers);
		}
	}
	
	const popover = (
		<Popover key={`config-remove-popover-${id}`} id="popover-basic">
		    <Popover.Header as="h3">{t('E-Mail Konfiguration löschen')}</Popover.Header>
		    <Popover.Body>
		    	<p>{t('Beim bestätigen wird diese Benachrichtigungskonfiguration gelöscht.')}</p>
		      	<ButtonToolbar>
		      		<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="danger-light-bg" onClick={event => {delete_notification(id)}}>{t('OK')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
				<ButtonToolbar>
					<ButtonGroup className="me-2">
						<Button key="config-reset-popover-button"size="sm" variant="" className="primary-light-bg" onClick={close_popover_click}>{t('Abbrechen')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
		    </Popover.Body>
		</Popover>
	)
	
	let default_value_props = {
		name: { default_value: notification_obj.name },
		host: { default_value: notification_obj.host },
		port: { default_value: notification_obj.port },
		username: { default_value: notification_obj.username },
		password: { default_value: notification_obj.password },
		call_number: { default_value: notification_obj.call_number }
	}
	
	let delete_class_prop = {
		className: 'primary-light-bg'
	}
	let delete_button_text = t('Entfernen')
	let delete_button_jsx = [
		<Button key={`config-remove-button-${id}`} variant="sm" {...delete_class_prop} onClick={event => {remove_notification_form(id)}}>{delete_button_text}</Button>
	]
	let save_button_onclick_prop = {
		onClick: create_notification_click
	}
	if(notification_obj.type === undefined) { // existing config (update)
		class_prop.className += ' existing'
		delete_button_text = t('Löschen')
		delete_class_prop.className = 'danger-light-bg'
		
		save_button_onclick_prop.onClick = update_notification_click
		
		delete_button_jsx = [
			<OverlayTrigger key="config-delete-overlay-trigger" trigger="click" onToggle={on_toggle_click} placement="bottom" overlay={popover} show={popover_show}>
				<Button key={`config-delete-button-${id}`} variant="sm" {...delete_class_prop}>{delete_button_text}</Button>
			</OverlayTrigger>
		]
	}
	
	return (
		<Card {...class_prop}>
			<Form ref={form_ref}>
				<Row>
					<Col>
						<InputFloatField 
							is_required={true}
							label={t('Name')} 
							field_name="name" 
							field_type="text"
							key={`name-${id}`} 
							errors={get_error_state(form_error_id, "name")}
							{...default_value_props.name} />
					</Col>
				</Row>
				<DragDropContext onDragEnd={drag_end}>
					<div className="dnd-container-wrapper">
						{containers.map(({ title, configurations }, index) => (
							<Droppable key={index} droppableId={`${index}`} type={ItemTypes.NOTIFICATIONCONFIGURATION}>
							{(provided, snapshot) => (
								<AlarmNotificationConfigurationDnDContainer
									ref={provided.innerRef}
									title={title}
									configurations={configurations}
									key={index}
									provided={provided}
								/>
								
							)}
							</Droppable>
				        ))}
					</div>
				</DragDropContext>
				<ButtonToolbar>
					<ButtonGroup className="me-2 mt-3">
						<Button variant="sm" className="primary-light-bg" {...save_button_onclick_prop} >{t('Speichern')}</Button>
					</ButtonGroup>
				</ButtonToolbar>
				<ButtonToolbar>
					<ButtonGroup className="me-2 mt-3">
						{delete_button_jsx}
					</ButtonGroup>
				</ButtonToolbar>
			</Form>
		</Card>
	)
}

export default AlarmNotificationForm;

/*
	name = models.CharField(_('Name'), max_length=255, help_text=_('Interner name für die Benachrichtigung'))
    configurations = GM2MField(verbose_name=_('Konfigurationen')) # there is no admin implementation for this field
*/