import React, { useRef, useCallback, Fragment } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { send } from '@giantmachines/redux-websocket';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import ProjectDeviceWidget from './widgets/ProjectDeviceWidget'

const ProjectDevicesForm = ({ project_obj }) => {
	const {t} = useTranslation();
	
	const na_devices = useSelector(state => state.settings.projects.na_devices)
	const all_devices = useSelector(state => state.devices)
	
	const urls = useSelector(state => state.urls)
	const dispatch = useDispatch()
	
	const select_ref = useRef(null);
	
	const remove_device_click = useCallback(dev_eui => {
		let data = {
			"operation": "remove_from_project",
			"device_eui": dev_eui
		}
		const url = urls['iot-api:project-devices']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Das Gerät wurde entfernt.')))
				dispatch(send({'type': 'initial.data.for.auth.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [dispatch, t, urls])
	
	let devices_jsx = []
	if(!project_obj['device_ids'].length) {
		devices_jsx = [
			<div key="no-devices" className="mb-3">{t('Diesem Projekt sind keine Geräte zugeordnet')}</div>
		]
	} else {
		for(let dev_key of project_obj['device_ids']) {
			let device_obj = all_devices[dev_key]['device'];
			devices_jsx.push(<ProjectDeviceWidget key={`device-field-${device_obj.id}`} project_obj={project_obj} device_obj={device_obj} remove_device_click={remove_device_click} />)
		}
	}
	
	const assign_device_click = useCallback(event => {
		let data = {
			"id": project_obj.id,
			"operation": "add_to_project",
			"device_eui": select_ref.current.value
		}
		const url = urls['iot-api:project-devices']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Das Gerät wurde hinzugefügt.')))
				dispatch(send({'type': 'initial.data.for.settings.change'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [select_ref, t, dispatch, urls, project_obj])
	
	let select_jsx = []
	if(!na_devices.length) {
		select_jsx = [
			<div key="no-devices-available">{t('Keine Geräte verfügbar die zugeordnet werden könnten.')}</div>
		]
	} else {
		let options_jsx = []
		for(let dev of na_devices) {
			let title = dev.device_title
			if(dev.dev_title !== dev.dev_eui) {
				title += ` (${dev.dev_eui})`;
			}
			options_jsx.push(			
				<option key={`option-${dev.dev_eui}`} value={dev.dev_eui}>{title}</option>
			)
		}
		select_jsx.push(
			<Fragment key="device-select">
			<FloatingLabel label={t('Nicht zugeordnete Geräte')} className="mb-3">
				<Form.Select ref={select_ref} defaultValue="" name="dev-to-add">
					{options_jsx}
				</Form.Select>
			</FloatingLabel>
			<Button onClick={assign_device_click} variant="" className="primary-light-bg">{t('Diesem Projekt zuordnen')}</Button>
			</Fragment>
		)
	}
	
	return (
		<>
		<div className="project-info-container">
			<div className="title">{t('Verfügbare Geräte')}</div>	
			{select_jsx}
		</div>
		<div className="project-info-container">
			<div className="title">{t('Diesem Projekt zugeordnet')}</div>	
			<div className="project-device-settings-container">
				<div className="device-overview-grid">
				{devices_jsx}
				</div>
			</div>
		</div>
		</>
	)
}

export default ProjectDevicesForm;