import update from 'immutability-helper'
import { send } from '@giantmachines/redux-websocket';
import React, { useCallback, useState, useEffect } from 'react';
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {useSelector, useDispatch} from 'react-redux'
import Button from 'react-bootstrap/Button'
import { useTranslation} from 'react-i18next';
import {
	set_header_form_alert,
	async_do_simple_form_post
} from '../../actions'
import DeviceDnDContainer from './components/DeviceDnDContainer'
import {ItemTypes} from '../../utils/drag_types'

const DashboardDevicesForm = ({dashboard_data, set_dashboard_data}) =>  {
	const {t} = useTranslation();
	
	const urls = useSelector(state => state.urls)
	
	const dispatch = useDispatch()
	
	const {dashboard_devices, na_devices} = dashboard_data.dashboard
	const [containers, set_containers] = useState([])
	
	const handle_save_click = useCallback(event => {
		let data = {
			"operation": "update_dashboard_devices",
			"id": dashboard_data.dashboard.id,
			"user_id": dashboard_data.dashboard.user_id,
			"dashboard_devices": containers[0].devices,
			"na_devices": containers[1].devices
		}
		const url = urls['iot-api:dashboard-settings']();
		dispatch(async_do_simple_form_post(data, url)).then(payload => {
			const {status} = payload.value
			if(status === 200) {
				dispatch(set_header_form_alert(true, "success", t('Das Formular wurde gespeichert.')))
				dispatch(send({'type': 'dashboard.update'}));
			} else {
				dispatch(set_header_form_alert(true, "danger", t('Es ist ein Fehler aufgetreten.')))
			}
		})
	}, [containers, dashboard_data, dispatch, t, urls])
	
	useEffect(() => {
		set_containers([
			{ title: t('Auf Dashboard gezeigt'), devices: dashboard_devices, dnd_channels_shown: true }, // only show channels dnd container for devices that are shown
		   	{ title: t('Nicht auf Dashboard gezeigt'), devices: na_devices, dnd_channels_shown: false },
		])
	}, [dashboard_devices, na_devices, t])
	
	const device_eui_from_type = (type) => {
		// get the device eui from the type string: device-<dev_eui>
		const splitted_type = type.split('-')
		if(splitted_type !== undefined && splitted_type.length === 2) {
			return splitted_type[1]
		} else {
			return null
		}
	}
	
	const index_for_channel_container = (index) => {
		const splitted_index = index.split('-')
		if(splitted_index !== undefined && splitted_index.length === 2) {
			return parseInt(splitted_index[1])
		} else {
			return null
		}
	} 
	
	const reorder_device = (list, start_index, end_index) => {
	  	const result = Array.from(list.devices)
		const [removed] = result.splice(start_index, 1)
		result.splice(end_index, 0, removed)
		return result
	}
	
	const reorder_channels = (device, start_index, end_index, source_channel_attr) => {
		// we only sort channels for shown channels
		const result = Array.from(device[source_channel_attr])
		const [removed] = result.splice(start_index, 1);
		result.splice(end_index, 0, removed)
		return result
	}
	
	const move_device = (source, destination, droppable_source, droppable_dest) => {
		const source_devices = Array.from(source.devices);
	  	const dest_devices = Array.from(destination.devices);
	  	
	  	const [removed] = source_devices.splice(droppable_source.index, 1);
	
		dest_devices.splice(droppable_dest.index, 0, removed);
	
		const result = {};
		result[droppable_source.droppableId] = source_devices;
		result[droppable_dest.droppableId] = dest_devices;
	
		return result;
	}
	
	const move_channel = (device, droppable_source_index, droppable_dest_index, source_channel_attr, dest_channel_attr) => {

		const source_channels = Array.from(device[source_channel_attr])
		const dest_channels = Array.from(device[dest_channel_attr])
			
		const [removed] = source_channels.splice(droppable_source_index, 1);
			
		dest_channels.splice(droppable_dest_index, 0, removed);
			
		const result = {}
		result[source_channel_attr] = source_channels
		result[dest_channel_attr] = dest_channels
		
		console.log(result)
		
		return result
	}
	
	const drag_end = (result) => {
		const { source, destination, type } = result;
		console.log('source: ', source)
		console.log('destination: ', destination)
		console.log('type: ',type)

	    // dropped outside the list
		if (!destination) {
			return
		}
		
		if(type === ItemTypes.DEVICE) {
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
		
			if (source_index === dest_index) {
				const reordered_devices = reorder_device(containers[source_index], source.index, destination.index);
				const new_containers = update(containers, {
					[source_index]: {
						devices: {
							$set: reordered_devices
						}
					}
				})
				set_containers(new_containers);
			} else {
				const result = move_device(containers[source_index], containers[dest_index], source, destination);
				const new_containers = update(containers, {
					[source_index]: {
						devices: {
							$set: result[source_index]
						}
					},
					[dest_index]: {
						devices: {
							$set: result[dest_index]
						}
					}
				});
				set_containers(new_containers);
		    }
		} else {
			const device_eui = device_eui_from_type(type)
			const source_index = source.droppableId;
			const dest_index = destination.droppableId;
			
			const source_channel_index = source.index;
			const dest_channel_index = destination.index

			const real_source_index = index_for_channel_container(source_index)
			const real_dest_index = index_for_channel_container(dest_index)
			
			// "destination container" is always 0 = shown devices
			const device_index = containers[0].devices.map(function(e) { return e.device_eui; }).indexOf(device_eui);
			const device = containers[0].devices[device_index]
				
			// 0 -> shown, 1 -> not shown				
			const source_channel_attr = real_source_index ? 'all_not_shown_channels' : 'all_shown_channels' 
			const dest_channel_attr = real_source_index ? 'all_shown_channels' : 'all_not_shown_channels'
			
			if(real_source_index === real_dest_index) { // order
				if(!real_source_index) { // only do ordering on shown channels
					const result = reorder_channels(device, source_channel_index, dest_channel_index, source_channel_attr)
					const new_containers = update(containers, {
						0: {
							devices: {
								[device_index]: {
									[source_channel_attr]: {
										$set: result
									}
								}
							}
						}
					});
					set_containers(new_containers);
				}
			} else { // move
				const result = move_channel(device, source_channel_index, dest_channel_index, source_channel_attr, dest_channel_attr)
				
				const new_containers = update(containers, {
					0: {
						devices: {
							[device_index]: {
								[source_channel_attr]: {
									$set: result[source_channel_attr]
								},
								[dest_channel_attr]: {
									$set: result[dest_channel_attr]
								}
							}
						}
					}
				});
				set_containers(new_containers);
			}
		}
	}
	
	return (
		<>
		<DragDropContext onDragEnd={drag_end}>
		<div className="dnd-container-wrapper">
			{containers.map(({ title, devices, dnd_channels_shown }, index) => (
				<Droppable key={index} droppableId={`${index}`} type={ItemTypes.DEVICE}>
				{(provided, snapshot) => (
					<DeviceDnDContainer
						ref={provided.innerRef}
						title={title}
						devices={devices}
						key={index}
						dnd_channels_shown={dnd_channels_shown}
						provided={provided}
					/>
					
				)}
				</Droppable>
	        ))}
		</div>
        </DragDropContext>
		<div className="mt-3">
			<Button onClick={handle_save_click} variant="" className="primary-light-bg">{t('Speichern')}</Button>
		</div>
		</>
	)
}

export default DashboardDevicesForm;