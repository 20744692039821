import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// usr local saved language files
import translation_de from './i18n/de/translation.json';

const resources = {
	de: {
		translation: translation_de
	}
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	//.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		resources,
		whitelist: ['de', 'en'],
		lng: 'de',
		defaultNS: 'translation',
		fallbackLng: 'de',
		keySeparator: true,
		debug: false,

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		}
	});


export default i18n;