import { useState, useCallback } from 'react';

// simple form, one
export const useFormError = (errors) => {
	const [form_errors, set_form_errors] = useState({})
	
	const get_error_state = useCallback((field_name) => {
		if(Object.keys(form_errors).length !== 0) {
			if(field_name in form_errors) {
				return form_errors[field_name];
			} else {
				return []
			}
		} else {
			return []
		}
	}, [form_errors])
	
	return [form_errors, set_form_errors, get_error_state];
}

// multiple forms with different ids, which need to be unique
export const useFormIDError = (errors) => {
	const [form_errors, set_form_errors] = useState({})
	
	const get_error_state = useCallback((form_id, field_name) => {
		if(Object.keys(form_errors).length !== 0) {
			if(form_id in form_errors) {
				if(Object.keys(form_errors[form_id]).length !== 0) {
					if(field_name in form_errors[form_id]) {
						return form_errors[form_id][field_name];
					} else {
						return []
					}
				} else {
					return []
				}
			} else {
				return []
			}
		} else {
			return []
		}
	}, [form_errors])
	
	return [form_errors, set_form_errors, get_error_state];
}