import React, { useState, useRef, useCallback } from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import { useTranslation} from 'react-i18next';

import Button from 'react-bootstrap/Button'

import {
	set_header_form_alert,
	async_do_http_csv_export
} from '../../actions'

import InputFloatField from './fields/InputFloatField'

import DeviceDataDeletePartForm from './DeviceDataDeletePartForm'

import {usePermission} from '../../hooks'

const DeviceExoportForm = ({ device_obj }) => {
	const {t} = useTranslation();
	
	const dispatch = useDispatch()
	
	const urls = useSelector(state => state.urls)
	
	const [validated, set_validated] = useState(false);
	
	const has_perm = usePermission()
	
	const form_ref = useRef(null);
	
	const handle_response = useCallback(payload => {
		const {status, data} = payload.value
		if(status === 200) {
			dispatch(set_header_form_alert(true, "success", t('Die CSV Datei wird generiert und automatisch zum Download angeboten.')))
			let url = window.URL.createObjectURL(new Blob([data], {type : 'text/csv'}));
			let a = document.createElement('a');
			a.href = url;
			a.download = 'export_file.csv';
			document.body.append(a);
			a.click();
			a.remove();
			window.URL.revokeObjectURL(url);
		} else {
			dispatch(set_header_form_alert(true, "danger", t('Für diesen Zeitraum existieren keine Daten die exportiert werden können, oder die Daten wurden bereits exportiert.')))
		}
	}, [dispatch, t])
	
	const handle_timerange_export = useCallback(event => {
		const form = form_ref.current;
		if (form.checkValidity() === false) {
			set_validated(true);
		} else {
			set_validated(true);
			let data = {
				"operation": "timerange",
				"dev_eui": device_obj.dev_eui
			}
			const fdata = new FormData(form);
			for (let [key, value] of fdata) {
				data[key] = value;
			}
			const url = urls['iot-api:csv-export']();
			dispatch(async_do_http_csv_export(data, url)).then(payload => {
				handle_response(payload)
			})
		}
	}, [form_ref, device_obj.dev_eui, handle_response, urls, dispatch])
	
	const handle_full_export = useCallback(event => {
		let data = {
			"operation": "full",
			"dev_eui": device_obj.dev_eui
		}
		const url = urls['iot-api:csv-export']();
		dispatch(async_do_http_csv_export(data, url)).then(payload => {
			handle_response(payload)
		})
	}, [device_obj.dev_eui, handle_response, urls, dispatch])
	
	let device_delete_part_form_jsx = []
	if(has_perm('iot.delete_device_data')) {
		device_delete_part_form_jsx = [
			<DeviceDataDeletePartForm device_obj={device_obj} event_key={2} key="export-delete-part-form" />
		]
	}
	
	return (
		<Accordion defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header>{t('Export nach Zeitraum')}</Accordion.Header>
				<Accordion.Body>
					<p>{t('Zeitraum für den Export wählen.')}</p>
					<Form validated={validated} ref={form_ref}>
						<Container fluid>
							<Row>
								<Col lg={true}>
									<InputFloatField 
										is_required={true}
										label={t('Start Datum')}
										field_name="start_date" 
										field_type="date" 
										default_value="" />
								</Col>
								<Col lg={true}>
									<InputFloatField
										is_required={true} 
										label={t('Start Zeit')}
										field_name="start_time" 
										field_type="time" 
										default_value="" />
								</Col>
							</Row>
							<Row>
								<Col lg={true}>
									<InputFloatField
										is_required={true} 
										label={t('End Datum')}
										field_name="end_date" 
										field_type="date" 
										default_value="" />
								</Col>
								<Col lg={true}>
									<InputFloatField
										is_required={true} 
										label={t('End Zeit')}
										field_name="end_time" 
										field_type="time" 
										default_value="" />
								</Col>
							</Row>
							<Button onClick={handle_timerange_export} variant="" className="primary-light-bg">{t('gewählter Zeitraum exportieren')}</Button>
						</Container>
					</Form>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header>{t('Vollständiger Export')}</Accordion.Header>
				<Accordion.Body>
					<p>{t('Es werden alle Daten des Gerätes über den ganzen Aufzeichnungszeitraum exportiert.')}</p>
					<Button onClick={handle_full_export} variant="" className="primary-light-bg">{t('Alle Daten exportieren')}</Button>
				</Accordion.Body>
			</Accordion.Item>
			{device_delete_part_form_jsx}
		</Accordion>
	)
}

export default DeviceExoportForm;