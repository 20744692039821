import React from 'react';
import {useSelector} from 'react-redux'
import { 
	CHART_X_SCALE_SMALL,
	CHART_Y_SCALE_SMALL,
	CHART_CHANNEL_DATASET_CONFIG,
	CHART_OPTIONS_SMALL,
	CHART_CONFIG_SMALL
} from '../../Config';
import ResponsiveLineChartCanvas from '../ResponsiveLineChartCanvas';
//import ResponsiveLineChartSVG from '../ResponsiveLineChartSVG';
import { prepare_channel_data } from "../../utils/data";

import DeviceContainer from "./DeviceContainer"

import { useTranslation} from 'react-i18next';
import {de} from 'date-fns/locale';

const DashboardDevice = ({ device_obj, triggered_alarm_count, channels, chart_channels, triggered_alarm_names }) => {
	
	const {t} = useTranslation();
	
	const max_initial_values_loaded = useSelector(state => state.settings.data_prefetch.max_initial_values_loaded)
	
	let pcd = prepare_channel_data(channels, max_initial_values_loaded);
	let data = pcd.data;
	let yaxis_config = pcd.yaxis_config;
	let chart_items = [];
	let has_data = true; // check if we have data, else do not render chart infos
	for(let i = 0; i < chart_channels.length; i++) {
		const channel_id = chart_channels[i]
		if(!data[channel_id].length) {
			has_data = false;
			break; // break the whole loop
		}
		
		const x_scale = Object.assign({}, CHART_X_SCALE_SMALL, {
			suggestedMin: data.first_datetime_entry,
			suggestedMax: data.last_datetime_entry,
			adapters: {
				date: {
					locale: de,
				}
			}
		})
		
		const y_scale = Object.assign({}, CHART_Y_SCALE_SMALL, {
			suggestedMin: yaxis_config[channel_id].min,
			suggestedMax: yaxis_config[channel_id].max
		})
			
		const data_set_config = Object.assign({}, CHART_CHANNEL_DATASET_CONFIG[channel_id], {
			label: `${channels[channel_id]['channel']['channel_id_display']} (${channels[channel_id]['channel']['unit_display']})`,
			data: data[channel_id]
		})
			
		const chart_options = Object.assign({}, CHART_OPTIONS_SMALL, {
			scales: {
				x: x_scale,
				y: y_scale
			},
		})
			
		const chart_info = Object.assign({}, CHART_CONFIG_SMALL, {
			options: chart_options,
			datasets: [data_set_config]
		})
		
		const legend_title = `${channels[channel_id]['channel']['channel_id_display']} (${channels[channel_id]['channel']['unit_display']})`;
		chart_items.push(<div className="chart-item" key={i+'-'+channel_id+'-'+device_obj['dev_eui']}>
						 <div className="chart-legend">{legend_title}</div>
						 <ResponsiveLineChartCanvas key={channel_id+'-'+device_obj['dev_eui']} chart_info={chart_info} device_obj={device_obj} chart_type="small" />
						 </div>);
	}
		
	if(!has_data) { // fill some message
		chart_items.push(<div key='error' className="error">{t('Keine Daten vorhanden.')}</div>);
	}
	
	return (
		<DeviceContainer with_link={true} device_obj={device_obj}  table_items={[]} chart_items={chart_items} triggered_alarm_count={triggered_alarm_count} triggered_alarm_names={triggered_alarm_names} />
	)
}

export default DashboardDevice;
